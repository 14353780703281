<template>

<Navigacija @reload-parent="methodThatForcesUpdate" yvisina=15 iocon="1">
  <template #atag><a></a></template>
      <template #content>
        <div id="baseApp">
        
  <div class='app'>
    <main class='project'>
      <div class='project-info'>
        <h1></h1>
        <div class='project-participants'>
          <span class='bg-plava' @click="otvori('preuzeto')"></span>
          <span class='bg-zuta' @click="otvori('preuzimanje')"></span>
          <span class='bg-zelena' @click="otvori('zavrseni')"></span>

        </div>
      </div>
      <div class='project-tasks'>
        <div class='project-column' id="preuzeto">
          <div class='project-column-heading'>
            <h2 class='project-column-heading__title' style="color:gray">
              <b>Preuzeti</b>
            </h2>
          </div>
          <div class="preuzeto">
        
            <div :class="changeColor(i.status)" v-for="i in dostave.preuzete">
            <a style="color:white;cursor: pointer;" @click="$router.push('/korisnik/dostave/'+i.id+'/')">
              <div class="card-block">
                <h6 class="m-b-20">{{i.status}}</h6>
                <h2 class="text-right">
                  <i class="fa fa-user f-left">&nbsp;</i>
                  <span>{{i.gost__broj__lokacija}}</span>
                  &nbsp;<span>{{new Date(i.zavrijeme).toLocaleString('en-UK', {month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit'}).replace(',', '')}}</span>
                  
                </h2>
              </div>
              </a>
            </div>

            <div class='card bg-c-gray order-card' v-if="dostave.preuzete && dostave.preuzete.length == 0">
              <a style="color:white;">
              <div class="card-block">
                <h6 class="m-b-20">...</h6>
                <h2 class="text-right">
             
                  <span>Nema dostava</span>
                </h2>
              </div>
              </a>
            </div>

          </div>
        </div>

        <div class='project-column' id="preuzimanje">
          <div class='project-column-heading'>
            <h2 class='project-column-heading__title' style="color:gray">
              <b>Preuzimanje</b>
            </h2>
          </div>
          <div class="preuzimanje">

          
            <div :class="changeColor(i.status)" v-for="i in dostave.preuzimanje">
               <a style="color:white;cursor: pointer;" @click="$router.push('/korisnik/dostave/'+i.id+'/')">
              <div class="card-block">
                <h6 class="m-b-20">{{i.status}}</h6>
                <h2 class="text-right">
                  <i class="fa fa-user f-left">&nbsp;</i>
                  <span>{{i.gost__broj__lokacija}}</span>
                  &nbsp;<span>{{new Date(i.zavrijeme).toLocaleString('en-UK', {month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit'}).replace(',', '')}}</span>
                </h2>
              </div>
              </a>
            </div>

     
              <div class='card bg-c-gray order-card' v-if="dostave.preuzimanje && dostave.preuzimanje.length == 0">
              <a style="color:white;">
              <div class="card-block">
                <h6 class="m-b-20">...</h6>
                <h2 class="text-right">
             
                  <span>Nema dostava</span>
                </h2>
              </div>
              </a>
            </div>


          </div>
        </div>


        <div class='project-column' id="zavrseni">
          <div class='project-column-heading'>
            <h2 class='project-column-heading__title' style="color:gray">
              <b>Dostavljene</b>
            </h2>
          </div>
          <div class="zavrseni">
         
          <div :class="changeColor(i.status)" v-for="i in dostave.izvrsene">
               <a style="color:white;cursor: pointer;" @click="$router.push('/korisnik/dostave/'+i.id+'/')">
              <div class="card-block">
                <h6 class="m-b-20">{{i.status}}</h6>
                <h2 class="text-right">
                  <i class="fa fa-user f-left">&nbsp;</i>
                  <span>{{i.gost__broj__lokacija}}</span>
                  &nbsp;<span>{{new Date(i.zavrijeme).toLocaleString('en-UK', {month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit'}).replace(',', '')}}</span>
                </h2>
              </div>
              </a>
            </div>


              <div class='card bg-c-gray order-card' v-if="dostave.izvrsene && dostave.izvrsene.length == 0">
              <a style="color:white;">
              <div class="card-block">
                <h6 class="m-b-20">...</h6>
                <h2 class="text-right">
             
                  <span>Nema dostava</span>
                </h2>
              </div>
              </a>
            </div>

          </div>
        </div>




      </div>
    </main>

  


  </div>
  <div id="preloader"></div>
</div>

      </template>



</Navigacija>



</template>


<script>
import Navigacija from '../components/Navigacija.vue'
import axios from 'axios'
export default{
  components: {
    Navigacija
  },

    data() {
    return {
        dostave:[],
        username:"",
        preuzete:[],
        sp:[],
        colona:[],
        izvrsene:[],


        form:{
         id:"",
         vrsta:"",
        },
        intervalId1:null,
    }
  },

  mounted(){

    if (this.intervalId1) {
    clearInterval(this.intervalId1);
  }
  this.DostaveSve()

      this.intervalId1 = setInterval(() => {
        this.DostaveSve()
      }, 10000);


    this.username = localStorage.getItem('username')
    const preloader = document.querySelector('#preloader');
    



  },
  beforeRouteLeave(to, from, next) {
    // Stop polling when leaving the route
    clearInterval(this.intervalId1);
    next();
  },

  methods:{

    methodThatForcesUpdate(key){
              
              this.DostaveSve()

        
          },
          

    changeColor(status) {

      console.log(status)
  return {
    'card bg-c-blue order-card': status == "Waiting",
    'card bg-c-blue order-card': status == "Taken",
    'card bg-c-pink order-card': status == "Preparing",
    'card bg-c-yellow order-card': status == "Ready",
    'card bg-c-green order-card': status == "Delivered",

  }},

    async DostaveSve() {

      await axios
        .get('/api/dostave/')
        .then(response => {
          const sp = ['sanpolo']
          const colona = ['molpicio', 'albachiara', 'portobus']
          this.dostave = response.data
          console.log(this.dostave)
  
          const products = response.data

          this.dostave.preuzimanje = products.dostave.filter(product => product.status != 'Delivered' && product.dostavljac__username == null)
          this.dostave.izvrsene = products.dostave.filter(product => product.status == 'Delivered' && product.dostavljac__username!=null)
          this.dostave.preuzete = products.dostave.filter(product => product.status != 'Delivered' && product.dostavljac__username==this.username)


          console.log(this.dostave)
          $('#preloader').hide()

          	
		

        })
        .catch(error => {
   
          console.log(error)
        })

    },



    otvori(stupac){
          $('.project-tasks .project-column').each(function(){
    
            $(this).hide();
    
          })
          $('#'+stupac).show();
        },

  },
}
</script>

<style scoped>
#baseApp:after {
    content: '';
    background-image: url(../assets/img/dec2.jpg);
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: 0px;
    width: 414px;
    height: 861px;
    
    z-index: -1;
    opacity: 0.5;
  }
  



</style>
<style>

.order-card {
    color: #fff;
}

.bg-c-blue {
    background: linear-gradient(45deg,#4099ff,#73b4ff);
}

.bg-c-green {
    background: linear-gradient(45deg,#2ed8b6,#59e0c5);
}

.bg-c-yellow {
    background: linear-gradient(45deg,#FFB64D,#ffcb80);
}

.bg-c-pink {
    background: linear-gradient(45deg,#FF5370,#ff869a);
}
.bg-c-gray {
    background: linear-gradient(45deg,#d3d3d3,#d3d3d3);
}



.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
    box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
    border: none;
    margin-bottom: 15px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.card .card-block {
    padding: 25px;
}

.order-card i {
    font-size: 26px;
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}


</style>

<style scoped>

.project {  

    max-width: 100%;  

}  


.submitbtn{
    font-weight: 400;
    display: inline-block;
    padding: 10px 28px;
    border-radius: 4px;
    transition: 0.5s;
    color: var(--color-white);
    background: var(--color-primary);
    font-family: var(--font-secondary);

}


</style>