

<template>


<Navigacija @reload-parent="methodThatForcesUpdate" yvisina=20  iocon="1">
<template #atag><a class="btn-getstarted scrollto" style="border-radius: 20px;background-color: rgb(249, 243, 243);padding: 8px 15px;cursor: pointer;" id="podsjetnik" @click="this.$root.otvoriform('myModal')" ><i class="fa fa-plus-circle" style="color:rgb(14, 162, 189);font-size: 25px;"></i></a></template>
<template #content>
  <div id="baseApp">
    
<br><div style="padding-top:17px;"></div>


<main class="cd-main-content">
  <div class="cd-tab-filter-wrapper">
    <div class="cd-tab-filter">
      <ul class="cd-filters">
        <li class="placeholder"> 
          <a data-type="nezavrseni" href="#0" data-filter=".nezavrsen">To do <i class="fa fa-hammer"></i> </a> <!-- selected option on mobile -->
        </li> 
        <li class="filter"><a  href="#0" data-type="nezavrseni" data-filter=".nezavrsen" class="selected" >To do </a></li>
        
        
        <li class="filter" data-filter=".3"><a href="#0" data-type="zavrseni">Finished  </a></li>
        <li class="filter" data-filter=".kom"><a href="#0" data-type="kom">Commented </a></li>
        <li class="filter" data-filter=".hitno"><a href="#0" data-type="hitni">Urgent </a></li>
        <li class="filter" data-filter=".mix"><a href="#0" data-type="svi">All</a></li>
       

      
        
    
      </ul> 
    </div> 
  </div> 

  <section class="cd-gallery">
<ul>
  
<div id="karte" class="row">
  <li class="mix col-md-4 col-lg-6 col-sm-12" style="display:inline-block;" v-for="zd in novi">
      <div style="padding-bottom: 25px;" >  
        <div :class="'box '+zd.vrsta_boja">
        <span id="imgd" class="f-right"><img :src="zd.img_ime" @error="'../assets/default.png'" style="width: 50px; height: 50px;"></span>
        <span id="naslovni" @click="detalji(zd.id)"><h2><b style="font-weight:bold;">{{zd.lokacija}}</b> <span class="mobile-break" style="color:var(--color-primary)">{{zd.vrsta_ime}}</span></h2></span>
        <p> <b style="font-weight:bold;"  v-if="zd.opis"></b>{{zd.opis}}</p>
        <p style="padding-top: 15px;"><b style="font-weight:bold;">Označeni:</b> <span v-for="djelatnik in zd.djelatnici">  {{djelatnik}}&nbsp; </span><template v-for="grupa in zd.grupa">{{grupa}}&nbsp;</template> </p><br>
        <p><span class="f-left">{{zd.autor}}  - {{zd.vrijemeizrade}}</span><span class="f-right"><i class="fa fa-picture-o" aria-hidden="true" v-if="zd.slika"></i>  <i class="fa-solid fa-door-open" v-if="zd.ulazak_staff"></i></span></p>
      </div>
      </div>
    </li>

     <li class="mix col-md-6 col-lg-6 col-sm-12"  :class="zd.djelatnici, zd.uzmikom, komentiran(zd.num_kom), hitno(zd.hitno),  zd.vrsta_lokacije, zd.grupa + ' ' +zd.status, vrijeme(zd.zadatum), nezavrsen(zd.status)" v-for="zd in data.zadaci">
      <div style="padding-bottom: 25px;" >  
        <div :class="'box '+zd.vrsta_boja">
          
        <span id="imgd" class="f-right"><img :src="zd.img_ime" @error="'../assets/default.png'" style="width: 50px; height: 50px;"></span>
        <span id="naslovni" @click="detalji(zd.id)"><h2> <i v-if="zd.status==3" class="fa fa-circle-check" style="color:green;font-size: 25px;"></i>&nbsp;<b style="font-weight:bold;">{{zd.lokacija}}</b> <span class="mobile-break" style="color:var(--color-primary)">{{zd.vrsta_ime}}</span></h2></span>
        <p> <b style="font-weight:bold;" v-if="zd.opis.trim().length"></b>{{zd.opis}}</p>
        <p style="padding-top: 15px;"><b style="font-weight:bold;"></b> <span v-for="djelatnik in zd.djelatnici">  {{djelatnik}}&nbsp; </span><template v-for="grupa in zd.grupa">{{grupa}}&nbsp;</template> </p><br>
        <p><span class="f-left">{{zd.autor}}  - {{zd.vrijemeizrade}}</span><span class="f-right"><i class="fa fa-picture-o" aria-hidden="true" v-if="zd.slika"></i>  <i class="fa-solid fa-door-open" v-if="zd.ulazak_staff"></i>&nbsp;

          <i class="fa fa-comment" aria-hidden="true" style="padding-right: 5px;" v-if="zd.num_kom!=0"></i>
    


          <i class="fa-solid fa-circle-exclamation"  v-if="zd.hitno"></i>
      </span></p>
      </div>
      </div>
  </li>
  <li class="mix col-md-6 col-lg-6 col-sm-12 nezavrsen kom hitno 3" v-if="this.countToDo == 0">
      <div style="padding-bottom: 25px;" >  
        <div :class="'box red'">
          
        <span id="imgd" class="f-right"><img src="../assets/img/empty.png"  style="width: 50px; height: 50px;"></span>
        <span id="naslovni"><h2>No tasks, yet ... </h2></span>
        <p> ...</p>
        

      </div>
      </div>
  </li>

  </div>
</ul>

<div style="color:gray" class="cd-fail-message">Pretraživanje nije dalo rezultata</div>
</section>
<div class="cd-filter">
  <form>
    <div class="cd-filter-block">
      <h4>Pretraži</h4>
      
      <div class="cd-filter-content">
        <input type="search" placeholder="Bilo što">
      </div> 
    </div> 

    <div class="cd-filter-block">
      <h4>Vrsta</h4>
      <ul class="cd-filter-content cd-filters list">
        <li>
          <input class="filter" data-filter=".mh" type="checkbox" id="checkbox1">
            <label class="checkbox-label" for="checkbox1">Mh</label>
        </li>
        <li>
          <input class="filter" data-filter=".parcela" type="checkbox" id="checkbox2">
          <label class="checkbox-label" for="checkbox2">Parcela</label>
        </li>
        <li>
          <input class="filter" data-filter=".prostor" type="checkbox" id="checkbox2">
          <label class="checkbox-label" for="checkbox2">Prostor</label>
        </li>
      </ul>
    </div> 
    <div class="cd-filter-block">
      <h4>Djelatnici</h4>
      <div class="cd-filter-content">
        <div class="cd-select cd-filters">
          <select class="filter" name="selectThis" id="selectThis">
            <option value="">Izaberi Djelatnika</option>
                  <template v-for="grupa in grupe">
                  <option :value="'.'+grupa.ime" style="color:#0ea2bd;">{{grupa.ime}}</option>
                  <option :value="'.'+djelatnik.user" v-for="djelatnik in grupa.djelatnici">{{djelatnik.user}}</option>
                  </template>
          </select>
        </div>
      </div>
    </div> 
    <div class="cd-filter-block">
      <h4>Status</h4>
      <ul class="cd-filter-content cd-filters list">
        <li>
          <input class="filter" data-filter="" type="radio" name="radioButton" id="radio1" checked>
          <label class="radio-label" for="radio1">Svi</label>
        </li>
        <li>
          <input class="filter" data-filter=".danas" type="radio" name="radioButton" id="radio2">
          <label class="radio-label" for="danas">Današnji</label>
        </li>
        <li>
          <input class="filter" data-filter=".buduci" type="radio" name="radioButton" id="radio3">
          <label class="radio-label" for="sutra">Budući</label>
        </li>
      </ul>
    </div>
  </form>

  <a href="#0" class="cd-close">Close</a>
</div>

<a href="#0" class="cd-filter-trigger" >Filteri</a>
</main> 







</div>


</template>
</Navigacija>

<div id="myModal" style="padding-top:90px; z-index:10001; background-color: rgba(218, 223, 225, 0.1);" tabindex="-1" role="dialog" aria-labelledby="costumModalLabel" aria-hidden="true" class="modal fade">
  <div class="modal-dialog kreirajbody kreiraj1" >
      <div class="modal-content animate-bottom kreiraj2" id="createmodal">

          <div class="modal-header">
             <h1 class="modelheading">Create task</h1>
             <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('myModal')" ></i>
          </div>
          <form v-on:submit.prevent="submitPost">
          <div class="modal-body">
            <label for="message" class="col-form-label" style="color:gray;font-size: .9rem;font-weight: bold;">Označeni *</label>

            <div class="col-md-12" id="djelatnici" @click="zatvoridjl"> <select id="choices-multiple-remove-button3" placeholder="Označi djelatnike" multiple>

  
</select> </div><br>

            <label for="message" class="col-form-label" style="color:gray;font-size: .9rem;font-weight: bold;">Vrsta *</label>
                <div class="multivrsta col-md-12 col-sm-12" id="vrsta" @click="zatvorivrste"> <select id="choices-multiple-remove-button1" class="inputVrsta" placeholder="Odredi vrstu zadatka" v-model="form.vrste">
      
              </select> </div><br>
              
              <label for="message" class="col-form-label" style="color:gray;font-size: .9rem;font-weight: bold;">Lokacija *</label>
              <div class="col-md-12 col-sm-12" id="lokacija" @click="zatvorilokacija"> <select id="choices-multiple-remove-button2" placeholder="Odredi lokaciju" >
  
              </select> </div>
              <br>
            

            <br>
             
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="5" placeholder="Opiši zadatak" style="color:gray" v-model="form.opis"></textarea> 
              <br>    
<br>         
            <br>

              <div class="mb-3">

  <label for="message" class="form-label" style="color:gray;font-size: .9rem;font-weight: bold;">Prikvačite fotografiju *</label>

  <input type="file" class="form-control fileinput"  @change="handleFileChange">
</div>

              <br>
              <div class="kreiraj4">
              <div class="form-check form-switch">
                <label class="form-check-label" for="flexSwitchCheckDefault" style="margin-top:0.25em;">Hitan zadatak</label>
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="form.hitno">
    
  </div><br><br>

  <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="form.ulazak_staff">
  <label class="form-check-label" for="flexSwitchCheckDefault">Dozvoljen ulaz dok gost nije u smještaju</label>
</div>
</div>
          </div>
          <div class="modal-footer d-flex justify-content-center kreiraj3">
            
      
      
              <button type="submit" id="submit" class="btn btn-info f-left modalbutton" data-dismiss="modal" >Kreiraj</button>
              <div style="padding-top:120px;" class="prazanprostor"></div>
          </div>
        </form>
      </div>
  </div>
</div>

<div id="preloader"></div>
</template>

<style scoped>

@media only screen and (max-width: 768px) {

.modal-content {
height: 100%;
border-radius: 0;
position:relative;
}


.modal-header{
  padding-top: 45px !important;
  
}

}</style>
<style>

.fileinput{
  height: 35px !important;
  border: 1px solid #ced4da !important;
  padding-left: 10px !important;
}


@media only screen and (max-width: 768px) {




.kreiraj2{
      border-radius: 0rem !important;
      height: 100%;
}
.kreiraj1{
  height: 100%;
}
}



.modalbutton{
  background-color:#17a2b8;
  color:white;width: 300px;
  border-radius: 20px;
  border-color: #17a2b8;
}

.modelheading{
  font-weight: bold !important;
  color:#0189a1 !important;
}

.cd-tab-filter ul{
  background: white;
}


@media (min-width: 576px){
.modal-dialog {
  max-width: 800px;
}
}

.animate-bottom {
  position: relative;
  animation: animatebottom 0.4s;
}

.animate-down {
  position: relative;
  animation: animatedown 0.4s;
}

@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes animatedown {
  from {
    bottom: 0;
    opacity: 1;
  }

  to {
    bottom: -300px;
    opacity: 0;
  }

 
}


@media only screen and (max-width: 768px) {
  .prazanprostor {
  padding-top:0px !important;
}
}


.inputmodal-dialog{
      padding:18px !important;
      bottom:50% !important;
    }
    .inputmodal-content{
      border-radius: 1rem !important;
      border: 0px solid white !important;
    }

.modal-dialog  {
  position: fixed;
 
  margin: 0px auto 0;
  top:auto;
  bottom:0;
  width:100% !important;

  transform: translate(-50%, 0%) !important;
  left: 50%;

  -webkit-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
} 

.choices__heading{
  color:var(--color-primary);
  font-size:14px;
}

.choices[data-type*=select-one]:after{
  border-width: 8px;
  border-color: var(--color-primary) transparent transparent transparent;
}

.choices[data-type*=select-one].is-open:after{
  border-width: 8px;
  border-color: transparent transparent var(--color-primary) transparent  ;
}

.modal-content{
  border-radius: 1rem 1rem 0 0;
  border-color: white;
}


</style>

<script>
import Navigacija from '../components/Navigacija.vue'
import axios from 'axios'

export default{
  components: {
    Navigacija
  },

  data() {
    return {
      reload:false,
      brojn:0,
      countToDo:1,
      choice1:null,
      choice2:null,
      choice3:null,
      novi:[],
      data:[],
      multipleCancelButton:"",
      zadaci: [],
      vrste: [],
      jedinice:[],
      grupe:[],
      pokazatelj:false,
      form: {
                opis: '',
                lokacija:'',
                vrsta:'',
                hitno:false,
                datum:new Date().toISOString().substr(0, 10),
                djelatnici:[],
                ulazak_staff:false,
                img:null,
                

            
            }
     
    }
    
  },

  beforeRouteUpdate(to, from, next) {
    console.log("test")
  },

  computed:{

nemazd(){
  let count = 0
  if(this.data.zadaci){
    for (let i = 0; i < this.data.zadaci.length; i++) {
    if(this.data[zadaci].status==0 || this.data[zadaci].status==0==1){
     
     count++;
   }
 

  }
  if(count==0){
    return true
   }
   else{
    return false
   }

  }

},

  },
  

  mounted(){





  $("#closeModal").click(function(){
 
            var modal = $("#myModal")
            $("#myModal").modal('hide');     

        });


      const preloader = document.querySelector('#preloader');
      this.getZadaci()


   


      

   },
methods:
{
  handleFileChange(event) {
      this.form.img = event.target.files[0];
    },
  hitno(status){
    if(status){ return "hitno"}
    else{ return "" }
  },

  komentiran(num){
    if(num!=0){ return " kom "}
  },

  methodThatForcesUpdate(key){
              this.reload = true
          
              this.getZadaci()

        
          },

  zatvorivrste(){
  
  },
  zatvorilokacija(){
    
  },

  zatvoridjl(){
    if (this.choice3.dropdown.isActive){

      //this.choice3.hideDropdown()
    }
  },

  
  nezavrsen(status){
    if(status==0 || status==1){
     
      return "nezavrsen"
    }
  },
  

  dodaj(ime){
    this.form.djelatnici.push(ime)
  },
  submitPost(){
         

              $('#djelatnici #choices-multiple-remove-button3 option').each(function(index, element){
                this.form.djelatnici.push($(element).attr('value'))
                

              }.bind(this));
         
            this.form.vrsta = $('#vrsta .choices__item ').attr('data-custom-properties')
            this.form.lokacija = $('#lokacija .choices__item ').attr('data-custom-properties')
            console.log(this.form)
            
            axios.post('/api/create_post/', this.form, {headers: {
        'Content-Type': 'multipart/form-data',
      },}) .then((res) => {
                  console.log(this.form.djelatnici)
                      this.novi.unshift({
                        id:res.data.id,
                        img_ime: res.data.img,
                       vrsta__img_path: res.data.img,
                       vrsta_ime: this.data.vrste.find(item => item[1] === parseInt(this.form.vrsta))[0],
                       lokacija: this.data.lokacije.find(item => item[1] === parseInt(this.form.lokacija))[0],
                       opis: this.form.opis,
                       vrijemeizrade:"sada",
                       zadatum:res.data.zadatum,
                       autor:"ja",
                       grupa: Object.values(this.form.djelatnici),
                       vrsta_boja:"cyan",
                       sada:true,
                       ulazak_staff:this.form.ulazak_staff

                          })
                        this.form.djelatnici.splice(0);
                        this.form.opis = ""
                        this.form.datum = new Date().toISOString().substr(0, 10)
                        this.form.djelatnici = []
                       

                      $("#myModal").modal('hide');  
                      this.$root.toastnoti("Uspjeh!", "Zadatak je napravljen", "uspjeh")
                 })
                 .catch((error) => {
                    console.log(error)
                 }).finally(() => {
                     //Perform action in always
                 });
                 
      },

      
    zatvori(){
       $("#myModal").modal('hide');  
    },

    vrijeme(zadatum){

      var date = new Date()
      var dan;
      var mjesec;


      dan = zadatum.split('.')[0];
      mjesec = zadatum.split('.')[1];
      zadatum =  new Date(date.getFullYear() , mjesec-1, dan).setHours(0,0,0,0);
      date = date.setHours(0,0,0,0)

      if(zadatum>date){
        return 'buduci'
      }
      else if(zadatum==date){
        return 'danas'
      }
      return 'prosli'


    },

        async getZadaci() {
          

      await axios
        .get('/api/zadaci/')
        .then(response => {
          this.data = response.data
          console.log("zadaciii")
          console.log(this.data.zadaci)
          this.countToDo = this.data.zadaci.filter(task => task.status === 0 || task.status === 1).length;
          console.log(this.countToDo)
        

          if(!this.reload){

            const recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute(
          "src",
          "/js/filter.js"
        );
        document.head.appendChild(recaptchaScript);
        $("#podsjetnik").css("visibility", "visible");
        $("#preloader").hide()
        console.log(this.data)

        this.choice1 = new Choices('#choices-multiple-remove-button1', {
          choices: this.data.vrste.map(function(i) {return { id: i[1], value: i[0], customProperties: i[1]};}),
        removeItemButton: true,
        maxItemCount:3,
        searchResultLimit:5,
        renderChoiceLimit:5
      }); 

    this.choice2 = new Choices('#choices-multiple-remove-button2', {
          choices: this.data.lokacije.map(function(i) {return { id: i[1], value: i[0], customProperties: i[1] };}),
        removeItemButton: true,
        maxItemCount:3,
        searchResultLimit:5,
        renderChoiceLimit:5
      }); 

      var choices = [];
      var djelatnicioznaceni = []
    this.data.grupe.forEach(function(grupa) {
      var groupChoices = [];
      groupChoices.push({ id: grupa.name, value: "Svi "+grupa.name });
      grupa.djelatnici.forEach(function(djelatnik) {
        
        if(!djelatnicioznaceni.includes(djelatnik)){
          groupChoices.push({ id: djelatnik, value: djelatnik });
          djelatnicioznaceni.push(djelatnik)
        }
        
      });
      
      choices.push({ label: grupa.name, choices: groupChoices });
    });

      this.choice3 = new Choices('#choices-multiple-remove-button3', {
          choices: choices,
        removeItemButton: true,
   
        searchResultLimit:5,
        renderChoiceLimit:5
      }); 
  





          }
           


        
        })
        .catch(error => {
          console.log(error)
            if (error.response.status === 401) this.$router.push('/korisnik/prijava')
          console.log(error)
        })

    },


    detalji(id){
      this.$router.push("/detalji/"+id)
 
    }



     

    
  },
  beforeDestroy() {

this.choice1.destroy();
this.choice2.destroy();
this.choice3.destroy();

}
}



</script>


<style scoped>

#baseApp:after {
    content: '';
    background-image: url(../assets/img/slider-right-dec.jpg);
    background-repeat: repeat-y;
    position: absolute;
    right: 0;
    top: 60px;
    width: 1159px;
    height: 797px;
    z-index: -1;
  }
  


</style>
<style>



@media only screen and (max-width: 600px) {
            .mobile-break {
                display: block;
            }
        }


#preloader {
  position: fixed;
  inset: 0;
  z-index: 1038;
  overflow: hidden;
  background: white;
  transition: all 0.6s ease-out;
  width: 100%;
  height: 100vh;
}

#preloader:before,
#preloader:after {
  content: "";
  position: absolute;
  border: 4px solid #0ea2bd;
  border-radius: 50%;
  -webkit-animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#preloader:after {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

@-webkit-keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 1;
  }

  100% {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    opacity: 0;
  }
}

@keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 1;
  }

  100% {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    opacity: 0;
  }
}


</style>

<style scoped>

  @import '../assets/css/filter/style.css';
  @import '../assets/css/filter/reset.css';

  #header {
    padding-right: 0px !important;
  }
  .modal {
    padding-right: 0px !important;
  }
  .modal-open {
    padding-right: 0px !important;
  }

</style>

<style scoped>

  
  @media screen and (min-width: 800px) {
    #karte{
    padding:40px;
  }
}
  @import '../assets/css/zadatak/zadatak1.css';
    h2 {
    color: var(--varyDarkBlue);
    font-weight: var(--weight3);
  }
  h1:first-of-type {
    font-weight: var(--weight1);
    color: var(--varyDarkBlue);
  
  }
  
  h1:last-of-type {
    color: var(--varyDarkBlue);
  }
</style>

<style>
:root {
  --color-primary: #0ea2bd;
  --color-primary-rgb: 14, 162, 189;
  --font-secondary: 'Poppins', sans-serif;
}
  .btn-getstarted,
  .btn-getstarted:focus {
    font-size: 16px;
    color: white;
    background: var(--color-primary);
    padding: 8px 23px;
    border-radius: 4px;
    transition: 0.3s;
    font-family: var(--font-secondary);
  }
  
  .btn-getstarted:hover,
  .btn-getstarted:focus:hover {
    color: var(--color-white);
    background: rgba(var(--color-primary-rgb), 0.85);
  }
  
  @media (max-width: 1279px) {
  
    .btn-getstarted,
    .btn-getstarted:focus {
      margin-right: 50px;
    }
  }
  
</style>


<style>

:root {
    scroll-behavior: smooth;
  }
  
  a {
    color: var(--color-links);
    text-decoration: none;
  }
  
  a:hover {
    color: var(--color-links-hover);
    text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-primary);
  }
  
  /*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
  #preloader {

  z-index: 9999;
  background: var(--color-white);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 99;
  
}

#loadingimage{
width:400px;
height:400px;
  
}

  /*--------------------------------------------------------------
  # Sections & Section Header
  --------------------------------------------------------------*/
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-header {
    text-align: center;
    padding-bottom: 40px;
  }
  
  .section-header h2 {
    font-size: 48px;
    font-weight: 300;
    margin-bottom: 20px;
    color: var(--color-secondary);
  }
  
  .section-header p {
    margin: 0 auto;
    color: var(--color-secondary-light);
  }
  
  @media (min-width: 1280px) {
    .section-header p {
      max-width: 80%;
    }
  }
  
  /*--------------------------------------------------------------
  # Scroll top button
  --------------------------------------------------------------*/
  .scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 995;
    background: var(--color-primary);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  
  .scroll-top i {
    font-size: 24px;
    color: var(--color-white);
    line-height: 0;
  }
  
  .scroll-top:hover {
    background: rgba(var(--color-primary-rgb), 0.85);
    color: var(--color-white);
  }
  
  .scroll-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  .header {
    padding: 15px 0;
    transition: all 0.5s;
    z-index: 997;
  }
  
  .header.sticked {
    background: var(--color-white);
    box-shadow: 0px 2px 20px rgba(var(--color-secondary-rgb), 0.1);
  }
  
  .header .logo img {
    max-height: 40px;
    margin-right: 6px;
  }
  
  .header .logo h1 {
    font-size: 32px;
    font-weight: 300;
    color: var(--color-secondary);
    font-family: var(--font-secondary);
  }
  
  .header .logo h1 span {
    color: var(--color-primary);
    font-weight: 500;
  }
  
  .header .btn-getstarted,
  .header .btn-getstarted:focus {
    font-size: 17px;
    color: var(--color-white);
    background: var(--color-primary);
    padding: 12px 25px;
    border-radius: 4px;
    transition: 0.3s;
    font-family: var(--font-secondary);
  }
  
  .header .btn-getstarted:hover,
  .header .btn-getstarted:focus:hover {
    color: var(--color-white);
    background: rgba(var(--color-primary-rgb), 0.85);
  }
  
  @media (max-width: 1279px) {
  
    .header .btn-getstarted,
    .header .btn-getstarted:focus {
      margin-right: 50px;
    }
  }
  
  /*--------------------------------------------------------------
  # Desktop Navigation 
  --------------------------------------------------------------*/
  @media (min-width: 1280px) {
    .navigacija {
      padding: 0;
      position: relative;
    }
  
    .navigacija ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      align-items: center;
    }
  
    .navigacija li {
      position: relative;
    }
  
    .navigacija>ul>li {
      white-space: nowrap;
    }
  
    .navigacija a,
    .navigacija a:focus {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 20px;
      font-family: var(--font-secondary);
      font-size: 16px;
      font-weight: 400;
      color: rgba(var(--color-secondary-dark-rgb), 0.7);
      white-space: nowrap;
      transition: 0.3s;
      position: relative;
    }
  
    .navigacija a i,
    .navigacija a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
    }
  
    .navigacija>ul>li>a:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: var(--color-primary);
      visibility: hidden;
      transition: all 0.3s ease-in-out 0s;
      transform: scaleX(0);
      transition: all 0.3s ease-in-out 0s;
    }
  
    .navigacija a:hover:before,
    .navigacija li:hover>a:before,
    .navigacija .active:before {
      visibility: visible;
      transform: scaleX(0.7);
    }
  
    .navigacija a:hover,
    .navigacija .active,
    .navigacija .active:focus,
    .navigacija li:hover>a {
      color: var(--color-primary);
    }
  
    .navigacija .dropdown a:hover:before,
    .navigacija .dropdown:hover>a:before,
    .navigacija .dropdown .active:before {
      visibility: hidden;
    }
  
    .navigacija .dropdown a:hover,
    .navigacija .dropdown .active,
    .navigacija .dropdown .active:focus,
    .navigacija .dropdown:hover>a {
      color: var(--color-white);
      background: var(--color-secondary);
    }
  
    .navigacija .dropdown ul {
      display: block;
      position: absolute;
      left: 0;
      top: 100%;
      margin: 0;
      padding: 0 0 10px 0;
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      background: var(--color-secondary);
      transition: 0.3s;
    }
  
    .navigacija .dropdown ul li {
      min-width: 200px;
    }
  
    .navigacija .dropdown ul a {
      padding: 10px 20px;
      font-size: 15px;
      text-transform: none;
      font-weight: 400;
      color: rgba(var(--color-white-rgb), 0.5);
    }
  
    .navigacija .dropdown ul a i {
      font-size: 12px;
    }
  
    .navigacija .dropdown ul a:hover,
    .navigacija .dropdown ul .active,
    .navigacija .dropdown ul .active:hover,
    .navigacija .dropdown ul li:hover>a {
      color: var(--color-white);
      background: var(--color-primary);
    }
  
    .navigacija .dropdown:hover>ul {
      opacity: 1;
      visibility: visible;
    }
  
    .navigacija .megamenu {
      position: static;
    }
  
    .navigacija .megamenu ul {
      right: 0;
      padding: 10px;
      display: flex;
    }
  
    .navigacija .megamenu ul li {
      flex: 1;
    }
  
    .navigacija .megamenu ul li a,
    .navigacija .megamenu ul li:hover>a {
      color: rgba(var(--color-white-rgb), 0.5);
      background: none;
    }
  
    .navigacija .megamenu ul li a:hover,
    .navigacija .megamenu ul li .active,
    .navigacija .megamenu ul li .active:hover {
      color: var(--color-white);
      background: var(--color-primary);
    }
  
    .navigacija .dropdown .dropdown ul {
      top: 0;
      left: calc(100% - 30px);
      visibility: hidden;
    }
  
    .navigacija .dropdown .dropdown:hover>ul {
      opacity: 1;
      top: 0;
      left: 100%;
      visibility: visible;
    }
  }
  
  @media (min-width: 1280px) and (max-width: 1366px) {
    .navigacija .dropdown .dropdown ul {
      left: -90%;
    }
  
    .navigacija .dropdown .dropdown:hover>ul {
      left: -100%;
    }
  }
  
  /*--------------------------------------------------------------
  # Mobile Navigation
  --------------------------------------------------------------*/
  @media (max-width: 1279px) {
    .navigacija { 
      position: fixed !important;
      top: 0;
      left: -100%;
      width: calc(100% - 70px);
      bottom: 0;
      transition: 0.3s;
      z-index: 9997;
    }
  
    .navigacija ul {
      position: absolute;
      inset: 0;
      padding: 10px 0;
      margin: 0;
      background: rgba(var(--color-secondary-rgb), 0.9);
      overflow-y: auto;
      transition: 0.3s;
      z-index: 9998;
    }
  
    .navigacija a,
    .navigacija a:focus {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(var(--color-white-rgb), 0.7);
      white-space: nowrap;
      transition: 0.3s;
    }
  
    .navigacija a i,
    .navigacija a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
    }
  
    .navigacija a:hover,
    .navigacija .active,
    .navigacija .active:focus,
    .navigacija li:hover>a {
      color: var(--color-white);
    }
  
    .navigacija .dropdown ul,
    .navigacija .dropdown .dropdown ul {
      position: static;
      display: none;
      padding: 10px 0;
      margin: 10px 20px;
      transition: all 0.5s ease-in-out;
      border: 1px solid rgba(var(--color-secondary-light-rgb), 0.3);
    }
  
    .navigacija .dropdown>.dropdown-active,
    .navigacija .dropdown .dropdown>.dropdown-active {
      display: block;
    }
  
    .mobile-nav-toggle {
      display: block !important;
      color: var(--color-secondary);
      font-size: 28px;
      cursor: pointer;
      line-height: 0;
      transition: 0.5s;
      position: fixed;
      top: 20px;
      z-index: 9999;
      right: 20px;
    }
  
    .mobile-nav-toggle.bi-x {
      color: var(--color-white);
    }
  
    .mobile-nav-active {
      overflow: hidden;
      z-index: 9995;
      position: relative;
    }
  
    .mobile-nav-active .navigacija {
      left: 0;
    }
  
    .mobile-nav-active .navigacija:before {
      content: "";
      position: fixed;
      inset: 0;
      background: rgba(var(--color-secondary-rgb), 0.8);
      z-index: 9996;
    }
  }
  
  /*--------------------------------------------------------------
  # Index Page
  --------------------------------------------------------------*/
  /*--------------------------------------------------------------
  # Animated Hero Section
  --------------------------------------------------------------*/
  .hero-animated {
    width: 100%;
    min-height: 50vh;
    
    background-size: cover;
    position: relative;
    padding: 120px 0 60px;
  }
  
  .hero-animated h2 {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 300;
    color: var(--color-secondary);
    font-family: var(--font-secondary);
  }
  
  .hero-animated h2 span {
    color: var(--color-primary);
  }
  
  .hero-animated p {
    color: rgba(var(--color-secondary-rgb), 0.8);
    margin: 0 0 30px 0;
    font-size: 20px;
    font-weight: 400;
  }
  
  .hero-animated .animated {
    margin-bottom: 60px;
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
  }
  
  @media (min-width: 992px) {
    .hero-animated .animated {
      max-width: 45%;
    }
  }
  
  @media (max-width: 991px) {
    .hero-animated .animated {
      max-width: 60%;
    }
  }
  
  @media (max-width: 575px) {
    .hero-animated .animated {
      max-width: 80%;
    }
  }
  
  .hero-animated .btn-get-started {
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    padding: 10px 28px;
    border-radius: 4px;
    transition: 0.5s;
    color: var(--color-white);
    background: var(--color-primary);
    font-family: var(--font-secondary);
  }
  
  .hero-animated .btn-get-started:hover {
    background: rgba(var(--color-primary-rgb), 0.8);
  }
  
  .hero-animated .btn-watch-video {
    font-size: 16px;
    transition: 0.5s;
    margin-left: 25px;
    font-family: var(--font-secondary);
    color: var(--color-secondary);
    font-weight: 600;
  }
  
  .hero-animated .btn-watch-video i {
    color: var(--color-primary);
    font-size: 32px;
    transition: 0.3s;
    line-height: 0;
    margin-right: 8px;
  }
  
  .hero-animated .btn-watch-video:hover {
    color: var(--color-primary);
  }
  
  .hero-animated .btn-watch-video:hover i {
    color: rgba(var(--color-primary-rgb), 0.8);
  }
  
  @media (max-width: 640px) {
    .hero-animated h2 {
      font-size: 32px;
    }
  
    .hero-animated p {
      font-size: 18px;
      margin-bottom: 30px;
    }
  
    .hero-animated .btn-get-started,
    .hero-animated .btn-watch-video {
      font-size: 14px;
    }
  }
  
  @-webkit-keyframes up-down {
    0% {
      transform: translateY(10px);
    }
  
    100% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes up-down {
    0% {
      transform: translateY(10px);
    }
  
    100% {
      transform: translateY(-10px);
    }
  }
  
  /*--------------------------------------------------------------
  # Featured Services Section
  --------------------------------------------------------------*/
  .featured-services .service-item {
    padding: 30px;
    transition: all ease-in-out 0.4s;
    background: var(--color-white);
    height: 100%;
  }
  
  .featured-services .service-item .icon {
    margin-bottom: 10px;
  }
  
  .featured-services .service-item .icon i {
    color: var(--color-primary);
    font-size: 36px;
    transition: 0.3s;
  }
  
  .featured-services .service-item h4 {
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 24px;
  }
  
  .featured-services .service-item h4 a {
    color: var(--color-secondary);
    transition: ease-in-out 0.3s;
  }
  
  .featured-services .service-item p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .featured-services .service-item:hover {
    transform: translateY(-10px);
    box-shadow: 0px 0 60px 0 rgba(var(--color-secondary-rgb), 0.1);
  }
  
  .featured-services .service-item:hover h4 a {
    color: var(--color-primary);
  }
  
  /*--------------------------------------------------------------
  # About Section
  --------------------------------------------------------------*/
  .about .about-img {
    position: relative;
    margin: 60px 0 0 60px;
  }
  
  .about .about-img:before {
    position: absolute;
    inset: -60px 0 0 -60px;
    z-index: -1;
    content: "";
  
    background-repeat: no-repeat;
  }
  
  @media (max-width: 575px) {
    .about .about-img {
      margin: 30px 0 0 30px;
    }
  
    .about .about-img:before {
      inset: -30px 0 0 -30px;
    }
  }
  
  .about h3 {
    color: var(--color-secondary);
    font-family: var(--font-secondary);
    font-weight: 300;
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .about h3 {
      font-size: 28px;
    }
  }
  
  .about .nav-pills {
    border-bottom: 1px solid rgba(var(--color-secondary-rgb), 0.2);
  }
  
  .about .nav-pills li+li {
    margin-left: 40px;
  }
  .nav-link{
    cursor: pointer;
  }
  .about .nav-link {

    background: none;
    font-size: 18px;
    font-weight: 400;
    color: var(--color-secondary);
    padding: 12px 0;
    margin-bottom: -2px;
    border-radius: 0;
    font-family: var(--font-secondary);
  }
  
  .about .nav-link.active {
    color: var(--color-primary);
    background: none;
    border-bottom: 3px solid var(--color-primary);
  }
  
  @media (max-width: 575px) {
    .about .nav-link {
      font-size: 16px;
    }
  }
  
  .about .tab-content h4 {
    font-size: 18px;
    margin: 0;
    font-weight: 700;
    color: var(--color-secondary);
  }
  
  .about .tab-content i {
    font-size: 22px;
    line-height: 0;
    margin-right: 8px;
    color: var(--color-primary);
  }
  
  /*--------------------------------------------------------------
  # Call To Action Section
  --------------------------------------------------------------*/
  .cta {
    padding: 0;
    margin-bottom: 60px;
  }
  
  .cta .container {
    padding: 80px;
    background: rgba(var(--color-secondary-rgb), 0.1);
    border-radius: 15px;
  }
  
  @media (max-width: 992px) {
    .cta .container {
      padding: 60px;
    }
  }
  
  .cta .content h3 {
    color: var(--color-secondary);
    font-size: 48px;
    font-weight: 700;
  }
  
  .cta .content h3 em {
    font-style: normal;
    position: relative;
  }
  
  .cta .content h3 em:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    height: 10px;
    background: rgba(var(--color-primary-rgb), 0.5);
    z-index: -1;
  }
  
  .cta .content p {
    color: var(--color-secondary);
    font-weight: 600;
    font-size: 18px;
  }
  
  .cta .content .cta-btn {
    color: var(--color-white);
    font-weight: 500;
    font-size: 16px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 5px;
    transition: 0.5s;
    margin-top: 10px;
    background: rgba(var(--color-primary-dark-rgb), 0.9);
  }
  
  .cta .content .cta-btn:hover {
    background: var(--color-primary);
  }
  
  .cta .img {
    position: relative;
  }
  
  .cta .img:before {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(var(--color-white-rgb), 0.5);
    border-radius: 15px;
    transform: rotate(12deg);
  }
  
  .cta .img:after {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(var(--color-white-rgb), 0.9);
    border-radius: 15px;
    transform: rotate(6deg);
  }
  
  .cta .img img {
    position: relative;
    z-index: 3;
    border-radius: 15px;
  }
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  .footer {
    color: var(--color-white);
    font-size: 14px;
  }
  
  .footer .footer-content {
    background: var(--color-secondary);
    padding: 60px 0 30px 0;
  }
  
  .footer .footer-content .footer-info {
    margin-bottom: 30px;
  }
  
  .footer .footer-content .footer-info h3 {
    font-size: 28px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .footer .footer-content .footer-info h3 span {
    color: var(--color-primary);
  }
  
  .footer .footer-content .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: var(--font-primary);
    color: var(--color-white);
  }
  
  .footer .footer-content h4 {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-white);
    position: relative;
    padding-bottom: 12px;
    margin-bottom: 15px;
  }
  
  .footer .footer-content h4::after {
    content: "";
    position: absolute;
    display: block;
    width: 20px;
    height: 2px;
    background: var(--color-primary);
    bottom: 0;
    left: 0;
  }
  
  .footer .footer-content .footer-links {
    margin-bottom: 30px;
  }
  
  .footer .footer-content .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer .footer-content .footer-links ul i {
    padding-right: 2px;
    color: var(--color-white);
    font-size: 12px;
    line-height: 1;
  }
  
  .footer .footer-content .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  .footer .footer-content .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  .footer .footer-content .footer-links ul a {
    color: rgba(var(--color-white-rgb), 0.7);
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  .footer .footer-content .footer-links ul a:hover {
    color: var(--color-white);
  }
  
  .footer .footer-content .footer-newsletter form {
    margin-top: 30px;
    background: var(--color-white);
    padding: 6px 10px;
    position: relative;
    border-radius: 4px;
  }
  
  .footer .footer-content .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
  }
  
  .footer .footer-content .footer-newsletter form input[type=email]:focus-visible {
    outline: none;
  }
  
  .footer .footer-content .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: var(--color-primary);
    color: var(--color-white);
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
  }
  
  .footer .footer-content .footer-newsletter form input[type=submit]:hover {
    background: rgba(var(--color-primary-rgb), 0.85);
  }
  
  .footer .footer-legal {
    padding: 30px 0;
    background: var(--color-secondary-dark);
  }
  
  .footer .footer-legal .credits {
    padding-top: 4px;
    font-size: 13px;
    color: var(--color-white);
  }
  
  .footer .footer-legal .credits a {
    color: var(--color-primary-light);
  }
  
  .footer .footer-legal .social-links a {
    font-size: 18px;
    display: inline-block;
    background: rgba(var(--color-white-rgb), 0.1);
    color: var(--color-white);
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  .footer .footer-legal .social-links a:hover {
    background: var(--color-primary);
    text-decoration: none;
  }
:root {
    /* Fonts */
    --font-default: 'Open Sans', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-primary: 'Source Sans Pro', sans-serif;
    --font-secondary: 'Poppins', sans-serif;
  
    /* Colors */
    /* The *-rgb color names are simply the RGB converted value of the corresponding color for use in the rgba() function */
  
    /* Default text color */
    --color-default: #1a1f24;
    --color-default-rgb: 26, 31, 36;
  
    /* Defult links color */
    --color-links: #0ea2bd;
    --color-links-hover: #1ec3e0;
  
    /* Primay colors */
    --color-primary: #0ea2bd;
    --color-primary-light: #1ec3e0;
    --color-primary-dark: #0189a1;
  
    --color-primary-rgb: 14, 162, 189;
    --color-primary-light-rgb: 30, 195, 224;
    --color-primary-dark-rgb: 1, 137, 161;
  
    /* Secondary colors */
    --color-secondary: #485664;
    --color-secondary-light: #8f9fae;
    --color-secondary-dark: #3a4753;
  
    --color-secondary-rgb: 72, 86, 100;
    --color-secondary-light-rgb: 143, 159, 174;
    --color-secondary-dark-rgb: 58, 71, 83;
  
    /* General colors */
    --color-blue: #0d6efd;
    --color-blue-rgb: 13, 110, 253;
  
    --color-indigo: #6610f2;
    --color-indigo-rgb: 102, 16, 242;
  
    --color-purple: #6f42c1;
    --color-purple-rgb: 111, 66, 193;
  
    --color-pink: #f3268c;
    --color-pink-rgb: 243, 38, 140;
  
    --color-red: #df1529;
    --color-red-rgb: 223, 21, 4;
  
    --color-orange: #fd7e14;
    --color-orange-rgb: 253, 126, 20;
  
    --color-yellow: #ffc107;
    --color-yellow-rgb: 255, 193, 7;
  
    --color-green: #059652;
    --color-green-rgb: 5, 150, 82;
  
    --color-teal: #20c997;
    --color-teal-rgb: 32, 201, 151;
  
    --color-cyan: #0dcaf0;
    --color-cyan-rgb: 13, 202, 240;
  
    --color-white: #ffffff;
    --color-white-rgb: 255, 255, 255;
  
    --color-gray: #6c757d;
    --color-gray-rgb: 108, 117, 125;
  
    --color-black: #000000;
    --color-black-rgb: 0, 0, 0;
    
  }
  
  /*--------------------------------------------------------------
  # 2. Override default Bootstrap variables
  --------------------------------------------------------------*/
  :root {
  
    --bs-blue: var(--color-blue);
    --bs-indigo: var(--color-indigo);
    --bs-purple: var(--color-purple);
    --bs-pink: var(--color-pink);
    --bs-red: var(--color-red);
    --bs-orange: var(--color-orange);
    --bs-yellow: var(--color-yellow);
    --bs-green: var(--color-green);
    --bs-teal: var(--color-teal);
    --bs-cyan: var(--color-cyan);
    --bs-white: var(--color-white);
    --bs-gray: var(--color-gray);
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: var(--color-blue);
    --bs-secondary: var(--color-blue);
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: var(--color-primary-rgb);
    --bs-secondary-rgb: var(--color-secondary-rgb);
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: var(--color-white-rgb);
    --bs-black-rgb: var(--color-black-rgb);
    --bs-body-color-rgb: var(--color-default-rgb);
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: var(--font-default);
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--font-default);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: var(--color-default);
    --bs-body-bg: #fff;
  
  }
  
  /*--------------------------------------------------------------
  # 3. Set color and background class names
  --------------------------------------------------------------*/
  /* Fonts */
  .font-default { font-family: var(--font-default) !important; }
  .font-primary { font-family: var(--font-primary) !important;}
  .font-secondary { font-family: var(--font-secondary) !important; }
  
  /* Text Colors */
  .color-default { color: var(--color-default) !important; }
  .color-links { color: var(--color-links) !important; }
  .color-links:hover { color: var(--color-links-hover) !important; }
  .color-primary { color: var(--color-primary) !important; }
  .color-primary-light { color: var(--color-primary-light) !important; }
  .color-primary-dark { color: var(--color-primary-dark) !important; }
  .color-secondary { color: var(--color-secondary) !important; }
  .color-secondary-light { color: var(--color-secondary-light) !important; }
  .color-secondary-dark { color: var(--color-secondary-dark) !important; }
  .color-blue { color: var(--color-blue) !important; }
  .color-indigo { color: var(--color-indigo) !important; }
  .color-purple { color: var(--color-purple) !important; }
  .color-pink { color: var(--color-pink) !important; }
  .color-red { color: var(--color-red) !important; }
  .color-orange { color: var(--color-orange) !important; }
  .color-yellow { color: var(--color-yellow) !important; }
  .color-green { color: var(--color-green) !important; }
  .color-teal { color: var(--color-teal) !important; }
  .color-cyan { color: var(--color-cyan) !important; }
  .color-white { color: var(--color-white) !important; }
  .color-gray { color: var(--color-gray) !important; }
  .color-black { color: var(--color-black) !important; }
  
  /* Background Colors */
  .bg-default { background-color: var(--color-default) !important; }
  .bg-primary { background-color: var(--color-primary) !important; }
  .bg-primary-light { background-color: var(--color-primary-light) !important; }
  .bg-primary-dark { background-color: var(--color-primary-dark) !important; }
  .bg-secondary { background-color: var(--color-secondary) !important; }
  .bg-secondary-light { background-color: var(--color-secondary-light) !important; }
  .bg-secondary-dark { background-color: var(--color-secondary-dark) !important; }
  .bg-blue { background-color: var(--color-blue) !important; }
  .bg-indigo { background-color: var(--color-indigo) !important; }
  .bg-purple { background-color: var(--color-purple) !important; }
  .bg-pink { background-color: var(--color-pink) !important; }
  .bg-red { background-color: var(--color-red) !important; }
  .bg-orange { background-color: var(--color-orange) !important; }
  .bg-yellow { background-color: var(--color-yellow) !important; }
  .bg-green { background-color: var(--color-green) !important; }
  .bg-teal { background-color: var(--color-teal) !important; }
  .bg-cyan { background-color: var(--color-cyan) !important; }
  .bg-white { background-color: var(--color-white) !important; }
  .bg-gray { background-color: var(--color-gray) !important; }
  .bg-black { background-color: var(--color-black) !important; }
  
  
  </style>