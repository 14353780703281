<template>
    <Navigation @reload-parent="methodThatForcesUpdate" yvisina=0 iocon="1">
      <template #atag><a></a></template>
      <template #content>
        
	<div id="notfound">
		<div class="notfound">
			
            <h1 style="color:#0ea2bd;">Račun koji se sprema na boravak</h1>
			<p style="font-size:20px; color:gray;">Ne može se stornirati</p><br>
      <label>Redni broj boravka gosta</label>
      <input class="form-control" type="number" v-model="form.hisid" min="1"><br>
  
       <select class="form-select" name="vrsta" aria-label="Default select example" v-model="form.selectoption">

<option value="tenis">Tenis</option>
<option value="multi">Multi</option>

</select>
<br>
      <button @click="posaljiporuku" class="btn btn-primary" :disabled="buttonDisabled">Spremi</button>

		</div>
	</div>
      </template>

</Navigation>



</template>

<script>
import Kodovi from '../components/Kodovi.vue'
import Navigation from '../components/Navigacija.vue'
import axios from 'axios'
export default {
  name: 'Info',
  data(){
    return{
      buttonDisabled:false,
      form:{
        hisid:"",
        selectoption:""
      },
      barcodes:[]
    
    }
  },
  components: {
        Navigation
    },

    
  mounted(){

  

    $('#preloader').remove();
  
  

  },
  methods:{
    posaljiporuku() {
      this.buttonDisabled = true
        console.log("poruka")
       
        
      axios.post('/api/upisstaff_tenis/', this.form)
        .then((res) => {
        console.log(res)
        this.$root.toastnoti("Uspjeh", "Račun je upisan na boravak", "uspjeh")
        this.buttonDisabled = false
	
            

        })
        .catch((error) => {
            if(error.response.status==400){
            this.$root.toastnoti("Greška", error.response.data, "error")
            this.buttonDisabled = false
          
          }
          this.$root.toastnoti("Greška", "Došlo je do greške", "greska")
        }).finally(() => {

        });

    },

 



  }

  
  
}
</script>
<style scoped>

#notfound {
  position: relative;
  height: 100vh;
  background: #f6f6f6;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  padding: 110px 40px;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
}

.notfound .notfound-404 {
  position: relative;
  height: 180px;
}

.notfound .notfound-404 h1 {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 165px;
  font-weight: 700;
  margin: 0px;
  color: #262626;
  text-transform: uppercase;
}

.notfound .notfound-404 h1>span {
  color: #00b7ff;
}

.notfound h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  color: #151515;
  margin-top: 0px;
  margin-bottom: 25px;
}

.notfound .notfound-search {
  position: relative;
  max-width: 320px;
  width: 100%;
  margin: auto;
}

.notfound .notfound-search>input {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  height: 50px;
  padding: 3px 65px 3px 30px;
  color: #151515;
  font-size: 16px;
  background: transparent;
  border: 2px solid #c5c5c5;
  border-radius: 40px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound .notfound-search>input:focus {
  border-color: #00b7ff;
}

.notfound .notfound-search>button {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 40px;
  height: 40px;
  text-align: center;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.notfound .notfound-search>button>span {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
      -ms-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
  margin-left: -3px;
}


@media only screen and (max-width: 767px) {
  .notfound h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 141px;
  }
}


</style>