<template><br>

    <Navigacija @reload-parent="methodThatForcesUpdate" yvisina=20 iocon="1">
      <template #atag><a></a></template>
      <template #content>
<div id="baseApp">
    
    
    
    <div class="container">
    <form style="padding:100px 50px 1px 50px;" v-on:submit.prevent="getSport">
      <input type="date" class="form-control" name="date" v-model="form.datum" v-on:input="getSport"><br>

    </form>

    
    
        <div class="row">
          <div  class="col-md-4 col-xl-3" v-if="Object.keys(this.datoteke).length == 0">
                <div class="card bg-c-tenis order-card">
      
    
                    <div class="card-block">
                        <i class=" f-right" style="cursor:pointer;"></i>
                        <span>
              
                            <h2 class="text-right">Prazno</h2>    
                          
                 
                        </span> 
    
                    </div>
                </div>
            </div>
            
    
    
            <div class="col-md-4 col-xl-3" style="padding:15px;" v-for="(item, index) in datoteke" :id="'termin'+i">
                <template v-if="item==0">
                    <div class="card bg-c-tenis order-card" >
      
                        <div class="card-block">
                            <i class="fa fa-arrow-right f-right" @click='rezervirajpotvrda(index)' style="cursor:pointer;"></i>
                            <span>
                                <h2 class="text-right">{{ index }}-{{ parseInt(index)+1 }}h</h2>    
                                
                            </span> 

                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="card bg-c-pink order-card" >
      
                        <div class="card-block">
                            <i class="fa fa-times f-right" @click="cancelpotvrda(parseInt(item[3]), parseInt(item[0]))" style="cursor:pointer;padding-left:20px;"></i>
                            

                            <span>
                              <h2 class="text-right" >{{ index }}-{{ parseInt(index)+1 }}h <br> <i class="fa fa-qrcode f-right" aria-hidden="true" style="font-size:30px;" @click="otvoribarkodform(0)"></i><template v-if="item[1]">{{ item[1] }}</template><template v-else>{{ item[2] }}</template></h2>    
                                
                            </span> 

                        </div>
                    </div>
                </template>
               
            </div>
            
    
    <div style="height:100px;"></div>
      </div>
    </div>
    

    <div id="preloader"></div>
  </div>

      </template>
    
    
    </Navigacija>

    
    <div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title modelheading" id="deleteLabel">Otkaži termin</h4>
            <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('deleteConfirmModal')"  ></i>
          </div>
        
          <div class="modal-footer d-flex justify-content-center">
           
         
            
      
      
            <button type="submit" id="submit" class="btn btn-info f-left modalbutton" data-dismiss="modal" @click="cancel">Potvrdi</button>
            <div style="padding-top:10%;" class="prazanprostor"></div>
    
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="barkodform" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title modelheading"  id="deleteLabel">Stvori barkodove</h4>
            <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('barkodform')"  ></i>
          </div>
          <div class="modal-body">
            <label>Koliko ih trebate?</label>
            <input type="number" class="form-control" v-model="qnt">
          </div>
          <div class="modal-footer d-flex justify-content-center">
         
            <button type="submit" id="submit" class="btn btn-info f-left modalbutton" data-dismiss="modal" @click="barkod()">Potvrdi</button>
            <div style="padding-top:10%;" class="prazanprostor"></div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="modal fade" id="rezervirajModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
      <div class="modal-dialog inputmodal-dialog">
        <div class="modal-content inputmodal-content">
          <div class="modal-header">
            <h4 class="modal-title modelheading" id="deleteLabel" style="col">Rezerviraj</h4>
            <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('rezervirajModal')"  ></i>
          </div>
          <div class="modal-body">

            <input class="form-control" placeholder="Opis" v-model="form.ime" required>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="submit" id="submit" class="btn btn-info f-left modalbutton" data-dismiss="modal" @click="rezerviraj">Rezerviraj</button>
            <div style="padding-top:10%;" class="prazanprostor"></div>
            
            
          </div>
        </div>
      </div>
    </div>
    
      </template>
      
     
      
      <script>
      import JsBarcode from 'jsbarcode';
      import axios from 'axios'
      import Navigacija from '../components/Navigacija.vue'

      export default {
        name: 'Info',
        components: {
          Navigacija
        },
      
        data(){
          return{
            broj:0,
            qnt:1,
            datoteke:[],
            id:"",
            cancelsat:"",

            form:{
                ime:"",
                terminsat:"",
                datum:new Date().toISOString().substr(0, 10),
                vrsta:"tenis",
                broj:0
            },
            barcodes:[],
          }
        },
        mounted(){
          
          const preloader = document.querySelector('#preloader');
          
          this.getSport()

        
      
        },
 
      
        methods:{
          methodThatForcesUpdate(key){
      
            this.getSport()


  },


   
    otvoribarkodform(broj){

      this.broj = broj
     
      this.$root.otvoriform('barkodform')

    },

          
          async barkod(){
            $('#barkodform').modal('hide')
            await axios
            
            .get("/placanje/staffbarcode/"+this.form.datum+"/"+this.broj+"/"+this.qnt)
            .then(response => {
              this.$root.toastnoti("Uspjeh", "Isprintajte barkodove", "uspjeh")
          

              this.barcodes = response.data['uids']
              this.$router.push({
  name: 'SportKodovi',
  query: {
    uids: this.barcodes,
    vrsta:"tenis"
  }
})
             
            })
             
            .catch(error => {
              console.log(error)
              this.$root.toastnoti("Error", "Greška", "uspjeh")
             
            })

          },
      
        async cancelpotvrda(id, cancelsat){
            
            this.cancelsat = cancelsat
            this.id = id
            this.$root.otvoriform('deleteConfirmModal')
          
        },

        async rezervirajpotvrda(sat, cancelsat){
            
            this.form.terminsat = sat
            
            this.$root.otvoriform('rezervirajModal')
    
        },
        async cancel(){
  
              var apilink = '/api/odgoditenis/'+this.id
         
            await axios
            
              .get(apilink)
              .then(response => {
                this.datoteke[this.cancelsat] = 0;
                $('#deleteConfirmModal').modal('hide')
                this.$root.toastnoti("Uspjeh", "Odgođen", "uspjeh")
              
      
        
              })
              .catch(error => {
                this.$root.toastnoti("Error", "Greška", "uspjeh")
               
              })
    
        },
        async rezerviraj(){
   
    
              console.log(this.form)
   axios.post('/api/rezervirajtenis/', this.form)
       .then((res) => {

         this.$root.toastnoti("Uspjeh", "Rezervacija je dodana", "uspjeh")

            this.datoteke[this.form.terminsat] = [this.form.terminsat, this.form.ime, null, res.data]

            $('#rezervirajModal').modal('hide') 
            this.form.ime = ""        
       })
       .catch((error) => {
         this.$root.toastnoti("Greška", error, "error")
       })
      

     
 
     },
    
       
          async getSport() {
            const inputDate = new Date(this.form.datum); // replace with your input date
      const now = new Date();
  
      const hasAlreadyPassed = inputDate < now &&
                              (inputDate.getFullYear() < now.getFullYear() ||
                              (inputDate.getFullYear() === now.getFullYear() && inputDate.getMonth() < now.getMonth()) ||
                              (inputDate.getFullYear() === now.getFullYear() && inputDate.getMonth() === now.getMonth() && inputDate.getDate() < now.getDate()));
  
  
            if (hasAlreadyPassed) {
            this.$root.toastnoti("Info", "Date has passed", "error")

          }
          if (!this.form.datum) {
            this.$root.toastnoti("Uspjeh", "Unesi datum", "error")
            return false;
          }
            
      
            await axios
              .get('/api/tenis/'+this.form.datum)
              .then(response => {
                
                    this.datoteke = response.data.termini
                    console.log(this.datoteke)

       
                
                $("#preloader").hide();
      
        
              })
              .catch(error => {
                this.$root.toastnoti("Uspjeh", "Rez", "greska")
               
              })
      
          },
        }
      }
      </script>
    
    
    <style scoped>
 

#baseApp:after {
    content: '';
    background-image: url(../assets/img/services-left-dec.jpg);
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 200px;
    width: 786px;
    height: 1217px;
    
    z-index: -1;
    opacity: 0.5;
  }
  



    
    .btn-getstarted {
        font-size: 16px;
        color: var(--color-white);
        background: var(--color-primary);
        padding: 8px 23px;
        border-radius: 4px;
        transition: 0.3s;
        font-family: var(--font-secondary);
        border:none;
  
    
    
    }
  
    
    .order-card {
        color: #fff;
    }
    
    .bg-c-blue {
        background: linear-gradient(45deg,#4099ff,#73b4ff);
    }
    
    .bg-c-green {
    
        background: linear-gradient(45deg, rgba(46, 216, 182, 0.7), rgba(89, 224, 197, 0.7));
        
    }
    
    .bg-c-yellow {
        background: linear-gradient(45deg,#FFB64D,#ffcb80);
    }
    
    .bg-c-pink {
        background: linear-gradient(45deg,#FF5370,#ff869a);
    }
    
    
    .card {
        border-radius: 5px;
        -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
        box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
        border: none;
   
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    
    .card .card-block {
        padding: 25px;
    }
    
    .order-card i {
        font-size: 26px;
    }
    
    .f-left {
        float: left;
    }
    
    .f-right {
        float: right;
    }
    
    
    .bg-c-tenis {
        background: linear-gradient(45deg,#0ea2bd,#0ea2bd);
    }
    .bg-c-pink {
    background: linear-gradient(45deg,#FF5370,#ff869a);
}
    </style>
     <style scoped>
      
     .hero-animated {
         padding: 50px 0 0px; 
         min-height: 30vh;
     }
     
     section {
         padding: 0px 0; 
     
     }
   
     .hero-animated p {
       color: rgba(var(--color-secondary-rgb),.8);
       margin: 0 0 30px 0;
       font-size: 20px;
       font-weight: 400;
   }
   
   @media (max-width: 640px){
     .hero-animated p {
       font-size: 18px;
       margin-bottom: 30px;
   }
   }
   
     
     </style>