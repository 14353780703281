<template><br>


    <Navigation @reload-parent="methodThatForcesUpdate" yvisina=15 iocon="1">
   
  
      <template #atag><a></a></template>
    <template #content>
      <div id="baseApp">
    <section id="hero-animated" class="hero-animated d-flex align-items-center">
        <div class="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">

          <h2><span>Depilation</span></h2>
          <p >Oasis Wellnes & Spa</p>

          
          
       
        </div>
    
    </section>
    <section class="menu">
      <div class="container">
      <div class="row">
      <div class="col-md-12">

      </div>
      </div>
      <div class="food-menu wow fadeInUp">
      <div class="row">
      <div class="col-md-12"> 

        <div class="menu-tags">
    <select class="form-select" aria-label="Hairdresser" @change="filter($event.target.value)">
    <optgroup label="Depilation">
        <option value="40">LEGS AND BRAZILIAN - 90 MIN</option>
        <option value="41">LEGS AND BIKINI - 90 MIN</option>
        <option value="42">LEGS - 60 MIN</option>
        <option value="43">THIGHS - 30 MIN</option>
        <option value="44">LOWER LEGS - 30 MIN</option>
        <option value="45">BIKINI AREA - 30 MIN</option>
        <option value="46">BRAZILIAN - 30 MIN</option>
        <option value="47">ARMS - 30 MIN</option>
        <option value="48">UNDERARMS - 15 MIN</option>
        <option value="49">UPPER LIP - 15 MIN</option>
        <option value="50">FACE - 30 MIN</option>
    </optgroup>
    <optgroup label="Sugar Paste Depilation">
        <option value="51">LEGS AND BRAZILIAN - 90 MIN</option>
        <option value="52">LEGS AND BIKINI - 90 MIN</option>
        <option value="53">LEGS - 60 MIN</option>
        <option value="54">THIGHS - 30 MIN</option>
        <option value="55">LOWER LEGS - 30 MIN</option>
        <option value="56">BIKINI AREA - 30 MIN</option>
        <option value="57">BRAZILIAN - 30 MIN</option>
        <option value="58">ARMS - 30 MIN</option>
        <option value="59">UNDERARMS - 15 MIN</option>
        <option value="60">UPPER LIP - 15 MIN</option>
        <option value="61">FACE - 30 MIN</option>
    </optgroup>
    <optgroup label="Thread - Threading Depilation">
        <option value="62">EYEBROW THREADING - 25 MIN</option>
        <option value="63">UPPER LIP THREADING - 15 MIN</option>
        <option value="64">FACE THREADING - 45 MIN</option>
    </optgroup>
    <optgroup label="Men's Waxing">
        <option value="65">LEGS - 60 MIN</option>
        <option value="66">CHEST - 30 MIN</option>
        <option value="67">ABDOMEN - 30 MIN</option>
        <option value="68">BACK - 45 MIN</option>
    </optgroup>
</select>
    </div>

      </div>

      </div>
<br>


      </div>
      </div>
      </section>
    
    <div class="container">
    <form style="padding:50px;" v-on:submit.prevent="getSport">
      <input type="date" class="form-control" name="date"  v-model="form.datum" v-on:input="getSport"><br>
  
    </form>
  
  
    <div style="color:gray;padding:10px;margin-bottom:10px;" class="cd-fail-message">Appointments for the chosen date:</div>
    
    
        <div class="row">
          <div  class="col-md-4 col-xl-3" v-if="areKeysEmpty(this.data)">
                  <div class="card bg-c-tenis order-card">
        
      
                      <div class="card-block">
                          <i class=" f-right" style="cursor:pointer;"></i>
                          <span>
                
                              <h2 class="text-right">No availability</h2>    
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
      
          <div class="col-md-4 col-xl-3" style="padding:15px;"  :id="'termin'+ i" v-for="i in datoteke[getTimeValue(this.form.vrsta)]">
                <template v-if="Array.isArray(i)">
                    <div class="card bg-c-pink order-card" >
        
      
        <div class="card-block">
            <i class="fa fa-times f-right"  @click="cancelpotvrda(i[5], this.form.datum, i[0])" style="cursor:pointer;"></i>
            
            <span>{{ tretmani[i[1]] }}</span>

            <span>

                <h2 class="text-right">{{TimeString(i[0])}}h-
                    <span> {{TimeString2(i[0], this.getTimeValue(i[1]))}} </span>

                   


                    
                    <br>
            <span v-if="i[3]">{{i[3]}}</span><span v-else>{{i[2]}}</span></h2>    
   
     
            </span> 

        </div>
    </div>
                            </template>

                    <template v-else>
                        <div class="card bg-c-tenis order-card" >
        
      
        <div class="card-block">
            <i class="fa fa-arrow-right f-right"  @click="rezervirajpotvrda(i)" style="cursor:pointer;"></i>
         
           
            <span>

                <h2 class="text-right">{{TimeString(i)}}h - {{TimeString2(i, this.getTimeValue(this.form.vrsta))}}</h2>    
              
     
            </span> 

        </div>
    </div>
                    </template>
                 
              </div>
            
        
  

    <div style="height:200px;"></div>
      </div>
    </div>
  
  
  <!-- ======= Footer ======= -->
  <footer id="footer" class="footer">
  
  <div class="footer-content">
    <div class="container">
      <div class="row">
  
        <div class="col-lg-12 col-md-12">
          <div class="footer-info">
            <h3>Paleo Park massage</h3>
            <p>
     
          <p>At Paleo park 52211, Bale</p><br>
          <a href="https://www.monperin.com/experiences/massage">Where?</a><br>
          
            </p>
          </div>
        </div>
  
  
        
  
        <div class="col-lg-4 col-md-12 footer-newsletter">
  
  
  
  
  </div>
  
  
  
  
      </div>
    </div>
  </div>
  
  
  </footer><!-- End Footer -->



    

  
  </div>

   
    </template>

  </Navigation>


  <div class="modal fade" id="rezervirajModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
  <div class="modal-dialog modal-lg inputmodal-dialog">
    <div class="modal-content inputmodal-content">
      <div class="modal-header">
        <h4 class="modal-title modelheading" id="deleteLabel">Reserve Massage</h4>
        <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('rezervirajModal')"  ></i>

      </div>
      <div class="modal-body">
   
        <form>

      
          <input class="form-control" placeholder="Opis" v-model="form.ime">
   
      </form>

      </div>
      <div class="modal-footer d-flex justify-content-center">
          <button type="submit" id="submit" class="btn btn-info f-left modalbutton" data-dismiss="modal" @click="rezerviraj">Potvrdi</button>
          <div style="padding-top:10%;" class="prazanprostor"></div>
      </div>
    </div>
  </div>
</div>



  <div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title modelheading" id="deleteLabel">Otkaži</h4>
            <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('deleteConfirmModal')"  ></i>
          </div>
   
          <div class="modal-footer d-flex justify-content-center">
              <button type="submit" id="submit" class="btn btn-info f-left modalbutton" data-dismiss="modal" @click="cancel">Potvrdi</button>
          <div style="padding-top:10%;" class="prazanprostor"></div>

          </div>
        </div>
      </div>
    </div>
    

      </template>
      
   
      <script>
      import axios from 'axios'
      import Navigation from '../../components/Navigacija.vue'
      export default {
        name: 'Info',
        components: {
          Navigation
        },
        computed: {

  },
      
        data(){
          return{
            datoteke:[],
            rezervacije:[],
            id:"",
            canceldatum:"",
            cancelsat:"",
            navBarKey:0,
            buttonDisabled: false,
            prijava:false,
            email:"",

            form:{
              datum:new Date().toISOString().substr(0, 10),
              ime:"",
              terminsat:"",
              vrsta:"",
              trideset:false,
              lokacija:"depilation",
              soba:5,
  
            },
            cijene:{
                40: 35,
                41: 30,
                42: 25,
                43: 25,
                44: 15,
                45: 10,
                46: 15,
                47: 15,
                48: 7,
                49: 5,
                50: 15,
                51: 45,
                52: 40,
                53: 35,
                54: 30,
                55: 20,
                56: 15,
                57: 17,
                58: 15,
                59: 10,
                60: 8,
                61: 18,
                62: 14,
                63: 7,
                64: 20,
                65: 35,
                66: 20,
                67: 20,
                68: 30
              },
              tretmani:{
     
                10:"HYDRATION AND REGENERATION TREATMENT PLATINUM FORLLED 1 hour 30 min",
                11:"TREATMENT BIOFILER FORLLED ANTI AGE 1 hour 30 min",
                12:"AESTHETIC NON-INVASIVE TREATMENTS HYALURONIC FILLER + BOTOX FACIAL 1 hour 30 min",
                13:"AESTHETIC NON-INVASIVE TREATMENTS SKIN BOOSTER + BOTOX FACIAL 1 hour 30 min",
                14:"AESTHETIC NON-INVASIVE TREATMENTS HYALURONIC FILLER 1 hour 30 min",
                14:"AESTHETIC NON-INVASIVE FACIAL BOTOX TREATMENTS 1 hour 30 min",
                14:"AESTHETIC NON-INVASIVE SKIN BOOSTER TREATMENTSL 1 hour 30 min",
                40: "LEGS AND BRAZILIAN - 90 MIN",
                41: "LEGS AND BIKINI - 90 MIN",
                42: "LEGS - 60 MIN",
                43: "THIGHS - 30 MIN",
                44: "LOWER LEGS - 30 MIN",
                45: "BIKINI AREA - 30 MIN",
                46: "BRAZILIAN - 30 MIN",
                47: "ARMS - 30 MIN",
                48: "UNDERARMS - 15 MIN",
                49: "UPPER LIP - 15 MIN",
                50: "FACE - 30 MIN",
                51: "LEGS AND BRAZILIAN (Sugar Paste) - 90 MIN",
                52: "LEGS AND BIKINI (Sugar Paste) - 90 MIN",
                53: "LEGS (Sugar Paste) - 60 MIN",
                54: "THIGHS (Sugar Paste) - 30 MIN",
                55: "LOWER LEGS (Sugar Paste) - 30 MIN",
                56: "BIKINI AREA (Sugar Paste) - 30 MIN",
                57: "BRAZILIAN (Sugar Paste) - 30 MIN",
                58: "ARMS (Sugar Paste) - 30 MIN",
                59: "UNDERARMS (Sugar Paste) - 15 MIN",
                60: "UPPER LIP (Sugar Paste) - 15 MIN",
                61: "FACE (Sugar Paste) - 30 MIN",
                62: "EYEBROW THREADING - 25 MIN",
                63: "UPPER LIP THREADING - 15 MIN",
                64: "FACE THREADING - 45 MIN",
                65: "MEN'S LEGS WAXING - 60 MIN",
                66: "MEN'S CHEST WAXING - 30 MIN",
                67: "MEN'S ABDOMEN WAXING - 30 MIN",
                68: "MEN'S BACK WAXING - 45 MIN"
              }
          }
        },
        mounted(){
          this.minute("pedeset")
          this.filter(40)
          this.platform = Capacitor.getPlatform()
          
          const preloader = document.querySelector('#preloader');
          this.prijava = this.$store.getters.isAuth;
       
          this.getSport()
        
      
        },
      
        methods:{
          methodThatForcesUpdate(key){
    
    this.getSport()


},

          async togglepolicy(tekst){
            $("#"+tekst).toggle();
          },
          getSlotValue(slot) {
          if (Array.isArray(slot)) {
              return slot[0];
          } else {
              return slot;
          }
          },

          
    

  
        async cancelpotvrda(id, datum, sat){
          console.log(id)
            this.id = id
            this.canceldatum = datum
            this.cancelsat = sat
      
            this.$root.otvoriform('deleteConfirmModal')
    
        },
  
  
         moguceodgoditi(datum, sat){
          const targetDate = new Date(`${datum}T${sat.toString().padStart(2, '0')}:00:00`)
          const now = new Date();
          const diffInHrs = Math.floor((targetDate - now) / (1000 * 60 * 60));
          
  
          if (diffInHrs > 2 && targetDate > now) {
            return true
          } else {
            return false
          }
  
    
        },
        async rezervirajpotvrda(sat){
            this.form.terminsat = sat

            this.$root.otvoriform('rezervirajModal')
    
    
        },
        async cancel(){
  
              var apilink = '/api/odgodimasaza/'+this.id
              this.canceldatum = this.canceldatum + " " + this.cancelsat+":00:00"
  
    
            await axios
            
              .get(apilink)
              .then(response => {

                this.$root.toastnoti("Uspjeh", "Termin odgođen", "uspjeh")
   
                this.$root.zatvoriform('deleteConfirmModal')
                this.getSport()
      
        
              })
              .catch(error => {
                  console.log(error)
                this.$root.toastnoti("Greška", "Došlo je do greške", "uspjeh")
               
              })
    
        },
        async rezerviraj(){
          this.buttonDisabled = true
          console.log(this.form)
          
        
            axios.post('/api/rezervirajmasaza/', this.form)
          .then((res) => {
  
            this.$root.toastnoti("Uspjeh", "Rezrevirano" , "uspjeh")
              this.rezervacije.push([this.form.terminsat, this.form.datum, res.data, this.form.vrsta])
              $("#termin"+this.form.terminsat).remove();
              this.buttonDisabled = false;
              this.getSport()
              this.form.ime = ""
                  
            })
          .catch((error) => {
            console.log(error)
            this.$root.toastnoti("Greška", "Greška", "error")
            this.buttonDisabled = false;
          })
  
          
          this.$root.zatvoriform('rezervirajModal')
    
        },
       
          async getSport() {
            $("#preloader").show()
            this.datoteke = {}
                const inputDate = new Date(this.form.datum); // replace with your input date
      const now = new Date();
  
      const hasAlreadyPassed = inputDate < now &&
                              (inputDate.getFullYear() < now.getFullYear() ||
                              (inputDate.getFullYear() === now.getFullYear() && inputDate.getMonth() < now.getMonth()) ||
                              (inputDate.getFullYear() === now.getFullYear() && inputDate.getMonth() === now.getMonth() && inputDate.getDate() < now.getDate()));
  
  
       
          const dayOfWeek = inputDate.getDay();
                  
          if([1].includes(dayOfWeek)){
              this.$root.toastnoti("Info", "Depilacija ne radi ponedjeljkom", "info")
              $("#preloader").hide()
              return false
          }

          if (!this.form.datum) {
            this.$root.toastnoti("Greška", "Unesite datum", "error")
            return false;
          }
            //$("#preloader").show();
      
            await axios
            .get('/api/masazatermini/depilation/'+this.form.datum+'/'+this.form.soba)
              .then(response => {
                this.datoteke = response.data
                console.log(this.datoteke)
                this.mergedSlots()
                $("#preloader").hide();
      
        
              })
              .catch(error => {
                console.log(error)
                  this.$root.toastnoti("Greška", "Došlo je do greške", "error")
                  $("#preloader").hide();
               
              })
      
          },
          
          mergedSlots() {
                const numbers = [30, 50, 120, 80, 20, 15, 25, 25, 60, 90];

for (let i = 0; i < numbers.length; i++) {
    const number = numbers[i];
    let merged = [];
    merged = [...this.datoteke[number]];

    for (const slot of this.datoteke.zauzeti) {
        const slotTime = slot[0].substring(0, 5); // Extract hour and minute portion
        const index = merged.findIndex((time) => time > slotTime);
        if (index === -1) {
            merged.push(slot);
        } else {
            merged.splice(index, 0, slot);
        }
    }

    this.datoteke[number] = merged;
}

               
      console.log(this.datoteke)

        },
      getTimeValue(value) {
      const timeMapping = {
        8: 20,
        7: 80,
        2: 30, 4: 30, 43: 30, 44: 30, 45: 30, 46: 30, 47: 30, 50: 30,
        54: 30, 55: 30, 56: 30, 57: 30, 58: 30, 61: 30, 66: 30, 67: 30,
        10: 90, 11: 90, 12: 90, 13: 90, 14: 90, 15: 90, 16: 90, 40: 90, 
        41: 90, 51: 90, 52: 90,
        1: 50, 3: 50, 5: 50, 6: 50,
        42: 60, 53: 60, 65: 60,
        64: 45, 68: 45,
        62: 25,
        48: 15, 49: 15, 59: 15, 60: 15, 63: 15
      };
      return timeMapping[value] || null; // Return null if the value is not found
    },
    areKeysEmpty(obj) {
                if(obj) {
                    const keys = [15, 20, 25, 30, 45, 50, 60, 80, 90, 120];

return keys.every(key => Array.isArray(obj[key]) && obj[key].length === 0);
                }
                else{
                    return false
                }
        
},
    
    
          filter(vrsta){

      $('.kategorija').css('opacity', '0.5').removeClass('tagsort-active');
      $('#'+vrsta).addClass('tagsort-active').css('opacity', '1');
      this.form.vrsta = vrsta
      console.log(this.form.vrsta)
      if(vrsta != 0 && vrsta!=1){
        $('.vrijeme').css('opacity', '0.5').removeClass('tagsort-active');
        $('#pedeset').addClass('tagsort-active').css('opacity', '1');
        this.form.trideset = false
      }

      $('.'+vrsta ).show()


  },
  minute(vrsta){
      $('.vrijeme').css('opacity', '0.5').removeClass('tagsort-active');
      $('#'+vrsta).addClass('tagsort-active').css('opacity', '1');
if(vrsta=="trideset"){
  this.form.trideset = true
}
else{
  this.form.trideset=false
}

$('.'+vrsta ).show()


},
vrstaime(vrsta){
      switch (vrsta) {  

    case 0 : return 'Back, neck, head and shoulders';
    case 1 : return 'Foot Reflexolog';
    case 2: return 'Coconut Massage';
    case 3: return 'Aroma massage';
    case 4: return 'Thai oil Massage';
    case 5: return 'Thai traditional massage';
    case 6: return 'Organic olive pulp massage';
    default: return 'Foot Reflexolog';
  }
  





},
TimeString(time){

var time = new Date('2023-01-01T' + time);
var updatedTimeString = time.toTimeString().slice(0, 5);

return updatedTimeString
},
TimeString2(time, broj){

var time = new Date('2023-01-01T' + time);
time.setMinutes(time.getMinutes() + broj);

var updatedTimeString = time.toTimeString().slice(0, 5);

return updatedTimeString
}
        }
      }
      </script>
    
    
    <style scoped>


#baseApp:after {
  content: '';
  background-image: url(../../assets/img/slider.jpg);
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 60px;
  width: 262px;
  height: 625px;
  z-index: -1;
  opacity: 0.5;
}



    
    .btn-getstarted {
        font-size: 16px;
        color: var(--color-white);
        background: var(--color-primary);
        padding: 8px 23px;
        border-radius: 4px;
        transition: 0.3s;
        font-family: var(--font-secondary);
        border:none;
  
    
    
    }
  
    
    .order-card {
        color: #fff;
    }
    
    .bg-c-blue {
        background: linear-gradient(45deg,#4099ff,#73b4ff);
    }
    
    .bg-c-green {
    
        background: linear-gradient(45deg, rgba(159, 217, 207, 0.7), rgba(89, 224, 197, 0.7));
        
    }
    
    .bg-c-yellow {
        background: linear-gradient(45deg,#FFB64D,#ffcb80);
    }
    
    .bg-c-pink {
        background: linear-gradient(45deg,#FF5370,#ff869a);
    }
    
    
    .card {
        border-radius: 5px;
        -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
        box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
        border: none;
        margin-bottom: 30px;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    
    .card .card-block {
        padding: 25px;
    }
    
    .order-card i {
        font-size: 26px;
    }
    
    .f-left {
        float: left;
    }
    
    .f-right {
        float: right;
    }
    
    
    .bg-c-tenis {
        background: linear-gradient(45deg,#bed6db,#44a3b6);
    }
    </style>
    
    <style scoped>
      
    .hero-animated {
        padding: 50px 0 0px; 
        min-height: 30vh;
    }
    
    section {
        padding: 0px 0; 
    
    }
  
    .hero-animated p {
      color: rgba(var(--color-secondary-rgb),.8);
      margin: 0 0 30px 0;
      font-size: 20px;
      font-weight: 400;
  }
  
  @media (max-width: 640px){
    .hero-animated p {
      font-size: 16px;
      margin-bottom: 30px;
  }
  }
  
  .menu-tags {
      text-align: center;
      margin-bottom: 20px
  }
  .menu-tags2, .menu-tags3, .menu-tags4 {
      text-align: center;
      margin-bottom: 50px;
      margin-top: 20px
  }
  .menu-tags span, .menu-tags2 span, .menu-tags3 span, .menu-tags4 span {
      font-family: raleway, sans-serif;
      font-size: 18px;
      color: #828281;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0 20px;
      padding-bottom: 2px;
      padding-top: 5px;
      cursor: pointer;
      transition: all .5s ease;
      border-bottom: 3px solid transparent
  }
  .menu-tags .tagsort-active, .menu-tags2 .tagsort2-active, .menu-tags3 .tagsort3-active, .menu-tags4 .tagsort4-active {
      border-bottom: 3px solid #5fbae9;
      color: #4b4741;
  
  }
  .menu .food-menu .menu-item, .menu .food-menu .menu-item3 {
      overflow: hidden;
      margin: 15px 0;
      padding-left: 10px;
      border-left: 3px solid #fff
  }
  .menu .food-menu .menu-item.featured {
      border-left: 3px solid #f9c56a
  }
  .menu .food-menu .menu-item .dotted-bg, .menu .food-menu .menu-item3 .dotted-bg {
      border-top: dotted 2px #ccc;
      left: 0;
      top: 15px;
      width: 100%;
      z-index: -1
  }
  .menu .food-menu .menu-item .menu-wrapper, .menu .food-menu .menu-item3 .menu-wrapper {
      position: relative;
      z-index: 1
  }
  .menu .food-menu .menu-item h4 class="testh4", .menu .food-menu .menu-item3 h4 class="testh4" {
      text-align: left;
      margin: 5px 0;
      float: left;
      padding-right: 10px;
      display: inline;
      background: #fff
  }
  .menu .food-menu .menu-item p, .menu .food-menu .menu-item3 p {
      font-size: 18px
  }
  .menu .food-menu .menu-item .price, .menu .food-menu .menu-item3 .price {
      float: right;
      padding-left: 10px;
      line-height: 19.8px;
      margin: 5px 0;
      background: #fff none repeat scroll 0% 0%;
      font-family: Josefin Sans;
      font-size: 22px;
      font-weight: 700
  }
  .menu .food-menu .menu-item-active {
      border-left: 3px solid #f9c56a
  }
  .menu .food-menu .menu-btn {
      text-align: center;
      padding: 20px 0
  }
  
  .menu-more, .menu-more:hover {
      width: 55px;
      height: 55px;
      -webkit-border-radius: 27px 28px 28px 27px/27px 27px 28px 28px;
      -moz-border-radius: 27px 28px 28px 27px/27px 27px 28px 28px;
      border-radius: 27px 28px 28px 27px/27px 27px 28px 28px;
      background-color: #f9c56a;
      -webkit-box-shadow: 0 5px 4px rgba(177, 177, 177, .75);
      -moz-box-shadow: 0 5px 4px rgba(177, 177, 177, .75);
      box-shadow: 0 5px 4px rgba(177, 177, 177, .75);
      text-shadow: 0 5px 5px #c1913e;
      border: solid 1px #f2ba57;
      color: #fff;
      line-height: 0;
      font-weight: 300;
      font-size: 65px;
      text-align: center;
      display: table;
      position: absolute;
      z-index: 48;
      bottom: -27.5px;
      right: 45px;
      font-family: Raleway;
      padding-top: 27px;
      text-decoration: none
  }
  .menu2-overlay {
      background: rgba(19, 19, 19, .7);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      opacity: 0;
      visibility: hidden;
      transition: .4s
  }
  .menu-info:hover .menu2-overlay {
      opacity: 1;
      visibility: visible;
      transition: .4s
  }
  .menu2-overlay h4 class="testh4" {
      font-size: 19px;
      color: #fff;
      margin: 46px 0 5px
  }
  .menu2-overlay p {
      color: #fff;
      font-size: 19px;
      line-height: 24px
  }
  .menu2-overlay .price {
      font-family: Josefin Sans;
      font-size: 36px;
      font-weight: 700;
      text-transform: uppercase;
      color: #fff;
      border-top: 1px solid #3e3d3b;
      width: 100%;
      display: table;
      margin: 17px 0 0;
      padding-top: 17px
  }
  .menu-item3 img {
      width: 83px;
      float: left
  }
  .menu-item3 .menu-wrapper {
      margin-left: 115px
  }
  .menu-items3 p {
      float: left
  }
  .menu-items4 {
      margin-bottom: 75px
  }
  .menu-item4 {
      margin-bottom: 30px
  }
  .menu-item4 a {
      text-decoration: none !important
  }
  .menu4-overlay {
      width: 100%;
      background-color: #fff;
      box-shadow: 0 5px 5px #e2e2e2;
      margin-bottom: 0;
      margin-top: -10px;
      text-align: center;
      text-decoration: none !important;
      display: table
  }
  .menu4-overlay h4 class="testh4" {
      font-size: 19px;
      color: #4b4741;
      margin: 46px 0 15px
  }
  .menu4-overlay p {
      color: #4b4741;
      font-size: 18px;
      line-height: 24px
  }
  .menu4-overlay .price {
      font-family: Josefin Sans;
      font-size: 36px;
      font-weight: 700;
      text-transform: uppercase;
      color: #e7ad48;
      width: 100%;
      display: table;
      margin: 0;
      padding: 13px 0 45px
  }
  
  @media(max-width:992px) {
      .list {
          padding-left: 0;
          margin-bottom: 0
      }
      .navbar .navbar-nav>li>a {
          padding: 9px 15px
      }
      .subscribe .btn-default {
          padding: 0 15px;
          height: 46px;
          border: 1px solid #f2ba57;
          margin-left: 0 px;
          margin-top: 0
      }
      .team-staff {
          border-bottom: 1px solid #dcdddd;
          padding-bottom: 30px;
          margin-bottom: 30px
      }
      .contact-social {
          margin-bottom: 60px
      }
      .menu-item2 {
          margin-bottom: 50px;
          width: 50%
      }
      .menu .food-menu .menu-item3 h4 class="testh4" {
          font-size: 14px
      }
      .menu-item4 {
          width: 50%
      }
      .rc-info {
          margin-left: 0
      }
      .recipie-content img {
          float: none;
          width: 100%;
          margin-bottom: 25px
      }
      .recipie-content hr {
          margin: 20px 0 50px
      }
      .rc-info {
          margin-bottom: 30px
      }
  }
  @media(max-width:767px) {
      .reservation .reservation-form .reservation-btn a {
          padding: 15px 14px;
          font-size: 15px
      }
      .table-responsive {
          padding: 10px 19px
      }
      .fp-content img {
          width: 100%
      }
      .recipie-content hr {
          margin: 20px 0 50px
      }
      .rc-info {
          margin-bottom: 30px
      }
      .menu-item2 {
          width: 100%
      }
      .menu-item2 img, .menu-item4 img {
          min-width: 100%
      }
      .post-date {
          margin: 0 0 30px;
          float: none
      }
      article h4 class="testh4" {
          margin: 40px 0 10px
      }
      h1, .h1 {
          font-size: 32px
      }
      .subscribe .btn-default {
          paddwing: 0 15px;
          height: 46px;
          border: 1px solid #f2ba57;
          margin-left: 0 px;
          margin-top: 0
      }
      .subscribe input {
          width: auto !important
      }
      .trusted-quote .quote-body {
          font-size: 19px
      }
      .trusted-quote .quote-author {
          font-size: 18px
      }
      .menu-tags span, .menu-tags2 span, .menu-tags3 span, .menu-tags4 span {
          font-size: 15px;
          margin: 0 8px 4px;
          display: inline-block
      }
      .page_header h2 {
          font-size: 61px
      }
      .menu .food-menu .menu-item3 h4 class="testh4" {
          font-size: 14px
      }
      .menu-item4 {
          width: 100%
      }
  }
  @media(max-width:480px) {
      .reservation .reservation-form {
          padding: 10px
      }
      .special-slider .slider-content .btn {
          margin: 17px 5px 20px 0;
          font-weight: 700;
          font-size: 10px;
          letter-spacing: normal;
          padding: 10px 20px;
          float: left
      }
      .element-tab .nav-tabs>li>a {
          color: #4b4741;
          font-size: 12px;
          font-family: Raleway;
          padding: 19px 14px
      }
      .cart-table tbody tr td a {
          font-size: 11px
      }
      .cart-table {
          font-size: 8px
      }
      .cart-table img {
          max-width: 50px;
          height: auto
      }
      .menu .food-menu .menu-item3 .price {
          
          float: none;
          padding-left: 0;
          margin: 6px 0 11px;
          font-size: 22px;
          display: table;
          padding-top: 10px
      }
      .contact-info p {
          font-size: 15px
      }
      .menu2-overlay h4 class="testh4" {
          font-size: 17px;
          margin: 22px 0 5px
      }
      .table>tbody>tr>td {
          padding: 8px 2px
      }
      .shop-grid select {
          float: none;
          display: block;
          margin-bottom: 15px
      }
      .sg-list {
          margin-left: -12px
      }
  }
  
  .menu-info {
      position: relative;
      overflow: hidden
  }
  .menu-item4 img {
      transform: scale(1);
      transition: .4s;
      opacity: 1
  }
  .menu-item4:hover img {
      transform: scale(1.1);
      transition: .4s;
      opacity: .9
  }
  .menu4-overlay {
      position: relative;
      z-index: 444
  }
  .menu-item2 img {
      transform: scale(1);
      transition: .4s;
      opacity: 1
  }
  .menu-item2:hover img {
      transform: scale(1.1);
      transition: .4s;
      opacity: .9
  }
  
  .menu-tags span {
  display: inline-block;
}
    
    </style>
