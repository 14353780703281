<template>
<Navigacija @reload-parent="methodThatForcesUpdate" yvisina=0 iocon="1">
  
  
  <template #atag><a class="btn-getstarted scrollto" style="border-radius: 20px;background-color: rgb(249, 243, 243);padding: 8px 15px;cursor: pointer;" id="podsjetnik" @click="this.$router.go(-1); this.$emit('reload-parent');"><i class="fa fa-arrow-left" style="color:rgb(14, 162, 189);font-size: 20px;"></i></a></template>
      <template #content>
        



<div class="content" style="color: gray;">
  <div class="container">
    <div class="row align-items-stretch no-gutters contact-wrap">
      <div class="col-md-12">
        
        <div class="form h-100">

          <a v-if="zadatak.telefon" :href="'tel:'+zadatak.telefon" style="padding-bottom: 50px;">Telefon gosta: {{ zadatak.telefon }}<br></a>


          <form v-on:submit.prevent="izmijeniZadatak"  class="mb-5" style="padding-top: 20px;">
           
            <div class="row">

         
    
              <div class="multivrsta col-md-6" id="vrsta">
                <label for="message" class="col-form-label">Vrsta zadatka *</label>
         
         
                <select id="choices-multiple-remove-button1" placeholder="Označi djelatnike" :disabled="isdisabledbutton">
                     
                </select>
              </div>
      
              <div class="col-md-6" id="lokacija">
                <label for="message" class="col-form-label">Lokacija *</label>
                <select id="choices-multiple-remove-button2" placeholder="Označi djelatnike" :disabled="isdisabledbutton2">
      
                  
                </select>
              </div>
            </div><br>
            <div class="form-group mb-5" v-if="zadatak.opis && zadatak.opis.trim().length" ><br>
              <i class="fa fa-language" @click="this.prijevod=!this.prijevod" style="font-size:20px;cursor: pointer;border-radius: 20px;box-shadow: 0 0 2px #888;background-color: white;padding: 0.4em 0.5em;color:var(--color-primary)"></i>&nbsp;
              <label for="message" class="col-form-label">Opis *</label>
               
      
              <p style="color:gray"><span v-if="!prijevod" >{{ zadatak.opis }}</span> <span v-else>{{ zadatak.opishr }}</span></p>
             
             
            </div>
            <div class="col-md-12" id="djelatnici">
              <label for="message" class="col-form-label">Označeni *</label>
              <select id="choices-multiple-remove-button3" placeholder="Označi djelatnike" multiple>
  
    
          
              </select>
            
            </div><br>
            <div class="row" style="padding-top: 20px;">
              <hr style="background-color:gray;">
              <div class="col-md-12 form-group">

                <button type="submit"  class="btn btn-success"><i class="fa-solid fa-check"></i></button>&nbsp;

             

                <button type="button" onclick="$('#deleteConfirmModal').modal('show')" class="btn btn-danger" style="background-color: #FF5349;border: none;" v-if="this.autor"><i class="fa-solid fa-trash-can"></i></button>
                <button type="button" @click="otvorichat(this.zadatak.autor)" class="btn btn-danger" v-if="zadatak.prijavio_gost" style="background-color: rgb(14, 162, 189);border-color: white;"><i class="fa-solid fa-message"></i></button>&nbsp;
                <a :href="this.zadatak.slika" v-if="this.zadatak.slika"><button type="button"  class="btn btn-danger" style="background-color: #ffc107;border: none;"><i class="fa fa-picture-o"></i></button></a>&nbsp;

              </div>
            </div>

          </form>

          <div style="margin-bottom: 20px;padding:20px;background-color: rgba(239, 239, 240, 0.8);border-radius: 20px;">
            <p>Aktivnosti:</p><br>
            <p style="color: gray; font-size: small; font-style: italic; line-height: 20px; white-space: pre-wrap;"
 v-html="formattedText"></p>
            <!--<p style="color:gray;font-size: small;font-style: italic;line-height: 20px;white-space: pre-wrap;"><span><b style="font-weight: bold;"></b></span>{{ zadatak.aktivnosti }}</p>-->

          </div>


          <form v-on:submit.prevent="izradiKomentar" :class="zadatak.id" name="kom-form">
            <div class="form-group mb-5">
              
              <div class="search">
                <input class="form-control" placeholder="Novi Komentar" v-model="this.formkom.opis">
                
            <i  style="float:right;padding-right: 20px;cursor: pointer;border-radius: 60px;box-shadow: 0 0 2px #888;background-color: white;padding: 0.5em 0.6em;" class="fa fa-send" @click="izradiKomentar()"></i><br>

      
</div>





            </div>
      </form>


      
        </div>
    
      </div>
    
    </div>

  </div>
  <div class="container">
    <div class="row" id="komentari_svi">
      <div class="col-md-12" v-for="kom in zadatak.komentari">
        <div class="media g-mb-30 media-comment">
          <div class="media-body u-shadow-v18 g-bg-secondary g-pa-30">
            <li class="list-inline-item g-mr-20 f-right" v-if="kom.autor==username">
                

               
              </li>
            <div class="g-mb-15">
              <h5 style="color:gray !important;" class="h5 g-color-gray-dark-v1 mb-0"><span>{{kom.opis}}</span></h5>


            </div>
            
            
            <ul class="list-inline d-sm-flex my-0">
              <li class="list-inline-item g-mr-20" style="color:gray;font-size: 15px;">
                

              <button type="button" style="border-radius: 20px; background-color: orange;border-color: white;font-size: 14px;color:white;border-style: solid;">&nbsp;&nbsp;{{kom.autor}}&nbsp;&nbsp;</button>
              
              {{kom.timestamp}}
          
              </li>
           
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


      </template>
</Navigacija>
<div id="preloader"></div>
<div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
    
        <h4 class="modal-title modelheading" id="deleteLabel">Izbriši Zadatak</h4>
        <i class="fa fa-times" style="color:gray;font-size: 20px;" onclick="$('#deleteConfirmModal').modal('hide')" ></i>
      </div>
      <div class="modal-body">
        <p>Ovaj zadatak više se neće prikazivati označenim djelatnicima</p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
 
        <button type="submit" id="submit" class="btn btn-danger f-left modalbutton" style="background-color: #dc3545 !important;" data-dismiss="modal" @click="izbrisiPost">Potvrdi</button>
            <div style="padding-top:10%;" class="prazanprostor"></div>

      </div>
    </div>
  </div>
</div>
</template>

<style>
.search {
  position: relative;
  color: #aaa;
  font-size: 16px;
}


.search input {
  background: rgba(239, 239, 240, 0.7) ;
  text-indent: 10px;
  border: 0;
  border-radius: 15px;
}

.search input:focus{
  background: rgba(239, 239, 240, 0.5) ;
}

.search .fa-send {
  position: absolute;
  top: 6px;
  right:7px;

  left: auto; right: 7px;
}</style>

<script>
import Navigacija from '../components/Navigacija.vue'
import axios from 'axios'
export default{
  components: {
    Navigacija
  },

    data() {
    return {
      aktivnosti:"",
  
      zadatak: [],
      vrste: [],
      jedinice:[],
      grupe:[],
      komentari:[],
      form:{
        id: this.$route.params.id,
        vrsta:"zadatak",
      },
      formkom:{
        opis:"",
        id: this.$route.params.id,
      },

      formpost:{
        vrsta:"",
        lokacija:"",
        opis:"",
        djl:[],
        id:"",
        staridjl:[],

        vrstapromjena:true,
        lokacijapromjena:true,
        djelatnikpromjena:false,
        opispromjena:false,
        isdisabledbutton:false,
        isdisabledbutton2:false,



      },
      obavijest:{
        opis:"",
        naslov:"",
        id:"",
      },
      username:"",
      lokacijechoice:null,
      vrstachoice:null,
      choices3:null,
      prijevod:false,
      autor:false,
   

     

    }
  },

  computed: {
    formattedText() {
      // Split the text into lines
      const lines = this.aktivnosti.split('\r\n');

      if(!this.aktivnosti){
        return "Nema aktivnosti"
      }

      // Create an array to hold the formatted lines
      const formattedLines = lines.map(line => {
        // Use regex to find the date and time parts and wrap them with <strong> tags
        const formattedLine = line.replace(/(\d{2}\.\d{2}\ \d{2}:\d{2})/g, '<b style="font-weight: bold;">$1</b>');
        return formattedLine;
      });

      // Join the lines back together with <br> tag s to preserve line breaks
      console.log(formattedLines.join('<br>'))

      
      return formattedLines.join('<br>');
    },
  },



   mounted(){
    this.username = localStorage.getItem('username')

    

  $('head').append('<style>#app{background:rgba(239, 239, 240, 0.8) !important;}</style>');
    this.getZadatak()

   },
methods:
{


  methodThatForcesUpdate(key){
              $("#preloader").show()
              this.vrstachoice.destroy()
              this.lokacijechoice.destroy()
              this.choices3.destroy()
              this.getZadatak()

        
          },


  otvorichat(autor){
    
    $('head').append('<style>#app{background: #fafbfc !important;}</style>');
    this.lokacijechoice.destroy();
    this.vrstachoice.destroy();
    this.choices3.destroy();

    this.$router.push({ name: 'Chat', params: { parameter: autor } });
  },

  obavijestsend(){
      axios.post('/api/obavijestgosta/', this.obavijest)
        .then((res) => {

          this.$root.toastnoti("Uspjeh", "Obavijest poslana.", "uspjeh")
          $('#myModal').modal('hide')

          this.obavijest.opis = ""
          this.obavijest.naslov = ""
    
                
          })
        .catch((error) => {
        
          this.$root.toastnoti("Greška", "Došlo je do greške", "greska")
          $('#myModal').modal('hide')
        })

    },

  promjenaDjelatnika(array) {
  if (this.formpost.djl.length !== array.length) {
    this.formpost.djl = array
    this.formpost.djelatnikpromjena = true
    return false
  }

  for (let item of this.formpost.djl) {
    if (!array.includes(item)) {
      this.formpost.djl = array
      this.formpost.djelatnikpromjena = true
      return false
    }
  }

  this.formpost.djelatnikpromjena = false
},

  zdstatus(status){
    if(status==0){return "Čeka Preuzimanje"}
    else if(status==1){return "Preuzet"}
    else if(status==2){return "Aktivan"}
    else if(status==3){return "Izvršen"}

  },
 izmijeniZadatak(){
              $("#preloader").show();
              const array1 = []
              $('#djelatnici #choices-multiple-remove-button3 option').each(function(index, element){
              array1.push($(element).attr('value'))
              }.bind(this));
         
              this.promjenaDjelatnika(array1)
              console.log($('#postOpis').val())
              if($('#postOpis').val() != this.formpost.opis){
                if($('#postOpis').val()){
                  this.formpost.opispromjena = true
              this.formpost.opis = $('#postOpis').val()
                }
              
            } 

       
            if($('#vrsta .choices__item ').attr('data-custom-properties')!= this.formpost.vrsta){
              this.formpost.vrstapromjena = true
              this.formpost.vrsta = $('#vrsta .choices__item ').attr('data-custom-properties')  
            } 
            else{this.formpost.vrstapromjena = false}

            if($('#lokacija .choices__item ').attr('data-custom-properties')!= this.formpost.lokacija){
              this.formpost.lokacijapromjena = true
              this.formpost.lokacija = $('#lokacija .choices__item ').attr('data-custom-properties')  
            } 
            else{this.formpost.lokacijapromjena = false}

            console.log(this.formpost)
            axios.post('/api/edit/', this.formpost)
                 .then((res) => {
                      $("#preloader").hide();
                      this.$root.toastnoti("Uspjeh!", "Zadatak je izmijenjen", "uspjeh")
                      console.log(res.data)
                      this.aktivnosti = res.data.aktivnosti
                      
                 })
                 .catch((error) => {
                  
                    if (error.response.status === 401) this.$router.push('/korisnik/prijava')
                    
                 }).finally(() => {
                     //Perform action in always
                 });
                
                 
      },


  izradiKomentar(){
  
            this.formkom.id=this.formpost.id

            axios.post('/api/komentar/', this.formkom)
                 .then((res) => {
                  this.zadatak.komentari.unshift({ opis: this.formkom.opis, autor: this.username, timestamp:"Sada" })

              
                      this.$root.toastnoti("Uspjeh!", "Komenar je Dodan", "uspjeh")
                      
                 })
                 .catch((error) => {
                  this.$root.toastnoti("Greška", "Komentar nije napravljen", "greska")
                 }).finally(() => {
                     //Perform action in always
                 });
                 
      },


  izbrisiPost(){
          console.log(this.form)
            axios.post('/api/izbrisi_zadatak/', this.form)
                 .then((res) => {
                      $("#deleteConfirmModal").modal('hide');  
                      window.location.href = "/";
                      this.$root.toastnoti("Uspjeh!", "Zadatak je Izbrisan", "uspjeh")
 
                      
                 })
                 .catch((error) => {
                    console.log(error)
                 })
                 
      },
      removeDuplicates(items) {
      const tempObject = {};
      items = items.filter((item) => {
        if (!tempObject[item.id]) {
          tempObject[item.id] = true;
          return true;
        }
        return false;
      });
    },


   async getZadatak() { 
    const id = this.$route.params.id
       await axios
        .get('/api/zadatak/'+id+'/')
        .then(response => {
          this.zadatak = response.data

          if(this.zadatak.autor == this.username){
          this.autor=true
          }

       
        


          this.aktivnosti = this.zadatak.aktivnosti
          this.formpost.djl = this.zadatak.selected_djelatnici.concat(this.zadatak.selected_grupe);
          this.formpost.staridjl = this.formpost.djl
          this.formpost.id = id
          this.formpost.opis = this.zadatak.opis
          this.formpost.vrsta = this.zadatak.vrsta_id
          this.formpost.lokacija = this.zadatak.lokacija_id

          this.obavijest.naslov = "Update for task: " + this.zadatak.vrsta_eng
          console.log(this.zadatak.vrste.map(function(i) {return { id: i[1], value: i[0], customProperties: i[1] };}))
          this.vrstachoice = new Choices('#choices-multiple-remove-button1', {
          choices: this.zadatak.vrste.map(function(i) {return { id: i[1], value: i[0], customProperties: i[1] };}),

      
        
          
 

            
        removeItemButton: true,
        maxItemCount:3,
        searchResultLimit:5,
        renderChoiceLimit:5
      }); 

      if(!(this.zadatak.prijavio_gost || this.autor)){
        this.vrstachoice.disable()
      }

      

      this.obavijest.id = this.zadatak.autor
   
      this.vrstachoice.setChoiceByValue(this.zadatak.vrsta_ime)

      this.lokacijechoice = new Choices('#choices-multiple-remove-button2', {

          choices: this.zadatak.lokacije.map(function(i) {return { id: i[1], value: i[0], customProperties: i[1] };}),
 

            
        removeItemButton: true,
        maxItemCount:3,
        searchResultLimit:5,
        renderChoiceLimit:5
      }); 

      this.lokacijechoice.setChoiceByValue(this.zadatak.lokacija)

      if(!this.autor){
     
     this.lokacijechoice.disable()
   }
  

      var selectdjl = this.zadatak.selected_djelatnici

      var selectgrupe = Object.values(this.zadatak.selected_grupe);
   
      var choices = [];
      var djelatnicioznaceni = []
    this.zadatak.grupe.forEach(function(grupa) {
      var groupChoices = [];

      if(selectgrupe.includes(grupa.name)){

      groupChoices.push({ label: grupa.name, value: grupa.name, selected:true});
      }
      else{
      groupChoices.push({ label: grupa.name, value: grupa.name });
      }


     
      grupa.djelatnici.forEach(function(djelatnik) {
       
        
        if(!djelatnicioznaceni.includes(djelatnik)){

          if(selectdjl.includes(djelatnik)){
            
       
          
              groupChoices.push({ id: djelatnik, value: djelatnik, selected:true });
         
          
          djelatnicioznaceni.push(djelatnik)
         
        }
        else{
          groupChoices.push({ id: djelatnik, value: djelatnik});
          djelatnicioznaceni.push(djelatnik)

        }

        }
  

        
        
      
        
      });

    

      choices.push({ label: grupa.name, choices: groupChoices });
      
      
    });
 


      this.choices3 = new Choices('#choices-multiple-remove-button3', {
        choices:choices,
        removeItemButton: true,
        maxItemCount:5,
        searchResultLimit:5,
        renderChoiceLimit:5
      }); 

     

        /*

        var element = $('div.choices__item.choices__item--selectable[data-value="Concierge"]');
        var buttonElement = element.find('button.choices__button');
        buttonElement.css('display', 'none');
        element.css('padding', "5px")

        */



      $("#preloader").hide()

        })
        .catch(error => {
        console.log(error)
        })

    },
  

  
 
},

beforeDestroy() {

this.lokacijechoice.destroy();


}

}
</script>


<style>

body {
  font-family: "Roboto", sans-serif;
  background-color: #fff;


  position: relative; }
  body:before {
    z-index: -1;
    position: absolute;
    height: 50vh;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    
 }

 @media (max-width: 991px) {
  .multivrsta{
    padding-bottom: 16px !important;
  }
}

#deleteConfirmModal{
  color:black;
}
.link-grey { color: #aaa; } .link-grey:hover { color: #00913b; }

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; }
  a, a:hover {
    text-decoration: none !important; }

.text-black {
  color: #000; }

.content {
  padding: 7rem 0; }

.heading {
  font-size: 2.5rem;
  font-weight: 900; }


.form-control {
  border: none;
  border-bottom: 1px solid #ccc;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  background: none; }

  .form-control:active, .form-control:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #000; }

.col-form-label {
  color: #000;
  font-size: 13px; }



.custom-select:active, .custom-select:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #000; }

  .btn, .form-control, .custom-select {
    height: 45px; }
    
    .btn.btn-primary {
      background: #35477d;
      color: #fff;
      }
    .btn:hover {
      color: #fff; }
    .btn:active, .btn:focus {
      outline: none;
      -webkit-box-shadow: none;
      box-shadow: none; }

    .contact-wrap {
      -webkit-box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.2); }
      .contact-wrap .col-form-label {
        font-size: 14px;
        color: #a3b1dbb9;
        margin: 0 0 10px 0;
        display: inline-block;
        padding: 0; }
      .contact-wrap .form, .contact-wrap .contact-info {
        padding: 40px; }
      
        .contact-wrap .contact-info ul li {
          margin-bottom: 15px;
          color: rgba(255, 255, 255, 0.5); }
          .contact-wrap .contact-info ul li .wrap-icon {
            font-size: 20px;
            color: #fff;
            margin-top: 5px; }
      .contact-wrap .form {
        background: #fff; }
        .contact-wrap .form h3 {

          color: #35477d;
          font-size: 20px;
          margin-bottom: 30px; }
      .contact-wrap .contact-info {
        background-color: white;}
        .contact-wrap .contact-info h3 {
          color: #fff;
          font-size: 20px;
          margin-bottom: 30px; }

label.error {
  font-size: 12px;
  color: red; }

#message {
  resize: vertical; }

#form-message-warning, #form-message-success {
  display: none; }

#form-message-warning {
  color: #B90B0B; }

#form-message-success {
  color: #55A44E;
  font-size: 18px;
  font-weight: bold; }

.submitting {
  float: left;
  width: 100%;
  padding: 10px 0;
  display: none;
  font-weight: bold;
  font-size: 12px;
  color: #000; }


body{
    margin-top:20px;

}
@media (min-width: 0) {
    .g-mr-15 {
        margin-right: 0.7rem !important;
    }
}
@media (min-width: 0){
    .g-mt-3 {
        margin-top: 0.7rem !important;
    }
}

.g-height-50 {
    height: 20px;
}

.g-width-50 {
    width: 20px !important;
}

@media (min-width: 0){
    .g-pa-30 {
        padding: 1rem !important;
    }
}

.g-bg-secondary {
    background-color: #fafafa !important;
}

.u-shadow-v18 {
    box-shadow: 0 5px 10px -6px rgba(0, 0, 0, 0.15);
}

.g-color-gray-dark-v4 {
    color: #777 !important;
}

.g-font-size-12 {
    font-size: 0.5rem !important;
}

.media-comment {
    margin-top:10px
}


</style>
