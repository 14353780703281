<template>

<Navigation @reload-parent="methodThatForcesUpdate" yvisina=0 iocon="1">
  <template #atag><a class="btn-getstarted scrollto" style="border-radius: 20px;background-color: rgb(249, 243, 243);padding: 8px 15px;cursor: pointer;" id="podsjetnik" @click="this.$router.go(-1); this.$emit('reload-parent');"><i class="fa fa-arrow-left" style="color:rgb(14, 162, 189);font-size: 20px;"></i></a></template>

<template #content>

  

	<div id="notfound">
		<div class="notfound">
			<img src="../assets/dino.png" style="width: 200px;" alt="">
            <h1 style="color:#0ea2bd;">Ulaz u Paleo Park</h1>
			<p style="font-size:20px; color:gray;">Barkodovi</p><br>
      <label>Količina:</label>
      <input class="form-control" type="number" v-model="qnt"><br>
       <button class="btn btn-info" style="border:none;color:white;" @click="ulaz">Preuzmi</button><br><br>
      

		</div>
	</div>

  
</template>
</Navigation>


</template>

<script>
import Kodovi from '../components/Kodovi.vue'
import Navigation from '../components/Navigacija.vue'

import axios from 'axios'
export default {
  name: 'Info',
  data(){
    return{

      qnt:1,
      barcodes:[]
    
    }
  },
  components: {
        Navigation
    },

  mounted(){

  

    $('#preloader').remove();
  
  

  },
  methods:{
    async ulaz() {

await axios
  .get('/placanje/paleoulaz/' + this.qnt)
  .then(response => {
    this.barcodes = response.data['uids']
    this.$router.push({
  name: 'Kodovi',
  query: {
    uids: this.barcodes
  }
})
    

  

  })
  .catch(error => {
    console.log(error)
  })

},
openPrintDialog(html) {
      const newWindow = window.open('', '_blank');
      newWindow.document.write(html);
      newWindow.document.close();
      newWindow.print();
      newWindow.close();
    },


  }

  
  
}
</script>
<style scoped>

#notfound {
  position: relative;
  height: 100vh;
  background: #f6f6f6;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  padding: 110px 40px;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
}

.notfound .notfound-404 {
  position: relative;
  height: 180px;
}

.notfound .notfound-404 h1 {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 165px;
  font-weight: 700;
  margin: 0px;
  color: #262626;
  text-transform: uppercase;
}

.notfound .notfound-404 h1>span {
  color: #00b7ff;
}

.notfound h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  color: #151515;
  margin-top: 0px;
  margin-bottom: 25px;
}

.notfound .notfound-search {
  position: relative;
  max-width: 320px;
  width: 100%;
  margin: auto;
}

.notfound .notfound-search>input {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  height: 50px;
  padding: 3px 65px 3px 30px;
  color: #151515;
  font-size: 16px;
  background: transparent;
  border: 2px solid #c5c5c5;
  border-radius: 40px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound .notfound-search>input:focus {
  border-color: #00b7ff;
}

.notfound .notfound-search>button {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 40px;
  height: 40px;
  text-align: center;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.notfound .notfound-search>button>span {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
      -ms-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
  margin-left: -3px;
}


@media only screen and (max-width: 767px) {
  .notfound h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 141px;
  }
}


</style>