import { createRouter, createWebHistory } from 'vue-router'
import Zadatak from '../views/Zadatak.vue'
import Novosti from '../views/Novosti.vue'
import Status from '../views/Status.vue'
import Predmeti from '../views/Predmeti.vue'
import Prijava from '../views/Prijava.vue'

import Kontrolna from '../views/Kontrolna.vue'
import Detalji from '../views/Detalji.vue'

import Dostave from '../views/Dostave.vue'
import DostaveKontrolna from '../views/DostaveKontrolna.vue'
import RestoranKontrolna from '../views/RestoranKontrolna.vue'
import DetaljiSuveniri from '../views/DetaljiSuveniri.vue'

import Tenis from '../views/Tenis.vue'
import Multi from '../views/Multi.vue'
import store from '../store'
import Masaza from '../views/services/Masaza.vue'
import MasazaSp from '../views/MasazaSp.vue'
import Ulaz from '../views/Ulaz.vue'
import Kodovi from '@/components/Kodovi.vue'
import Ankete from '../views/Ankete.vue'
import Anketa from '../views/AnketaPitanja.vue'
import Chat from '../views/Chat.vue'
import RacunHis from '../views/RacunHis.vue'
import SportKod from '@/components/SportKod.vue'
import Manikura from '../views/services/Manikura.vue'
import Fitness from '../views/services/Fitness.vue'
import Frizer from '../views/services/Frizer.vue'
import Depilation from '@/views/services/Depilation.vue'

const routes = [
  {
    path: '/sportkodovi',
    name: 'SportKodovi',
    component: SportKod,
    props: (route) => ({ uids: route.query.uids, vrsta:route.query.vrsta })
  },
  {
    path: '/kodovi',
    name: 'Kodovi',
    component: Kodovi,
    props: (route) => ({ uids: route.query.uids })
  },

  {
    path: '/chat/:parameter',
    name: 'Chat',
    component: Chat,
    meta: {
      requireLogin: true
    }
   
  },

  {
    path: '/',
    name: 'home',
    component: Zadatak,
    meta: {
      requireLogin: true
    }
   
  },
  {
    path: '/fitness/',
    name: 'fitness',
    component: Fitness,
    meta: {
      requireLogin: true
    }
   
  },
  {
    path: '/frizer/',
    name: 'frizer',
    component: Frizer,
    meta: {
      requireLogin: true
    }
   
  },
  {
    path: '/depilation/',
    name: 'depilation',
    component: Depilation,
    meta: {
      requireLogin: true
    }
   
  },
  {
    path: '/manikura/',
    name: 'manikura',
    component: Manikura,
    meta: {
      requireLogin: true
    }
   
  },
  {
    path: '/racunhis/',
    name: 'racunhis',
    component: RacunHis,
    meta: {
      requireLogin: true
    }
   
  },
  {
    path: '/ankete/',
    name: 'ankete',
    component: Ankete,
    meta: {
      requireLogin: true
    }
   
  },
  {
    path: '/anketa/:id',
    name: 'anketa',
    component: Anketa,
    meta: {
      requireLogin: true
    }
   
  },

  {
    path: '/paleoulaz/',
    name: 'paleoulaz',
    component: Ulaz,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/sport/tenis/',
    name: 'tenis',
    component: Tenis,
    meta: {
      requireLogin: true
    }
   
  },
  {
    path: '/sport/multi/',
    name: 'multi',
    component: Multi,
    meta: {
      requireLogin: true
    }
   
  },

  {
    path: '/novosti/',
    name: 'novosti',
    component: Novosti,
    meta: {
      requireLogin: true
    }
  },


  {
    path: '/status/',
    name: 'status',
    component: Status,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/predmeti/',
    name: 'predmeti',
    component: Predmeti,
    meta: {
      requireLogin: true
    }
  },

  {
    path: '/masaza/paleo',
    name: 'masaza',
    component: Masaza,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/masaza/sp',
    name: 'masazasp',
    component: MasazaSp,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/korisnik/prijava/',
    name: 'prijava',
    component: Prijava
  },

  {
    path: '/korisnik/kontrolna/',
    name: 'kontrolna',
    component: Kontrolna,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/suveniridetalji/:id/',
    name: 'suveniri',
    component: DetaljiSuveniri,
    meta: {
      requireLogin: true
    }
  },

  {
    path: '/detalji/:id/',
    name: 'detalji',
    component: Detalji,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/korisnik/dostave/:id/',
    name: 'dostave',
    component: Dostave,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/kontrolna/dostave/',
    name: 'kontrolnadostave',
    component: DostaveKontrolna,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/kontrolna/restoran/',
    name: 'kontrolnarestoran',
    component: RestoranKontrolna,
    meta: {
      requireLogin: true
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    return next('/korisnik/prijava')
  } else {
    next()
  }
})
export default router
