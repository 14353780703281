<template>


<Navigacija @reload-parent="methodThatForcesUpdate" yvisina=15 iocon="1">

  <template #atag><a></a></template>
      <template #content>

        
        <div id="baseApp">
<br><div style="padding-top:17px;"></div>

<main class="cd-main-content">
  <div class="cd-tab-filter-wrapper">
    <div class="cd-tab-filter">
      <ul class="cd-filters">
        <li class="placeholder"> 
          <a data-type="all" href="#0">Sve</a> <!-- selected option on mobile -->
        </li> 

      <li class="filter"><a class="selected" href="#0" data-type="all">Sve</a></li>
      <li class="filter" data-filter=".sanpolo"><a href="#0" data-type=".sp">San Polo</a></li>
      <li class="filter" data-filter=".colona"><a href="#0" data-type=".colona">Colona</a></li>

    
      </ul> <!-- cd-filters -->
    </div> <!-- cd-tab-filter -->
  </div> <!-- cd-tab-filter-wrapper -->

  <section class="cd-gallery">


    <ul>
    <div class="row">

   

<template v-for="mh in status.status">
<!-- Kartica -->
<li class="mix col-md-4 col-xl-3"  :class="{'imapodsjetnik':mh.email, 'nemapodsjetnik':!mh.email, 'krevetic':mh.krevetic, 'hranilica':mh.hranilica}, mh.zona +  ' ' +mh.status">
      <div  :id="'boja'+mh.lokacija" :class="changeColor(mh.status)">
    
          <div class="card-block">
              <h2 class="text-right">
              <i style="cursor:pointer;"   v-show="mh.status!=3 && mh.status!= 2" @click="promjena(mh.id)" :id="'ikonica'+mh.lokacija" class="fa fa-check f-left">
              </i><span class="promjena">{{mh.lokacija}}</span>
         
              <i class="fa fa-exclamation-circle f-right" style="padding-right:8px; font-size:30px; cursor:pointer;" aria-hidden="true" @click="otvoriform(mh.id, mh.lokacija)"></i>


              <i class="fa f-right" :class="'kreveticikona'+mh.lokacija" v-show="mh.krevetic" @click="dodaj('krevetic', mh.lokacija, 'makni')"><img src="../assets/img/cot.png" style="width=30px; height:30px; padding-right:10px; cursor:pointer;"></i>
              <i class="fa f-right" v-show="!mh.krevetic" :class="'kdodaj'+mh.lokacija" @click="dodaj('krevetic', mh.lokacija, 'dodaj')"><img src="../assets/img/plus.png" style="width=30px; height:30px; padding-right:10px; cursor:pointer; color:white;"></i>
              <i class="fa f-right" :class="'hranilicaikona'+mh.lokacija" v-show="mh.hranilica"  @click="dodaj('hranilica', mh.lokacija, 'makni')"><img src="../assets/img/chair.png" style="width=30px; height:30px; padding-right:10px; cursor:pointer;" ></i>
              <i class="fa f-right" v-show="!mh.hranilica" :class="'hdodaj'+mh.lokacija" @click="dodaj('hranilica', mh.lokacija, 'dodaj')"><img src="../assets/img/plus.png" style="width=30px; height:30px; padding-right:10px; cursor:pointer;"></i>

              </h2>

          
          </div>
      </div></li>
<!-- ZELENO -->

</template>



</div>

</ul>
  <div style="color:gray" class="cd-fail-message">Nema novosti sa tim filterom</div>
</section> <!-- cd-gallery -->

<div class="cd-filter">
<form>
  <div class="cd-filter-block">
    <h4>Pretraži</h4>
    
    <div class="cd-filter-content">
      <input type="search" placeholder="Broj Mh">
    </div> <!-- cd-filter-content -->
  </div> <!-- cd-filter-block -->

  <div class="cd-filter-block">
    <h4>Status</h4>

    <ul class="cd-filter-content cd-filters list">
      <li>
        <input class="filter" data-filter=".3" type="checkbox" id="checkbox1">
          <label class="checkbox-label" for="checkbox1">Boravak</label>
      </li>

      <li>
        <input class="filter" data-filter=".0" type="checkbox" id="checkbox2">
        <label class="checkbox-label" for="checkbox2">Odlazak</label>
      </li>

      <li>
        <input class="filter" data-filter=".2" type="checkbox" id="checkbox2">
        <label class="checkbox-label" for="checkbox2">Spremna</label>
      </li>

      <li>
        <input class="filter" data-filter=".1" type="checkbox" id="checkbox2">
        <label class="checkbox-label" for="checkbox2">Pretraga</label>
      </li>


    </ul> <!-- cd-filter-content -->
  </div> <!-- cd-filter-block -->



  <div class="cd-filter-block">
    <h4>Podsjetnik</h4>

    <ul class="cd-filter-content cd-filters list">
      <li>
        <input class="filter" data-filter="" type="radio" name="radioButton" id="radio1" checked>
        <label class="radio-label" for="radio1">Svi</label>
      </li>

      <li>
        <input class="filter" data-filter=".imapodsjetnik" type="radio" name="radioButton" id="radio2">
        <label class="radio-label" for="danas">Da</label>
      </li>

      <li>
        <input class="filter" data-filter=".nemapodsjetnik" type="radio" name="radioButton" id="radio3">
        <label class="radio-label" for="sutra">Ne</label>
      </li>
    </ul> <!-- cd-filter-content -->
  </div> <!-- cd-filter-block -->

 <div class="cd-filter-block">
    <h4>Dodaci</h4>

    <ul class="cd-filter-content cd-filters list">
      <li>
        <input class="filter" data-filter=".krevetic" type="checkbox" id="checkbox1">
          <label class="checkbox-label" for="checkbox1">Krevetić</label>
      </li>

      <li>
        <input class="filter" data-filter=".hranilica" type="checkbox" id="checkbox2">
        <label class="checkbox-label" for="checkbox2">Hranilica</label>
      </li>



    </ul> <!-- cd-filter-content -->
  </div> <!-- cd-filter-block -->


</form>

<a href="#0" class="cd-close">Close</a>
</div> <!-- cd-filter -->

<a href="#0" class="cd-filter-trigger">Filteri</a>
</main> <!-- cd-main-content -->






</div>

      </template>

</Navigacija>

<div id="problemModal" style="padding-top:90px; z-index:10001;" class="modal fade" tabindex="-1">
<div class="modal-dialog inputmodal-dialog">
    <div class="modal-content inputmodal-content">
        <div class="modal-header" >
            <h5  class="modal-title modelheading">Prijava problema</h5><br>
            <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('problemModal')"  ></i>
        </div>
      <form v-on:submit.prevent="submitPost" class="reportforma" id="id">
        <div class="modal-body">
 
          <div class="multivrsta col-md-12 col-sm-12" id="vrsta"> <select id="choices-multiple-remove-button" class="inputVrsta" placeholder="Označi djelatnike">
          
          </select> </div>
          <input class="form-control" placeholder="Opiši problem" v-model="formproblem.opis"><br>
          <div class="col-md-12" id="djelatnici"> <select id="choices-multiple-remove-button2" placeholder="Označi djelatnike" multiple>
    
      
              


  </select> </div>

          
          
        
        </div>
        <div class="modal-footer d-flex justify-content-center">
          
           
 
            <button type="submit" id="submit" class="btn btn-info f-left modalbutton" data-dismiss="modal">Prijavi</button>
            <div style="padding-top:10%;" class="prazanprostor"></div>

            
        </div>
      </form>
    </div>
</div>
</div>

<div id="preloader"></div>

</template>

<style src="../assets/css/status/style.css" scoped></style>


<style>
.kreveticikona:hover{
  opacity:0.5;
}
.hranilicaikona:hover{
  opacity:0.5;
}
</style>

<script>
import axios from 'axios'
import Navigacija from '../components/Navigacija.vue'


export default{
  components: {
    Navigacija
},


   data() {
    return {
      choice1:null,
      choice2:null,
      status: [],
      form:{
        id:"",
      },
      formproblem:{
        opis:"",
        djelatnici:[],
        datum:"",
        vrsta:"",
        hitno:false,
        lokacija:"",
      },

      formpos:{
        id:"",
        email:"",
        vrsta:"mh",
      },
      grupe:[],
      pokazatelj:false,
    }
  },

     mounted(){


      $("#closeModal").click(function(){
            var modal = $("#myModal")
            $("#myModal").modal('hide');     

        });


        this.getNovosti();
        

       
        
    },
    methods:
  

{

  methodThatForcesUpdate(key){
      
          
              this.getNovosti()

        
          },


  submitPost(lokacija){
         

              $('#djelatnici #choices-multiple-remove-button2 option').each(function(index, element){
                
                console.log($(element))
                this.formproblem.djelatnici.push($(element).attr('value'))

              }.bind(this));
              console.log(this.formproblem.djelatnici)


            this.formproblem.datum="Sada"
            this.formproblem.hitno = true;
            this.formproblem.vrsta = $('#vrsta .choices__item ').attr('data-custom-properties')
            this.formproblem.lokacija = $(".reportforma").attr('id')

            console.log(this.formproblem.lokacija)
            
            axios.post('/api/create_post/', this.formproblem)
                 .then((res) => {

                      $("#problemModal").modal('hide');  
                      this.$root.toastnoti("Uspjeh!", "Zadatak je napravljen", "uspjeh")
                      this.formproblem = {}
                      this.formproblem.datum = new Date().toISOString().substr(0, 10)
                      this.formproblem.djelatnici = []
                 })
                 .catch((error) => {
                    this.$root.toastnoti("Greška", "Zadatak nije napravljen", "greska")
                 }).finally(() => {
                     //Perform action in always
                 });
                 
      },

      ikona(status) {
        if(status == 3){
          return 'fa fa-home f-right';
        }
        else{
          return 'fa fa-envelope f-right';
        }
},

  changeColor(status) {
    console.log(status)
  return {
    'card bg-c-purple order-card': status == 3,
    'card bg-c-pink order-card': status == 0,
    'card bg-c-green order-card': status == 2,
    'card bg-c-yellow order-card': status == 1,

  }
},

zatvori(vrsta){
       $(vrsta).modal('hide');  
    },
  podsjetnik(){
    axios.post('/api/podsjetnik/', this.formpos)
                 .then((res) => {

                      $("#myModal").modal('hide');
                      this.$root.toastnoti("Uspjeh!", "Podsejtnik je dodan", "uspjeh")
                 })
                 .catch((error) => {
                    console.log("bb")
                 }).finally(() => {
                     //Perform action in always
                 });
  },

   otvoriPodsjetnik(id, email, broj){
      var modal = $("#myModal")
      $("#myModal").modal('show');
      modal.find('.modal-title').text('Podsjetnik za MH ' + broj)
      $('.modal-title').attr("id", id); 
      this.formpos.id=id
      if(email != ""){
        $('.modal-body #email').attr("placeholder", email); 
      }
      
    },
    otvoriform(id, lokacija){
      
        

      var modal = $("#problemModal")
      modal.find('.modal-title').text('Zadatak u ' + lokacija)

      this.$root.otvoriform('problemModal')
      $(".reportforma").attr('id', id);
  
      
    },


  promjena(id){
       
            this.form.id = id
            axios.post('/api/promjena/', this.form)
                 .then((res) => {
                  console.log(res)
                      if(res.data.status==3){
                $("#"+res.data.broj).attr('class', 'fa fa-home f-right');
                $("#ikonica"+res.data.broj).css('visibility', 'hidden');
                $("#boja"+res.data.broj).attr('class', 'card bg-c-purple order-card');
                $("#ikonica"+res.data.broj).attr('class', 'fa fa-times f-left');
                this.$root.toastnoti("Uspjeh!", "Status MH promijenjen je u boravak", "uspjeh");

            }
            else{
            if(res.data.status == 0){
                $("#"+res.data.broj).css('visibility', 'visible');
                $("#boja"+res.data.broj).attr('class', 'card bg-c-pink order-card');
                $("#ikonica"+res.data.broj).attr('class', 'fa fa-times f-left');
                this.$root.toastnoti("Uspjeh!", "Status MH promijenjen je u ciscenje", "uspjeh");
          
         
                
                
            }
            else{
                if(res.data.status==2){
                    $("#boja"+res.data.broj).attr('class', 'card bg-c-green order-card');

                    $("#ikonica"+res.data.broj).css('visibility', 'hidden');

                    
                    $("#ikonica"+res.data.broj).attr('class', 'fa fa-check f-left');
                    this.$root.toastnoti("Uspjeh!", "Status MH promijenjen je u spremna", "uspjeh");

                    if(res.data.mail){
                        $( "body" ).prepend( 
                        ` 
                        <div id="modal" class="modal fade" tabindex="-1">
                          <div class="modal-dialog" style="color:gray;">
                              <div class="modal-content">
                                  <div class="modal-header" >
                                      <h5  class="modal-title">Podsjentik</h5><br>
                                      <button type="button" onclick="$('#modal').modal('hide')" class="btn-close" aria-label="Close"></button>
                                  </div>
                                  <div class="modal-body">
                                  <p>Podsjetnik je poslan na ${res.data.email}</p>  
                                  
                                  </div>
                                  <div class="modal-footer">
                                    
                                      <button type="button" id="close" class="btn btn-success f-left" onclick="$('#modal').modal('hide')">Zatvori</button>
                            
                                  </div>
                         
                              </div>
                          </div>
                        </div>
                        </div>
                        `);
                    }
                    $("#modal").modal('show');
                

                }
                else{
                    $("#boja"+res.data.broj).attr('class', 'card bg-c-yellow order-card');
                    $("#ikonica"+res.data.broj).attr('class', 'fa fa-check f-left');
                    $("#"+res.data.broj).attr('class', 'podsjetnik fa fa-envelope f-right');
                    this.$root.toastnoti("Uspjeh!", "Status MH promijenjen je u pregled", "uspjeh");
            
                }
            }}
                      this.$root.toastnoti("Uspjeh!", "Status je promijenjen", "uspjeh")
                 })
                 .catch((error) => {
                     this.$root.toastnoti("Greška", error, "greska")
                 }).finally(() => {
                     //Perform action in always
                 });
                 
      },



  async getNovosti() {

      await axios
        .get('/api/status/')
        .then(response => {
          this.status = response.data
          const recaptchaScript = document.createElement("script");
          recaptchaScript.setAttribute(
          "src",
          "/js/filterostalo.js"
        );
        document.head.appendChild(recaptchaScript);
        $("#preloader").hide();
        console.log(this.status)

        this.choice1 = new Choices('#choices-multiple-remove-button', {
          choices: this.status.vrste.map(function(i) {return { id: i[1], value: i[0], customProperties: i[1]  };}),
        removeItemButton: true,
        maxItemCount:3,
        searchResultLimit:5,
        renderChoiceLimit:5
      }); 

      var choices = [];
    this.status.grupe.forEach(function(grupa) {
      var groupChoices = [];
      grupa.djelatnici.forEach(function(djelatnik) {
        groupChoices.push({ id: djelatnik, value: djelatnik });
      });
      choices.push({ label: grupa.name, choices: groupChoices });
    });


      this.choice2 = new Choices('#choices-multiple-remove-button2', {
          choices: choices,
        removeItemButton: true,
        maxItemCount:3,
        searchResultLimit:5,
        renderChoiceLimit:5
      }); 


        })
        .catch(error => {
          console.log(error)
        })

    },

      async dodaj(pojam, broj, vrsta) {
       
      await axios
        .get('/api/dodatak/status/'+pojam+"/"+broj)
        .then(response => {
          this.$root.toastnoti("Uspjeh!", " ", "uspjeh")
          if(pojam=="hranilica"){
            if(vrsta=="makni"){
              $(".hranilicaikona"+broj).hide();
              $(".hdodaj"+broj).show();
              this.$root.toastnoti("Uspjeh!", "Hranilica je maknuta", "uspjeh")
            }
            else{
              $(".hdodaj"+broj).hide();
              $(".hranilicaikona"+broj).show();
              this.$root.toastnoti("Uspjeh!", "Hranilica je dodana", "uspjeh")
            }
            
          }
          else if(pojam=="krevetic"){
            if(vrsta=="makni"){
              $(".kreveticikona"+broj).hide();
              $(".kdodaj"+broj).show();
              this.$root.toastnoti("Uspjeh!", "Krevetić je maknut", "uspjeh")
            }
            else{
              $(".kreveticikona"+broj).show();
              $(".kdodaj"+broj).hide();
              this.$root.toastnoti("Uspjeh!", "Krevetić je dodan", "uspjeh")
              
            }
          }

          
            
 

        })
        .catch(error => {
            if (error.response.status === 401) this.$router.push('/korisnik/prijava')
         
          this.$root.toastnoti("Greška", error , "greska")
        })

    } 
 
},
beforeDestroy() {

this.choice1.destroy();
this.choice2.destroy();

}
}
</script>