<template>
<header id="header" class="header fixed-top" style="padding-right:0px !important;" data-scrollto-offset="0">
    <div class="container-fluid d-flex align-items-center justify-content-between">
     
      <a @click="$router.push('/')"  class="logo d-flex align-items-center scrollto me-auto me-lg-0">
        <!-- Uncomment the line below if you also wish to use an image logo -->
        <img style="padding-left: 10px;" src="../assets/logo.png" alt="">
     
        <h1>Staff<span>.</span></h1>
      </a>

      <div id="navigacija" class="navigacija" style="font-family: var(--bs-font-sans-serif);">
        <ul id="navigacijaul">

          <li @click="promijeniprozor('/korisnik/prijava/')"><a class="nav-link scrollto">User {{ username }} <i class="fa fa-user navikona"></i></a></li>
          <li v-if="!this.zadaci.includes(username)" @click="promijeniprozor('/')"><a class="nav-link scrollto" >Tasks <i class="fa-solid fa-house-circle-exclamation navikona"></i></a></li>
          
          
         
          <li class="dropdown" ><a href="/korisnik/kontrolna/"><span>Control pannel</span> <i class="bi bi-chevron-down dropdown-indicator"></i></a>
            <ul>
             
              <li v-if="!this.restorani.includes(username)" @click="promijeniprozor('/korisnik/kontrolna/')"><a class="nav-link scrollto">Tasks <i class="fa fa-users navikona"></i></a></li>
              <li @click="promijeniprozor('/kontrolna/dostave/')"><a class="nav-link scrollto">Food delivery <i class="fa fa-truck navikona"></i></a></li>
              <li @click="promijeniprozor('/kontrolna/restoran/')"><a v-if="this.grupe.includes('Restoran')"  class="nav-link scrollto">Restaurant <i class="fa fa-utensils navikona"></i></a></li>
              
              
      
            </ul>
          </li>
          <li class="dropdown" v-show="!this.sport.includes(username)"><a href="/sport/tenis/"><span>Sport</span> <i class="bi bi-chevron-down dropdown-indicator"></i></a>
            <ul>
             
            <li @click="promijeniprozor('/sport/tenis/')"><a class="nav-link scrollto" >Tenis field<i class="fa fa-futbol navikona"></i></a></li>
            <li @click="promijeniprozor('/sport/multi/')"><a class="nav-link scrollto" >Multi functional field <i class="fa fa-futbol navikona"></i></a></li>          
      
            </ul>
          </li>
      
  
         

          <li class="dropdown" v-show="!this.masaza.includes(username)"><a href="/masaza/paleo"><span>Services</span> <i class="bi bi-chevron-down dropdown-indicator"></i></a>
            <ul>

              <li @click="promijeniprozor('/masaza/paleo')"><a class="nav-link scrollto"  >Oasis Massage & Face <i class="fa fa-spa navikona"></i></a></li>
              <li @click="promijeniprozor('/manikura/')"><a class="nav-link scrollto"  >Oasis Pedicure & Manicure <i class="fa fa-spa navikona"></i></a></li>
              <li @click="promijeniprozor('/fitness/')"><a class="nav-link scrollto">Fitness <i class="fa fa-dumbbell navikona"></i></a></li>
              <li><a class="nav-link scrollto" @click="promijeniprozor('/masaza/sp')" >Massage San Polo <i class="fa-solid fa-tent navikona"></i></a></li>
             
             
            </ul>
          </li>
      
    

          <li class="dropdown" v-show="!this.masaza.includes(username)"><a href="/masaza/paleo"><span>Others</span> <i class="bi bi-chevron-down dropdown-indicator"></i></a>
            <ul>
              <li @click="promijeniprozor('/status/')" v-if="!this.status.includes(username)"><a class="nav-link scrollto" >Accommodation Status<i class="fa fa-home navikona"></i></a></li>
              <li @click="promijeniprozor('/novosti/')"><a class="nav-link scrollto" >Staff Messages <i class="fa fa-circle-info navikona"></i></a></li>
          <li v-if="!this.restorani.includes(username)" @click="promijeniprozor('/predmeti/')"> <a class="nav-link scrollto" >Lost & Found <i class="fa fa-circle-exclamation navikona"></i></a></li>

              <li v-if="!this.ulaz.includes(username)" @click="promijeniprozor('/paleoulaz/')"><a class="nav-link scrollto" >Pool entrance <i class="fa fa-water-ladder navikona"></i></a></li>
              
      
            </ul>
          </li>
      
         
        
        </ul>
        <i class="bi bi-list mobile-nav-toggle dplay"></i>
      </div><!-- .navigacija -->



      
      <slot name="atag"></slot>

    </div>
  </header>

  <ion-page v-if="this.iocon==1">
      

      <ion-content ref="myContent" class="ion-padding" @ionScroll="handleScroll" :scrollEvents="true" style="height: 100vh;">
  
         
        <ion-refresher slot="fixed" :pull-factor="0.5" :pull-min="50" :pull-max="50" @ionRefresh="handleRefresh($event)">
          <ion-refresher-content></ion-refresher-content>
        </ion-refresher>

        <slot name="content">

        </slot>

   
        
      
     
      </ion-content>
      </ion-page>



  
  </template>

<style>

.navikona{
  font-size: 15px !important;
  padding-left: 5px;
}

ion-content {
  --keyboard-offset: 0px;
}

@media print
{    
    #header
    {
        display: none !important;
    }
}

@media (min-width: 1280px) {
  .dplay{
    display:none;
  }
}

</style>




<script>
import router from '@/router'
import { Capacitor } from '@capacitor/core';
import { IonContent, IonHeader, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, IonPage } from '@ionic/vue';


export default{
  props: ['yvisina', 'iocon'],
   data(){
    return {
      username:"",
      restorani:['Restoran', 'molpicio', 'paleopark'],
      grupe:[],
      kontrolna:['Prodaja', 'molpicio', 'paleopark'],
      zadaci:['Prodaja', 'Restoran', 'molpicio', 'paleopark'],
      ulaz:['Prodaja', 'paleopark', 'molpicio'],
      status:['Prodaja', 'Restoran', 'molpicio', 'paleopark'],
      sport:['molpicio', 'paleopark'],
      masaza:['molpicio', 'paleopark'],
      racun:["Samanta, Arijana"],
      native:false,

    }
  },

  components: { IonContent, IonHeader, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, IonPage },
  
methods:{
  promijeniprozor(url){

document.querySelector('body').classList.toggle('mobile-nav-active');
let navbarToggle = document.querySelector('.mobile-nav-toggle');
navbarToggle.classList.toggle('bi-list');
navbarToggle.classList.toggle('bi-x');
this.$router.push(url)

},


  handleScroll() {

const selectHeader = document.querySelector('#header');
const contentEl = document.querySelector('ion-content');
const scrollY = contentEl.shadowRoot.querySelector('.inner-scroll').scrollTop;    


scrollY >= this.yvisina ? selectHeader.classList.add('sticked') : selectHeader.classList.remove('sticked');
if (selectHeader) {
  document.addEventListener('scroll', () => {
    scrollY > this.yvisina ? selectHeader.classList.add('sticked') : selectHeader.classList.remove('sticked');
  });
}



},

handleRefresh(event) {
  this.$emit('reload-parent');
setTimeout(() => {
  // When the data is done refreshing, complete the refresher
  event.target.complete();
}, 1000);
},


  goback(){
    window.history.back();

    
   
  },


  methodThatForcesUpdate(){
    window.location.reload()
  }
},
 mounted() {

  console.log(this.iocon)
  this.native = Capacitor.isNative

  this.username = localStorage.getItem('username')
  this.grupe = localStorage.getItem('grupe')
  if (this.grupe == null){
    this.grupe = []
  }


  const selectHeader = document.querySelector('#header');
     window.scrollY >= this.yvisina ? selectHeader.classList.add('sticked') : selectHeader.classList.remove('sticked');
  if (selectHeader) {
    document.addEventListener('scroll', () => {
      window.scrollY > this.yvisina ? selectHeader.classList.add('sticked') : selectHeader.classList.remove('sticked');
    });
  }
  let navbarlinks = document.querySelectorAll('#navigacija .scrollto');
  function navbarlinksActive() {
    navbarlinks.forEach(navbarlink => {
      if (!navbarlink.hash) return;
      let section = document.querySelector(navbarlink.hash);
      if (!section) return;
      let position = window.scrollY;
      if (navbarlink.hash != '#header') position += 200;
      if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
        navbarlink.classList.add('active');
      } else {
        navbarlink.classList.remove('active');
      }
    })
  }
  window.addEventListener('load', navbarlinksActive);
  document.addEventListener('scroll', navbarlinksActive);
  /**
   * Function to scroll to an element with top ofset
   */
  function scrollto(el) {
    const selectHeader = document.querySelector('#header');
    let offset = 0;
    if (selectHeader.classList.contains('sticked')) {
      offset = document.querySelector('#header.sticked').offsetHeight;
    } else if (selectHeader.hasAttribute('data-scrollto-offset')) {
      offset = selectHeader.offsetHeight - parseInt(selectHeader.getAttribute('data-scrollto-offset'));
    }
    window.scrollTo({
      top: document.querySelector(el).offsetTop - offset,
      behavior: 'smooth'
    });
  }
  /**
   * Fires the scrollto function on click to links .scrollto
   */
  let selectScrollto = document.querySelectorAll('.scrollto');
  selectScrollto.forEach(el => el.addEventListener('click', function(event) {
   
  }));
  /**
   * Scroll with ofset on page load with hash links in the url
   */
  window.addEventListener('load', () => {
    if (window.location.hash) {
      if (document.querySelector(window.location.hash)) {
        scrollto(window.location.hash);
      }
    }
  });
  /**
   * Mobile nav toggle
   */
  const mobileNavToogle = document.querySelector('.mobile-nav-toggle');
  if (mobileNavToogle) {
    mobileNavToogle.addEventListener('click', function(event) {
      event.preventDefault();
      document.querySelector('body').classList.toggle('mobile-nav-active');
      this.classList.toggle('bi-list');
      this.classList.toggle('bi-x');
    });
  }
  /**
   * Toggle mobile nav dropdowns
   */
  const navDropdowns = document.querySelectorAll('.navigacija .dropdown > a');
  navDropdowns.forEach(el => {
    el.addEventListener('click', function(event) {
      if (document.querySelector('.mobile-nav-active')) {
        event.preventDefault();
        this.classList.toggle('active');
        this.nextElementSibling.classList.toggle('dropdown-active');
        let dropDownIndicator = this.querySelector('.dropdown-indicator');
        dropDownIndicator.classList.toggle('bi-chevron-up');
        dropDownIndicator.classList.toggle('bi-chevron-down');
      }
    })
  });



  
 },
 
 
 }

</script>