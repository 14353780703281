<template>

    <Navigation @reload-parent="methodThatForcesUpdate" yvisina=0 iocon="0">
    
    <template #atag><a></a></template>
    <template #content>
   </template>
    </Navigation>

    <div class="container">
    <div class="row" style="padding-top: 50px;">
    
    
            <div class="col col-md-4" style="page-break-inside: avoid;padding-top: 50px;" v-for="i in uids">
    
        
    
    <div class="ticket airline">
        <div class="top">
            <h1 style="color:white;">Entrance ticket</h1>
            <div class="big">
                <p class="from" v-if="vrsta=='tenis'">Tenis</p>
                <p class="from" v-else>Multi</p>
                <p class="to" style="color:white;">Sport</p>
            </div>
            <div class="top--side">
                <img src="../assets/obojan.png" style="width: 50px;">
                <p style="color:#13547a;">Mon Perin</p>
                <p style="color:#13547a;">Bale/Valle</p>
            </div>
          
        </div>
        <div class="bottom">
            <div class="column" style="print-color-adjust: exact;">
               
                <div class="row row-2">
     
                    <p class="row--center" style="color:#13547a;"><span>Barcode for entrance to the complex and field</span>Only opens once</p>
              
                </div>
            
            </div>
            <div class="bar--code"  :key="i">
                <svg :id="i"></svg>
            </div>
        </div>
    </div>
    </div>
    </div>
    </div>
    
    
    </template>
    
    <script>
    import JsBarcode from 'jsbarcode';
    import Navigation from '../components/Navigacija.vue'
    export default {
      name: 'Kodovi',
      props: {
        vrsta:"",
        uids: {
          type: Array,
          required: true
        }
      },
      data(){
        return{
    
        formattedDate:"",
        }
      },
      components: {
            Navigation
        },
    
    
      mounted(){

        console.log(this.uids)
        this.generateBarcodes();
        var today = new Date();
    
    // Get the day and month
    var day = String(today.getDate()).padStart(2, '0');
    var month = String(today.getMonth() + 1).padStart(2, '0');
    
    // Combine day and month with a dot separator
     this.formattedDate = day + '.' + month;
    
    
      },
    
      methods: {
        generateBarcodes() {
          this.uids.forEach(uid => {
            const barcodeOptions = {
              format: 'EAN8',
              displayValue: false,
              width: 3,
              height: 70,
    
            };
            var uidElement = document.getElementById(uid);
            JsBarcode(uidElement, uid, barcodeOptions);
          });
        },
      },
    
      // ...
    }
    </script>
    
    <style scoped>
   
    body{
        overflow: visible !important;
        background-color: white !important;
    }
     body, p, h1 {
         margin: 0;
         padding: 0;
    
         font-family: "Open Sans", sans-serif;
    }
    .container {   
        position: relative;
    
         height: 50vh;
    }
     .container .ticket {
        position: relative;
        top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
    }
     .container .basic {
         display: none;
    }
     .airline {
         display: block;
         height: 475px;
         width: 270px;
         box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
         border-radius: 25px;
         z-index: 3;
    }
     .airline .top {
         height: 220px;
         background: #3FA7CB !important;
         border-top-right-radius: 25px;
         border-top-left-radius: 25px;
         print-color-adjust: exact;
    }
     .airline .top h1 {
         text-transform: uppercase;
         font-size: 12px;
         letter-spacing: 2;
         text-align: center;
         position: absolute;
         top: 30px;
         left: 50%;
         transform: translateX(-50%);
    }
     .airline .bottom {
         height: 155px;
         background: #fff;
         border-bottom-right-radius: 25px;
         border-bottom-left-radius: 25px;
    }
     .top .big {
         position: absolute;
         top: 100px;
         font-size: 65px;
         font-weight: 700;
         line-height: 0.8;
    }
     .top .big .from {
         color: white;
    
    }
     .top .big .to {
         position: absolute;
         left: 32px;
         font-size: 35px;
         display: flex;
         flex-direction: row;
         align-items: center;
    }
     .top .big .to i {
         margin-top: 5px;
         margin-right: 10px;
         font-size: 15px;
    }
     .top--side {
         position: absolute;
         right: 35px;
         top: 110px;
         text-align: right;
    }
     .top--side i {
         font-size: 25px;
         margin-bottom: 18px;
    }
     .top--side p {
         font-size: 10px;
         font-weight: 700;
    }
     .top--side p + p {
         margin-bottom: 8px;
    }
     .bottom p {
         display: flex;
         flex-direction: column;
         font-size: 13px;
         font-weight: 700;
    }
     .bottom p span {
         font-weight: 400;
         font-size: 11px;
         color: #6c6c6c;
    }
     .bottom .column {
         margin: 0 auto;
         width: 80%;
         padding: 2rem 0;
    }
     .bottom .row {
         display: flex;
         justify-content: space-between;
    }
     .bottom .row--right {
         text-align: right;
    }
     .bottom .row--center {
         text-align: center;
    }
     .bottom .row-2 {
         margin: 30px 0 0px 0;
         position: relative;
    }
     .bottom .row-2::after {
         content: "";
         position: absolute;
         width: 100%;
         bottom: -30px;
         left: 0;
         background: #000;
         height: 1px;
    }
     .bottom .bar--code {
         height: 50px;
         width: 80%;
         margin: 0 auto;
         position: relative;
    }
    
    
     .info {
         position: absolute;
         left: 50%;
         transform: translateX(-50%);
         bottom: 10px;
         font-size: 14px;
         text-align: center;
         z-index: 1;
    }
     .info a {
         text-decoration: none;
         color: #000;
         background: #ffcc05;
    }
     
    </style>