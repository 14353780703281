<template><br>

    <div>
        <Navigation @reload-parent="methodThatForcesUpdate" yvisina=15 iocon="1">
        <template #atag>
          <a></a>
      
        </template>
        <template #content>
<br><br><br>
            <section id="hero-animated" class="hero-animated d-flex align-items-center">
          <div class="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">

            <h2><span>Manikura & Pedikura</span></h2>
            <p>Oasis</p>
         
          </div>
      
      </section>

      

  
      <section class="menu">
        <div class="container">
        <div class="row">
        <div class="col-md-12">
       
        </div>
        </div>
        <div class="food-menu wow fadeInUp">
        <div class="row">
        <div class="col-md-12"> 
            <div class="menu-tags" >
        
        <span id="ruke" class="tagsort-active kategorija2" @click="filterruke(0)"><img src="../../assets/hand.png" style="color:gray;width:30px;padding-bottom:5px;"><br><span >Hand</span></span>
        <span id="noge" class=" kategorija2" @click="filterruke(1)"><img src="../../assets/1.png" style="color:gray;width:30px;padding-bottom:5px;"><br><span >Feet</span></span>
 <hr>
        </div>
  
        <div class="menu-tags hand" v-show="ruke" >
   
          <span id="1" class="tagsort-active kategorija" @click="filter(1)"><br><span >NAIL EXTENSION - GEL</span></span>
        <span id="2" class=" kategorija" @click="filter(2)"><br><span >NAIL REFILL</span></span>
        <span  id="3" class="kategorija" @click="filter(3)"><br><span>PERMANENT NAIL POLISH</span></span>
        <span  id="4" class="kategorija"  @click="filter(4)"><br><span>REMOVAL OF GEL/PERMANENT NAIL POLISH </span></span>
        <span id="5"  class="kategorija"  @click="filter(5)"><br><span >CLASSIC MANICURE WITH NAIL POLISH </span></span>



        </div>
        <div class="menu-tags feet" v-show="!ruke">
       
        
        <span id="6" class="tagsort-active kategorija" @click="filter(6)"><br><span >NAIL CARE - POLISH</span></span>
        <span id="7" class=" kategorija" @click="filter(7)"><br><span >NAIL CARE - PERMANENT NAIL POLISH</span></span>
        <span  id="8" class="kategorija" @click="filter(8)"><br><span>CLASSIC PEDICURE - POLISH</span></span>
        <span  id="9" class="kategorija"  @click="filter(9)"><br><span>CLASSIC PEDICURE - PERMANENT POLISH</span></span>
        <span id="10"  class="kategorija"  @click="filter(10)"><br><span >CLASSIC PEDICURE - GEL</span></span>
        <span id="11"  class="kategorija"  @click="filter(11)"><br><span >MEDICAL PEDICURE</span></span>
        <span id="12"  class="kategorija"  @click="filter(12)"><br><span >MEDICAL PEDICURE - PERMANENT NAIL POLISH</span></span>
        <span id="13"  class="kategorija"  @click="filter(13)"><br><span >MEDICAL PEDICURE - GEL</span></span>

        </div>

        </div>
      
  
       
    
        </div>


        

        </div>
        </div>
        </section>
      
      <div class="container">
      <form style="padding:50px;" v-on:submit.prevent="getSport">
        <input type="date" class="form-control" name="date" min="2024-04-29"  v-model="form.datum" v-on:input="getSport"><br>
    
      </form>
    
    


      
          <div class="row">
            <div  class="col-md-4 col-xl-3" v-if="nemasdostupno()">
                  <div class="card bg-c-tenis order-card">
        
      
                      <div class="card-block">
                          <i class=" f-right" style="cursor:pointer;"></i>
                          <span>
                
                              <h2 class="text-right">Nema termina</h2>    
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
              
           
              <template v-if="[4, 5].includes(form.vrsta)">
              <div class="col-md-4 col-xl-3" style="padding:15px;"  :id="'termin'+ i" v-for="i in datoteke.pola">


                <template v-if="Array.isArray(i)">
                    <div class="card bg-c-pink order-card" >
        
      
        <div class="card-block">
            <i class="fa fa-times f-right"   @click="cancelpotvrda(i[3], this.form.datum, i[0])" style="cursor:pointer;"></i>
            
           
            <span>
                <span>{{ tretmani[i[1]] }}</span>
                <h2 class="text-right">{{TimeString(i[0])}}h- 
                         <template v-if="[4, 5].includes(i[1])">{{TimeString2(i[0], 30)}}</template>
            <template v-if="[3, 6, 7, 8].includes(i[1])">{{TimeString2(i[0], 60)}}</template>
            <template v-if="[1, 2, 9, 10, 11, 12, 13].includes(i[1])">{{TimeString2(i[0], 90)}}</template>
                    <br>
              
            <span v-if="i[2]">{{ i[2] }}</span></h2>    
            <span v-if="i[4]">{{ i[4] }}</span>
   
     
            </span> 

        </div>
    </div>
                            </template>



                  <div class="card bg-c-tenis order-card" v-else>
        
      
                      <div class="card-block">
                          <i class="fa fa-arrow-right f-right"  @click="rezervirajpotvrda(i)" style="cursor:pointer;"></i>
                          
                          <span>
                       
                              <h2 class="text-right" style="font-size: 22px">{{TimeString(i)}}h - {{TimeString2(i, 30)}}h <span style="font-size: 15px;font-weight: bold;">Price: {{cijene[form.vrsta]}}€</span></h2>   
                              
                            
                          
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
            </template>
            <template v-if="[3, 6, 7, 8].includes(form.vrsta)">
              <div class="col-md-4 col-xl-3" style="padding:15px;"  :id="'termin'+ i" v-for="i in datoteke.jedan">
                <template v-if="Array.isArray(i)">
                    <div class="card bg-c-pink order-card" >
        
      
        <div class="card-block">
            <i class="fa fa-times f-right"   @click="cancelpotvrda(i[3], this.form.datum, i[0])" style="cursor:pointer;"></i>
            
           
            <span>
                <span>{{ tretmani[i[1]] }}</span>
                <h2 class="text-right">{{TimeString(i[0])}}h- 
                         <template v-if="[4, 5].includes(i[1])">{{TimeString2(i[0], 30)}}</template>
            <template v-if="[3, 6, 7, 8].includes(i[1])">{{TimeString2(i[0], 60)}}</template>
            <template v-if="[1, 2, 9, 10, 11, 12, 13].includes(i[1])">{{TimeString2(i[0], 90)}}</template>
                    <br>
                    <span v-if="i[2]">{{ i[2] }}</span></h2>    
            <span v-if="i[4]">{{ i[4] }}</span> 
   
     
            </span> 

        </div>
    </div>
                            </template>


                
                  <div class="card bg-c-tenis order-card" v-else>
        
      
                      <div class="card-block">
                          <i class="fa fa-arrow-right f-right"  @click="rezervirajpotvrda(i)" style="cursor:pointer;"></i>
         
                         
                          <span>

                              <h2 class="text-right" style="font-size: 22px">{{TimeString(i)}}h - {{TimeString2(i, 60)}}h <span style="font-size: 15px;font-weight: bold;">Price: {{cijene[form.vrsta]}}€</span></h2>    
                              
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
            </template>
            <template v-if="[1, 2, 9, 10, 11, 12, 13].includes(form.vrsta)">
              <div class="col-md-4 col-xl-3" style="padding:15px;"  :id="'termin'+ i" v-for="i in datoteke.jedanipo">

                <template v-if="Array.isArray(i)">
                    <div class="card bg-c-pink order-card" >
        
      
        <div class="card-block">
            <i class="fa fa-times f-right"  @click="cancelpotvrda(i[3], this.form.datum, i[0])"  style="cursor:pointer;"></i>
            
           
            <span>
                <span>{{ tretmani[i[1]] }}</span>
                <h2 class="text-right">{{TimeString(i[0])}}h- 
                         <template v-if="[4, 5].includes(i[1])">{{TimeString2(i[0], 30)}}</template>
            <template v-if="[3, 6, 7, 8].includes(i[1])">{{TimeString2(i[0], 60)}}</template>
            <template v-if="[1, 2, 9, 10, 11, 12, 13].includes(i[1])">{{TimeString2(i[0], 90)}}</template>
                    <br>
                    <span v-if="i[2]">{{ i[2] }}</span></h2>    
            <span v-if="i[4]">{{ i[4] }}</span>  
   
     
            </span> 

        </div>
    </div>
                            </template>

                
                  <div class="card bg-c-tenis order-card" v-else>
        
      
                      <div class="card-block">
                          <i class="fa fa-arrow-right f-right"  @click="rezervirajpotvrda(i)" style="cursor:pointer;"></i>
         
                       
                          <span>

                              <h2 class="text-right" style="font-size: 22px">{{TimeString(i)}}h - {{TimeString2(i, 90)}}h <span style="font-size: 15px;font-weight: bold;">Price: {{cijene[form.vrsta]}}€</span></h2>    
                            
                            
                   
                          </span> 
      
                      </div>
                  </div>
              </div>
            </template>
              
         
      <div style="height:200px;"></div>
        </div>
      </div>
    
    
    <!-- ======= Footer ======= -->
    <footer id="footer" class="footer">
    
    <div class="footer-content">
      <div class="container">
        <div class="row">
    
          <div class="col-lg-12 col-md-12">
            <div class="footer-info">
              <h3>Saltwater Oasis Manicure and Pedicure</h3>
              <p>
       
            <p>At  Saltwater Oasis Wellness&Spa <br></p><br>
            <a href= "mailto: wellness@monperin.hr"> wellness@monperin.hr </a>
     
            
              </p>
            </div>
          </div>
    
    
          
    
          <div class="col-lg-4 col-md-12 footer-newsletter">
    
    
    
    
    </div>
    
    
    
    
        </div>
      </div>
    </div>
    
    
    </footer><!-- End Footer -->
    
        </template>
    
      </Navigation >
    
      <div class="modal fade" id="rezervirajModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
    <div class="modal-dialog inputmodal-dialog modal-lg">
      <div class="modal-content inputmodal-content">
        <div class="modal-header">
          <h4 class="modal-title modelheading" id="deleteLabel">Reserve Massage</h4>
          <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('rezervirajModal')"  ></i>

        </div>
        <div class="modal-body">
     
          <form>

        
            <input class="form-control" placeholder="Opis" v-model="form.ime">
     
        </form>

        </div>
        <div class="modal-footer d-flex justify-content-center">
       


           <button type="submit" id="submit" class="btn btn-info f-left modalbutton" data-dismiss="modal" @click="rezerviraj">Potvrdi</button>
            <div style="padding-top:10%;" class="prazanprostor"></div>
      
        </div>
      </div>
    </div>
  </div>

      <div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title modelheading" id="deleteLabel">Otaži</h4>
              <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('deleteConfirmModal')" ></i>

            </div>
            <div class="modal-body">
              <p>Otkaži termin</p>
            </div>
            <div class="modal-footer d-flex justify-content-center">
       <button type="button" @click="cancel" class="btn btn-info f-left modalbutton" style="border:none;background-color: #dc3545;">Cancel</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="reserveModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title modelheading" id="deleteLabel">Reserve</h4>
              <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('reserveModal')" ></i>

            </div>
            <div class="modal-body">
              <p>Since you are not logged in, please fill in this form:</p>
              <input class="form-control" type="text" placeholder="Your name or camp location...">
              <input class="form-control" type="email" placeholder="Email where we will send you confirmation">
            </div>
            <div class="modal-footer d-flex justify-content-center">
           <button type="button" @click="cancel" class="btn btn-info f-left modalbutton" style="border:none;">Cancel</button>
            </div>
          </div>
        </div>
      </div>
      

    

      </div>
        </template>
        
     
        <script>
        import axios from 'axios'
        import Navigation from '../../components/Navigacija.vue'
        export default {
          name: 'Info',
          components: {
            Navigation
          },
        
          data(){
            return{
                ruke:true,
              datoteke:[],
              rezervacije:[],
              id:"",
              canceldatum:"",
              cancelsat:"",
              navBarKey:0,
              buttonDisabled: false,
              prijava:false,
              email:"",

              form:{
                ime:"",
                datum:new Date().toISOString().substr(0, 10),
                email:"",
                terminsat:"",
                vrsta:"",
                vrsta:1,
                lokacija:"oasis",
                ime:"",
              
    
              },
              cijene:{
                1:55,
                2:45,
                3:40,
                4:15,
                5:15,
                6:30,
                7:40,
                8:35,
                9:45,
                10:50,
                11:45,
                12:60,
                13:65
              },
              tretmani:{
                1:"NAIL EXTENSION - GEL",
                2:"NAIL REFILL",
                3:"PERMANENT NAIL POLISH",
                4:"REMOVAL OF GEL/PERMANENT NAIL POLISH",
                5:"CLASSIC MANICURE WITH NAIL POLISH",
                6:"NAIL CARE - POLISH",
                7:"NAIL CARE - PERMANENT NAIL POLISH",
                8:"CLASSIC PEDICURE - POLISH",
                9:"CLASSIC PEDICURE - PERMANENT POLISH",
                10:"CLASSIC PEDICURE - GEL",
                11:"MEDICAL PEDICURE",
                12:"MEDICAL PEDICURE - PERMANENT NAIL POLISH",
                13:"MEDICAL PEDICURE - GEL"
              }
            }
          },
          mounted(){
            
            window.scrollTo(0, 0)
            this.minute("pedeset")
            this.filterruke(0)
            this.platform = Capacitor.getPlatform()
            
            const preloader = document.querySelector('#preloader');
      
    
            
            this.getSport()
          
        
          },
        
          methods:{

            filterruke(broj){
                if(broj==0){
                    this.ruke = true
                    $('#noge').css('opacity', '0.5').removeClass('tagsort-active');
                    $('#ruke').addClass('tagsort-active').css('opacity', '1');
                }
                else{
                    this.ruke = false
                    $('#ruke').css('opacity', '0.5').removeClass('tagsort-active');
                    $('#noge').addClass('tagsort-active').css('opacity', '1');
                }
      
            },
            async togglepolicy(tekst){
              $("#"+tekst).toggle();
            },
    
            async plati(){
            if(!document.getElementById('flexCheckDefault').checked){
              this.$root.toastnoti("Greška", "Confirm sales policy to proceed...", "greska")
              return;
            }
              $("#preloader").show();
              $('#rezervirajModal').modal('hide')
              await axios
              
              .get("placanje/stvorihash/sport/"+this.form.datum+"/"+this.form.terminsat)
              .then(response => {
                this.$root.toastnoti("Greška", "Redirecting...", "uspjeh")
                this.openPaymentForm(response.data.hash, response.data.cartid)
      
      
        
              })
              .catch(error => {
                this.$root.toastnoti("Greška", "There has been error with our Payment service, please try to contact us because app might not be working properly", "greska")
                $("#preloader").hide();
               
              })
            },

    
            methodThatForcesUpdate(key){
              
              this.mojiTermini()
              this.getSport()
        
          },
    
          async cancelpotvrda(id){
              this.id = id
    
              this.$root.otvoriform('deleteConfirmModal')
      
          },
    
    
           moguceodgoditi(datum, sat){
            const targetDate = new Date(`${datum}T${sat.toString().padStart(2, '0')}:00:00`)
            const now = new Date();
            const diffInHrs = Math.floor((targetDate - now) / (1000 * 60 * 60));
            
    
            if (diffInHrs > 2 && targetDate > now) {
              return true
            } else {
              return false
            }
    
      
          },
          async rezervirajpotvrda(sat){
              this.form.terminsat = sat
          
              this.$root.otvoriform('rezervirajModal')
      
          },
          async cancel(){
    
                var apilink = '/api/odgodimanikura/'+this.id
               
    
      
              await axios
              
                .get(apilink)
                .then(response => {
                  this.$root.toastnoti("Greška", "Termin je odgođen", "uspjeh")
                  $('#deleteConfirmModal').modal('hide')
                  this.getSport()
        
          
                })
                .catch(error => {
                  this.$root.toastnoti("Greška", "Greška kod odgađanja", "uspjeh")
                 
                })
      
          },
          async rezerviraj(){
            this.buttonDisabled = true
            
          
              axios.post('/api/rezervirajmanikura/', this.form)
            .then((res) => {
    
              
                this.$root.toastnoti("Uspjeh", "Rezerviran termin", "uspjeh")
                

                this.getSport()
                    
              })
            .catch((error) => {
          
              this.buttonDisabled = false;
              if (error.response.status === 401) {
                axios.defaults.headers.common["Authorization"] = ""
                localStorage.removeItem("token")
                localStorage.removeItem("username")
                localStorage.removeItem("userid")
                this.$store.commit('removeToken')
              //this.$root.toastnoti("Error", "Your account is not valid anymore, either your stay has come to the end or the password has been changed. If you are still with us, try to log in again.", "greska")
              this.$router.push('/bye/')
            }
            else{
              this.$root.toastnoti("Greška", "Greška kod rezerviranja termina", "error")
            }
             
              
            })
    
            $('#rezervirajModal').modal('hide')
      
          },
         
            async getSport() {
              $("#preloader").show()
              this.datoteke = {}
              const inputDate = new Date(this.form.datum); // replace with your input date
                  const dayOfWeek = inputDate.getDay();
                  if(dayOfWeek !== 1  && dayOfWeek !== 4){
          
          
                    this.$root.toastnoti("Info", "Manicure and Pedicure works on monday and thursday", "info")
                    $("#preloader").hide()
                    return false
                    

                    
                  }
// Check if it's Monday (dayOfWeek === 1)

        const now = new Date();
    
        const hasAlreadyPassed = inputDate < now &&
                                (inputDate.getFullYear() < now.getFullYear() ||
                                (inputDate.getFullYear() === now.getFullYear() && inputDate.getMonth() < now.getMonth()) ||
                                (inputDate.getFullYear() === now.getFullYear() && inputDate.getMonth() === now.getMonth() && inputDate.getDate() < now.getDate()));
    
    
              if (hasAlreadyPassed) {
              this.$root.toastnoti("Greška", "Date has passed", "error")
              $("#preloader").hide();
              return false;
            }
            if (!this.form.datum) {
              this.$root.toastnoti("Greška", "Unesite datum", "error")
              return false;
            }
              //$("#preloader").show();
        
              await axios
                .get('/api/manikuratermini/'+this.form.datum)
                .then(response => {
                  this.datoteke = response.data
                  this.mergedSlots()
                  $("#preloader").hide();
        
          
                })
                .catch(error => {
                  this.$root.toastnoti("Greška", "Greška kod dohvaćanja termina", "greska")
                  $("#preloader").hide();
                
                 
                })
        
            },
            mergedSlots() {
                const merged = [...this.datoteke.pola];
      for (const slot of this.datoteke.zauzeti) {
        const slotTime = slot[0].substring(0, 5); // Extract hour and minute portion
        const index = merged.findIndex((time) => time > slotTime);
        if (index === -1) {
          merged.push(slot);
        } else {
          merged.splice(index, 0, slot);
        }
      }
      this.datoteke.pola = merged

      const mmerged = [...this.datoteke.jedan];
      for (const slot of this.datoteke.zauzeti) {
        const slotTime = slot[0].substring(0, 5); // Extract hour and minute portion
        const index = mmerged.findIndex((time) => time > slotTime);
        if (index === -1) {
            mmerged.push(slot);
        } else {
            mmerged.splice(index, 0, slot);
        }
      }
      this.datoteke.jedan = mmerged


      const mmmerged = [...this.datoteke.jedanipo];
      for (const slot of this.datoteke.zauzeti) {
        const slotTime = slot[0].substring(0, 5); // Extract hour and minute portion
        const index = mmmerged.findIndex((time) => time > slotTime);
        if (index === -1) {
            mmmerged.push(slot);
        } else {
            mmmerged.splice(index, 0, slot);
        }
      }
      this.datoteke.jedanipo = mmmerged


      console.log(this.datoteke)

        },

       
            filter(vrsta){
  
        $('.kategorija').css('opacity', '0.5').removeClass('tagsort-active');
        $('#'+vrsta).addClass('tagsort-active').css('opacity', '1');
        
        console.log("check")

        this.form.vrsta = vrsta


    },
    minute(vrsta){
        $('.vrijeme').css('opacity', '0.5').removeClass('tagsort-active');
        $('#'+vrsta).addClass('tagsort-active').css('opacity', '1');
  if(vrsta=="trideset"){
    this.form.trideset = true
  }
  else{
    this.form.trideset=false
  }

  $('.'+vrsta ).show()


},
vrstaime(vrsta){
        switch (vrsta) {  

      case 0 : return 'Back, neck, head and shoulders';
      case 1 : return 'Foot Reflexolog';
      case 2: return 'Coconut Massage';
      case 3: return 'Aroma massage';
      case 5: return 'Thai oil Massage';
      case 4: return 'Thai traditional massage';
      case 6: return 'Coconut oil massage';
      default: return 'Foot Reflexolog';
    }
    





},
nemasdostupno(){
  if ([4, 5].includes(this.form.vrsta)){
    if(!this.datoteke.pola || this.datoteke.pola.length === 0){
      return true
    }
  }
  if ([3, 6, 7, 8].includes(this.form.vrsta)){
    if(!this.datoteke.jedan || this.datoteke.jedan.length === 0){
      return true
    }
  }
  if ([1, 2, 9, 10, 11, 12, 13].includes(this.form.vrsta)){
    if(!this.datoteke.jedanipo || this.datoteke.jedan.jedanipo === 0){
      return true
    }
  }

 
},
TimeString(time){

var time = new Date('2023-01-01T' + time);
var updatedTimeString = time.toTimeString().slice(0, 5);

return updatedTimeString
},
TimeString2(time, broj){

var time = new Date('2023-01-01T' + time);
time.setMinutes(time.getMinutes() + broj);

var updatedTimeString = time.toTimeString().slice(0, 5);

return updatedTimeString
}
          }
        }
        </script>
      
      
      <style>
  
  .image-container img {

    display: block;
    width: 100%;
    min-height: 300px;
    max-height: 5%;
    max-width: 100%; /* Ensure the image doesn't exceed the container width */
  
  }
.darken-image {
  
    position: relative;
    display: inline-block;
  }

  .darken-image img {
    display: block;
  }
  .darken-image .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
      
      .btn-getstarted {
          font-size: 16px;
          color: var(--color-white);
          background: var(--color-primary);
          padding: 8px 23px;
          border-radius: 4px;
          transition: 0.3s;
          font-family: var(--font-secondary);
          border:none;
    
      
      
      }
    
      
      .order-card {
          color: #fff;
      }
      
      .bg-c-blue {
          background: linear-gradient(45deg,#4099ff,#73b4ff);
      }
      
      .bg-c-green {
      
          background: linear-gradient(45deg, rgba(159, 217, 207, 0.7), rgba(89, 224, 197, 0.7));
          
      }
      
      .bg-c-yellow {
          background: linear-gradient(45deg,#FFB64D,#ffcb80);
      }
      
      .bg-c-pink {
          background: linear-gradient(45deg,#FF5370,#ff869a);
      }
      
      
      .card {
          border-radius: 5px;
          -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
          box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
          border: none;
          margin-bottom: 30px;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
      }
      
      .card .card-block {
          padding: 25px;
      }
      
      .order-card i {
          font-size: 26px;
      }
      
      .f-left {
          float: left;
      }
      
      .f-right {
          float: right;
      }
      
      
      .bg-c-tenis {
          background: linear-gradient(45deg,#bed6db,#44a3b6);
      }
      </style>
      
      <style scoped>
        
      .hero-animated {
          padding: 50px 0 0px; 
          min-height: 30vh;
      }
      
      section {
          padding: 0px 0; 
      
      }
    
      .hero-animated p {
        color: rgba(var(--color-secondary-rgb),.8);
        margin: 0 0 30px 0;
        font-size: 20px;
        font-weight: 400;
    }
    
    @media (max-width: 640px){
      .hero-animated p {
        font-size: 16px;
        margin-bottom: 30px;
    }
    }
    .menu-tags span{
      display: inline-block;
    }
    
      
      </style>
      
    <style scoped>
    .btn-outline-light:hover {

    background-color: transparent;
    border-color: #f8f9fa;
}

    .oasisnaslov{
      font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-style: normal;
    }
    


    .hero-animated{
      min-height: 15vh;
      padding: 0px
    }
    </style>

<style scoped>



#baseApp:after {
    content: '';
    background-image: url(../../assets/img/slider.jpg);
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 60px;
    width: 262px;
    height: 625px;
    z-index: -1;
    opacity: 0.5;
  }
  


      
      .btn-getstarted {
          font-size: 16px;
          color: var(--color-white);
          background: var(--color-primary);
          padding: 8px 23px;
          border-radius: 4px;
          transition: 0.3s;
          font-family: var(--font-secondary);
          border:none;
    
      
      
      }
    
      
      .order-card {
          color: #fff;
      }
      
      .bg-c-blue {
          background: linear-gradient(45deg,#4099ff,#73b4ff);
      }
      
      .bg-c-green {
      
          background: linear-gradient(45deg, rgba(159, 217, 207, 0.7), rgba(89, 224, 197, 0.7));
          
      }
      
      .bg-c-yellow {
          background: linear-gradient(45deg,#FFB64D,#ffcb80);
      }
      
      .bg-c-pink {
          background: linear-gradient(45deg,#FF5370,#ff869a);
      }
      
      
      .card {
          border-radius: 5px;
          -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
          box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
          border: none;
          margin-bottom: 30px;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
      }
      
      .card .card-block {
          padding: 25px;
      }
      
      .order-card i {
          font-size: 26px;
      }
      
      .f-left {
          float: left;
      }
      
      .f-right {
          float: right;
      }
      
      
      .bg-c-tenis {
          background: linear-gradient(45deg,#bed6db,#44a3b6);
      }
      </style>
      
      <style scoped>
        
      .hero-animated {
          padding: 50px 0 0px; 
          min-height: 30vh;
      }
      
      section {
          padding: 0px 0; 
      
      }
    
      .hero-animated p {
        color: rgba(var(--color-secondary-rgb),.8);
        margin: 0 0 30px 0;
        font-size: 20px;
        font-weight: 400;
    }
    
    @media (max-width: 640px){
      .hero-animated p {
        font-size: 16px;
        margin-bottom: 30px;
    }
    }
    
    .menu-tags {
        text-align: center;
        margin-bottom: 20px
    }
    .menu-tags2, .menu-tags3, .menu-tags4 {
        text-align: center;
        margin-bottom: 50px;
        margin-top: 20px
    }
    .menu-tags span, .menu-tags2 span, .menu-tags3 span, .menu-tags4 span {
        font-family: raleway, sans-serif;
        font-size: 18px;
        color: #828281;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 20px;
        padding-bottom: 2px;
        padding-top: 5px;
        cursor: pointer;
        transition: all .5s ease;
        border-bottom: 3px solid transparent
    }
    .menu-tags .tagsort-active, .menu-tags2 .tagsort2-active, .menu-tags3 .tagsort3-active, .menu-tags4 .tagsort4-active {
        border-bottom: 3px solid #5fbae9;
        color: #4b4741;
    
    }
    .menu .food-menu .menu-item, .menu .food-menu .menu-item3 {
        overflow: hidden;
        margin: 15px 0;
        padding-left: 10px;
        border-left: 3px solid #fff
    }
    .menu .food-menu .menu-item.featured {
        border-left: 3px solid #f9c56a
    }
    .menu .food-menu .menu-item .dotted-bg, .menu .food-menu .menu-item3 .dotted-bg {
        border-top: dotted 2px #ccc;
        left: 0;
        top: 15px;
        width: 100%;
        z-index: -1
    }
    .menu .food-menu .menu-item .menu-wrapper, .menu .food-menu .menu-item3 .menu-wrapper {
        position: relative;
        z-index: 1
    }
    .menu .food-menu .menu-item h4 class="testh4", .menu .food-menu .menu-item3 h4 class="testh4" {
        text-align: left;
        margin: 5px 0;
        float: left;
        padding-right: 10px;
        display: inline;
        background: #fff
    }
    .menu .food-menu .menu-item p, .menu .food-menu .menu-item3 p {
        font-size: 18px
    }
    .menu .food-menu .menu-item .price, .menu .food-menu .menu-item3 .price {
        float: right;
        padding-left: 10px;
        line-height: 19.8px;
        margin: 5px 0;
        background: #fff none repeat scroll 0% 0%;
        font-family: Josefin Sans;
        font-size: 22px;
        font-weight: 700
    }
    .menu .food-menu .menu-item-active {
        border-left: 3px solid #f9c56a
    }
    .menu .food-menu .menu-btn {
        text-align: center;
        padding: 20px 0
    }
    
    .menu-more, .menu-more:hover {
        width: 55px;
        height: 55px;
        -webkit-border-radius: 27px 28px 28px 27px/27px 27px 28px 28px;
        -moz-border-radius: 27px 28px 28px 27px/27px 27px 28px 28px;
        border-radius: 27px 28px 28px 27px/27px 27px 28px 28px;
        background-color: #f9c56a;
        -webkit-box-shadow: 0 5px 4px rgba(177, 177, 177, .75);
        -moz-box-shadow: 0 5px 4px rgba(177, 177, 177, .75);
        box-shadow: 0 5px 4px rgba(177, 177, 177, .75);
        text-shadow: 0 5px 5px #c1913e;
        border: solid 1px #f2ba57;
        color: #fff;
        line-height: 0;
        font-weight: 300;
        font-size: 65px;
        text-align: center;
        display: table;
        position: absolute;
        z-index: 48;
        bottom: -27.5px;
        right: 45px;
        font-family: Raleway;
        padding-top: 27px;
        text-decoration: none
    }
    .menu2-overlay {
        background: rgba(19, 19, 19, .7);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transition: .4s
    }
    .menu-info:hover .menu2-overlay {
        opacity: 1;
        visibility: visible;
        transition: .4s
    }
    .menu2-overlay h4 class="testh4" {
        font-size: 19px;
        color: #fff;
        margin: 46px 0 5px
    }
    .menu2-overlay p {
        color: #fff;
        font-size: 19px;
        line-height: 24px
    }
    .menu2-overlay .price {
        font-family: Josefin Sans;
        font-size: 36px;
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        border-top: 1px solid #3e3d3b;
        width: 100%;
        display: table;
        margin: 17px 0 0;
        padding-top: 17px
    }
    .menu-item3 img {
        width: 83px;
        float: left
    }
    .menu-item3 .menu-wrapper {
        margin-left: 115px
    }
    .menu-items3 p {
        float: left
    }
    .menu-items4 {
        margin-bottom: 75px
    }
    .menu-item4 {
        margin-bottom: 30px
    }
    .menu-item4 a {
        text-decoration: none !important
    }
    .menu4-overlay {
        width: 100%;
        background-color: #fff;
        box-shadow: 0 5px 5px #e2e2e2;
        margin-bottom: 0;
        margin-top: -10px;
        text-align: center;
        text-decoration: none !important;
        display: table
    }
    .menu4-overlay h4 class="testh4" {
        font-size: 19px;
        color: #4b4741;
        margin: 46px 0 15px
    }
    .menu4-overlay p {
        color: #4b4741;
        font-size: 18px;
        line-height: 24px
    }
    .menu4-overlay .price {
        font-family: Josefin Sans;
        font-size: 36px;
        font-weight: 700;
        text-transform: uppercase;
        color: #e7ad48;
        width: 100%;
        display: table;
        margin: 0;
        padding: 13px 0 45px
    }
    
    @media(max-width:992px) {
        .list {
            padding-left: 0;
            margin-bottom: 0
        }
        .navbar .navbar-nav>li>a {
            padding: 9px 15px
        }
        .subscribe .btn-default {
            padding: 0 15px;
            height: 46px;
            border: 1px solid #f2ba57;
            margin-left: 0 px;
            margin-top: 0
        }
        .team-staff {
            border-bottom: 1px solid #dcdddd;
            padding-bottom: 30px;
            margin-bottom: 30px
        }
        .contact-social {
            margin-bottom: 60px
        }
        .menu-item2 {
            margin-bottom: 50px;
            width: 50%
        }
        .menu .food-menu .menu-item3 h4 class="testh4" {
            font-size: 14px
        }
        .menu-item4 {
            width: 50%
        }
        .rc-info {
            margin-left: 0
        }
        .recipie-content img {
            float: none;
            width: 100%;
            margin-bottom: 25px
        }
        .recipie-content hr {
            margin: 20px 0 50px
        }
        .rc-info {
            margin-bottom: 30px
        }
    }
    @media(max-width:767px) {
        .reservation .reservation-form .reservation-btn a {
            padding: 15px 14px;
            font-size: 15px
        }
        .table-responsive {
            padding: 10px 19px
        }
        .fp-content img {
            width: 100%
        }
        .recipie-content hr {
            margin: 20px 0 50px
        }
        .rc-info {
            margin-bottom: 30px
        }
        .menu-item2 {
            width: 100%
        }
        .menu-item2 img, .menu-item4 img {
            min-width: 100%
        }
        .post-date {
            margin: 0 0 30px;
            float: none
        }
        article h4 class="testh4" {
            margin: 40px 0 10px
        }
        h1, .h1 {
            font-size: 32px
        }
        .subscribe .btn-default {
            paddwing: 0 15px;
            height: 46px;
            border: 1px solid #f2ba57;
            margin-left: 0 px;
            margin-top: 0
        }
        .subscribe input {
            width: auto !important
        }
        .trusted-quote .quote-body {
            font-size: 19px
        }
        .trusted-quote .quote-author {
            font-size: 18px
        }
        .menu-tags span, .menu-tags2 span, .menu-tags3 span, .menu-tags4 span {
            font-size: 15px;
            margin: 0 8px 4px;
            display: inline-block
        }
        .page_header h2 {
            font-size: 61px
        }
        .menu .food-menu .menu-item3 h4 class="testh4" {
            font-size: 14px
        }
        .menu-item4 {
            width: 100%
        }
    }
    @media(max-width:480px) {
        .reservation .reservation-form {
            padding: 10px
        }
        .special-slider .slider-content .btn {
            margin: 17px 5px 20px 0;
            font-weight: 700;
            font-size: 10px;
            letter-spacing: normal;
            padding: 10px 20px;
            float: left
        }
        .element-tab .nav-tabs>li>a {
            color: #4b4741;
            font-size: 12px;
            font-family: Raleway;
            padding: 19px 14px
        }
        .cart-table tbody tr td a {
            font-size: 11px
        }
        .cart-table {
            font-size: 8px
        }
        .cart-table img {
            max-width: 50px;
            height: auto
        }
        .menu .food-menu .menu-item3 .price {
            
            float: none;
            padding-left: 0;
            margin: 6px 0 11px;
            font-size: 22px;
            display: table;
            padding-top: 10px
        }
        .contact-info p {
            font-size: 15px
        }
        .menu2-overlay h4 class="testh4" {
            font-size: 17px;
            margin: 22px 0 5px
        }
        .table>tbody>tr>td {
            padding: 8px 2px
        }
        .shop-grid select {
            float: none;
            display: block;
            margin-bottom: 15px
        }
        .sg-list {
            margin-left: -12px
        }
    }
    
    .menu-info {
        position: relative;
        overflow: hidden
    }
    .menu-item4 img {
        transform: scale(1);
        transition: .4s;
        opacity: 1
    }
    .menu-item4:hover img {
        transform: scale(1.1);
        transition: .4s;
        opacity: .9
    }
    .menu4-overlay {
        position: relative;
        z-index: 444
    }
    .menu-item2 img {
        transform: scale(1);
        transition: .4s;
        opacity: 1
    }
    .menu-item2:hover img {
        transform: scale(1.1);
        transition: .4s;
        opacity: .9
    }
    
    .menu-tags span {
    display: inline-block;
}
      
      </style>
  