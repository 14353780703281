<template>
<Navigacija @reload-parent="methodThatForcesUpdate" yvisina=15 iocon="1">
    
    <template #atag><a class="btn-getstarted scrollto" style="border-radius: 20px;background-color: rgb(249, 243, 243);padding: 8px 15px;cursor: pointer;" id="podsjetnik" @click="this.$router.go(-1)"><i class="fa fa-arrow-left" style="color:rgb(14, 162, 189);font-size: 20px;"></i></a></template>
      <template #content>
        
  <br><br><br><br><br><br>
<div style="color:gray" class="text-center cd-fail-message"  v-if="!delivery.id">Dostava ne postoji</div>
  <div class="card" v-if="delivery.to" id="deliverydetail">
            <div class="title">Detalji Narudžbe {{delivery.id}}&nbsp;    {{new Date(delivery.zavrijeme).toLocaleString('en-UK', {day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false})}}</div>
            <div class="title">	<a :href="'tel:'+delivery.telefon">{{delivery.telefon}}</a></div>
            <div class="title" id="cijelistatus"><span id="status" style="color:green;">{{delivery.status}}</span> <span  id="osoba"><template v-if="delivery.preuzeo">- Preuzima {{delivery.preuzeo}}</template></span></div>
            <div class="title">{{delivery.karticno}}</div>
    <hr>
            <div class="info">
                <div class="row">
                    <div class="col-7">
                        <span id="heading">Restoran</span><br>
                        <span id="details">Mol Picio</span>
                    </div>
                    <div class="col-5 pull-right">
                        <span id="heading">Lokacija</span><br>
                        <span id="details">{{delivery.to}}</span>
                    </div>
             
                </div>      
            </div>      
            <div class="pricing">
                <div class="row">
                  <template v-for="i in delivery.jela">
                   <div class="col-9">
                        <span id="name">{{i[0]}}</span>  
                    </div>
                   
                    <div class="col-3">
                        <span id="price">{{i[2]}}x</span>
                    </div>
                  </template>
                   

          
                </div>
            </div>
        <br>
            <div class="tracking">
                <div class="title">Status Narudžbe</div>
            </div>
            <div class="progress-track">
                <ul id="progressbar">
                    <li  :class="CheckStep(delivery.status, 'Preparing')" id="step1">Preparing</li>
                    <li  :class="CheckStep(delivery.status, 'Ready')" id="step2">Ready</li>
                    <li  :class="CheckStep(delivery.status, 'Taken')"  id="step3">Taken</li>
                    <li  :class="CheckStep(delivery.status, 'Delivered')" id="step5" >Delivered</li>
                </ul>
            </div>

            <div class="tracking">
                <div class="title">Napomena:</div>
                <p class="info2" style="color:gray;" v-if="delivery.opis">{{delivery.opis}}</p><p class="info2" style="color:gray;" v-else>Nema napomene</p>
            </div><br><br>
            
            

            <div class="footer">
                <button class="btn btn-success" v-show="!this.restorani.includes(username) && delivery.preuzeo==username && delivery.status=='Taken'" @click="IzvrsiDostavu()" id="izvrsigumb">Završi</button>&nbsp;
                <button class="btn btn-info" v-show="!this.restorani.includes(username) && delivery.status=='Ready' && !delivery.preuzeo" @click="PreuzmiDostavu(delivery.status)" id="preuzmigumb">Preuzmi</button>&nbsp;
                <button class="btn btn-info" v-show="!this.restorani.includes(username) && delivery.status=='Taken' && delivery.preuzeo==username" @click="VratiDostavu()" id="vratigumb">Vrati</button>
                <button class="btn btn-success" v-show="this.restorani.includes(username) && delivery.status=='Preparing'" id="readygumb"  @click="readyDostava()">Ready</button>
    
               
            </div>
        </div>

<br><br><br><br>
        
        
      </template>
      

</Navigacija>
<div id="preloader"></div>
  </template>



<script>
import Navigacija from '../components/Navigacija.vue'
import axios from 'axios'
export default {

  name: 'HomeView',
  components: {
    Navigacija
  },
  data(){
    return {
      form:{
        od:"",
        do:"",
        broj:""

      },
      username:'',
      restorani:['paleopark', 'portobus', 'sanpolo', 'molpicio'],
      delivery:[],
      formdelete:{
        id:"",
        vrsta:"delivery",
        razlog:"",

      },
      id:"",
      predvideno_vrijeme:"",
      razlog_otkazivanja:"",

    }
  },

  methods:{

    otkaziDostavu(){
        $("#otkazivanje").modal('show');     

    },
    CheckStep(status, tocka) {
      const poredak = ["Preparing", "Ready", "Taken", "Delivered"]
      if(poredak.indexOf(tocka)<=poredak.indexOf(status)){
         return 'step0 active'
      }
    },

    async readyDostava() {

      await axios
        .get('/api/dostava/ready/'+this.id)
        .then(response => {
            $('#status').text("Ready");
            $("#readygumb").hide();
            this.$root.toastnoti("Uspjeh!", "Jelo je označeno kao spremno", "uspjeh")



        })
        .catch(error => {
         
            if (error.response.status === 401) this.$router.push('/korisnik/prijava')

        })

    },


    async IzvrsiDostavu() {

      await axios
        .get('/api/dostava/izvrsi/'+this.id)
        .then(response => {
            $('#status').text("Delivered");
            $("#izvrsigumb").hide();
            $("#vratigumb").hide();
            this.$root.toastnoti("Uspjeh!", "Status je promijenjen u dostavljeno... gost će dobiti račun na email", "uspjeh")



        })
        .catch(error => {
            if (error.response.status === 401) this.$router.push('/korisnik/prijava')
          console.log(error)
        })

    },
     async PotvrdiDostavu() {

      await axios
        .get('/api/dostava/potvrdi/'+this.id)
        .then(response => {
            $('#status').text("Preparing");
            $("#potvrdigumb").hide();
            $("#readygumb").show();
            this.$root.toastnoti("Uspjeh!", "Dostava je potvrđena", "uspjeh")
        })
        .catch(error => {
            if (error.response.status === 401) this.$router.push('/korisnik/prijava')
          console.log(error)
        })

    },

    async VratiDostavu() {

      await axios
        .get('/api/dostava/vrati/'+this.id)
        .then(response => {
            $('#preuzimanje').text("Slobodno za preuzimanje")
            $("#vratigumb").hide();
            $("#izvrsigumb").hide();
            $("#preuzmigumb").show();
            $("#osoba").hide();
            this.$root.toastnoti("Uspjeh!", "Ostali djelatnici je sada mogu preuzeti", "uspjeh")



        })
        .catch(error => {
            if (error.response.status === 401) this.$router.push('/korisnik/prijava')
          console.log(error)
        })

    },

    async PreuzmiDostavu(status) {

      await axios
        .get('/api/dostava/preuzmi/'+this.id)
        .then(response => {
            $('#preuzimanje').hide();
            $('#osoba').text("- Preuzeto");
            $("#preuzmigumb").hide();
            $("#vratigumb").show();
    
            if(status=="Ready"){
                $("#izvrsigumb").show();
            }
            
            this.$root.toastnoti("Uspjeh!", "Preuzeli ste ovu dostavu", "uspjeh")



        })
        .catch(error => {
            if (error.response.status === 401) this.$router.push('/korisnik/prijava')
          console.log(error)
        })

    },



   async getDelivery() {
    const id = this.$route.params.id
      await axios
        .get('/api/dostava/'+id+'/')
        .then(response => {
          this.delivery = response.data
          this.id = this.delivery.id
          console.log(this.delivery)

      
          $("#preloader").hide();

        
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 401) this.$router.push('/korisnik/prijava')
            $("#preloader").hide();
        })

    },
},

  mounted(){
    this.username = localStorage.getItem('username')

    this.getDelivery();

    const preloader = document.querySelector('#preloader');

    
  },
}
</script>





  <style scoped>
 .card{
    margin: auto;
    width: 38%;
    max-width:600px;
    padding: 4vh 0;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-top: 3px solid var(--color-primary);
    border-bottom: 3px solid var(--color-primary);
    border-left: none;
    border-right: none;
}
@media(max-width:768px){
    .card{
        width: 90%;
    }
}
.title{
    color: var(--color-primary);
    font-weight: 600;
    margin-bottom: 2vh;
    padding: 0 8%;
    font-size: initial;
}
#details{
    font-weight: 400;
}
.info{
    padding: 5% 8%;
}
.info2{
    padding: 0% 8%;
}
.info .col-5{
    padding: 0;
}
#heading{
    color: grey;
    line-height: 6vh;
}
.pricing{
    background-color: #ddd3;
    padding: 2vh 8%;
    font-weight: 400;
    line-height: 2.5;
}
.pricing .col-3{
    padding: 0;
}
.total{
    padding: 2vh 8%;
    color: var(--color-primary);
    font-weight: bold;
}
.total .col-3{
    padding: 0;
}
.footer{
    padding: 0 8%;
    font-size: x-small;
    color: black;
}
.footer img{
    height: 5vh;
    opacity: 0.2;
}
.footer a{
    color: rgb(252, 103, 49);
}
.footer .col-10, .col-2{
    display: flex;
    padding: 3vh 0 0;
    align-items: center;
}
.footer .row{
    margin: 0;
}
#progressbar {
    margin-bottom: 3vh;
    overflow: hidden;
    color: rgb(252, 103, 49);
    padding-left: 0px;
    margin-top: 3vh
}

#progressbar li {
    list-style-type: none;
    font-size: x-small;
    width: 20%;
    float: left;
    position: relative;
    font-weight: 400;
    color: rgb(160, 159, 159);
}

#progressbar #step1:before {
    content: "";
    color: rgb(252, 103, 49);
    width: 5px;
    height: 5px;
    margin-left: 0px !important;
    /* padding-left: 11px !important */
}

#progressbar #step2:before {
    content: "";
    color: #fff;
    width: 5px;
    height: 5px;
    margin-left: 32%;
}

#progressbar #step3:before {
    content: "";
    color: #fff;
    width: 5px;
    height: 5px;
    margin-right: 32% ; 
    /* padding-right: 11px !important */
}

#progressbar #step4:before {
        content: "";
        color: #fff;
        width: 5px;
        height: 5px;
        margin-right: 32% ; 
        /* padding-right: 11px !important */
    }
    
    #progressbar #step5:before {
        content: "";
        color: #fff;
        width: 5px;
        height: 5px;
        margin-right: 0px !important;
        /* padding-right: 11px !important */
    }

#progressbar li:before {
    line-height: 29px;
    display: block;
    font-size: 12px;
    background: #ddd;
    border-radius: 50%;
    margin: auto;
    z-index: -1;
    margin-bottom: 1vh;
}

#progressbar li:after {
    content: '';
    height: 2px;
    background: #ddd;
    position: absolute;
    left: 0%;
    right: 0%;
    margin-bottom: 2vh;
    top: 1px;
    z-index: 1;
}
.progress-track{
    padding: 0 8%;
}
#progressbar li:nth-child(2):after {
    margin-right: auto;
}

#progressbar li:nth-child(1):after {
    margin: auto;
}

#progressbar li:nth-child(3):after {
    float: left;
    width: 68%;
}
#progressbar li:nth-child(4):after {
    margin-left: auto;
    width: 132%;
}

#progressbar  li.active{
    color: black;
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: #91d16f;
}

  </style>

