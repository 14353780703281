<template>
 

    <Navigacija @reload-parent="methodThatForcesUpdate" yvisina=15 iocon="1">
      <template #atag><a class="btn-getstarted scrollto" style="border-radius: 20px;background-color: rgb(249, 243, 243);padding: 8px 15px;cursor: pointer;" @click="this.$root.otvoriform('myModal')"><i class="fa fa-plus-circle" style="color:rgb(14, 162, 189);font-size: 25px;"></i></a>
</template>
      <template #content>

        <div id="baseApp">
          
    <br><div style="padding-top:17px;"></div>






<main class="cd-main-content">
  <div class="cd-tab-filter-wrapper">
    <div class="cd-tab-filter">
      <ul class="cd-filters">
        <li class="placeholder">
          <a href="#0" data-type="all">Sve</a> <!-- selected option on mobile -->
        </li>
        <li class="filter"><a class="selected" id="sve" href="#0" data-type="all">Sve</a></li>
        <li class="filter" id="svenovosti" data-filter=".svi"><a href="#0" data-type="svi">Javni</a></li>
        <li class="filter" :data-filter="'.' + grupa" v-for="grupa in novosti.mojegrupe"><a href="#0">{{ grupa }}</a></li>


      </ul>
    </div>
  </div>


  <section class="cd-gallery">

    <ul>
      <div id="vijesti" class="row" >
        <template v-for="novost in novi">

        <li class="col-lg-4" :id="novost.id">



          <div class="card card-margin">
            <div class="card-header no-border">

              <h5 class="card-title"><template v-if="novost.svi">Svi</template> <template v-else
                  v-for="grupa in novost.djelatnici"> {{ grupa }}&nbsp; </template> </h5>


            </div>
            <div class="card-body pt-0">
              <div class="widget-49">
                <div class="widget-49-title-wrapper">
                  <div class="widget-49-date-primary">
                    <span class="widget-49-date-day">{{ new Date(novost.datum).getDate() }}</span>
                    <span class="widget-49-date-month">{{ new Date(novost.datum).toLocaleString('default', { month: 'short' }) }}</span>
                  </div>
                  <div class="widget-49-meeting-info">
                    <span class="widget-49-pro-title">{{ novost.naslov }}</span>
                    <span class="widget-49-meeting-time">{{ novost.opis }}</span>
                  </div>
                </div>

                <div class="widget-49-meeting-action">
                  <i @click="izbrisinovost(novost.id)" class="fa fa-trash" style="cursor: pointer;"
                    aria-hidden="true"></i>

                </div>
              </div>
            </div>
          </div>

        </li>
        </template>


        <template v-for="novost in novosti.novosti">

          <li class="mix col-lg-4" :class="novost.svi === true ? 'svi' : novost.djelatnici, novost.novo === true ? 'novo' : 'staro'" :id="novost.id">



            <div class="card card-margin">
              <div class="card-header no-border">

                <h5 class="card-title"><template v-if="novost.svi">Svi</template> <template v-else
                    v-for="grupa in novost.djelatnici"> {{ grupa }}&nbsp; </template> </h5>


              </div>
              <div class="card-body pt-0">
                <div class="widget-49">
                  <div class="widget-49-title-wrapper">
                    <div class="widget-49-date-primary">
                      <span class="widget-49-date-day">{{ new Date(novost.datum).getDate() }}</span>
                      <span class="widget-49-date-month">{{ new Date(novost.datum).toLocaleString('default', { month: 'short' }) }}</span>
                    </div>
                    <div class="widget-49-meeting-info">
                      <span class="widget-49-pro-title">{{ novost.naslov }}</span>
                      <span class="widget-49-meeting-time">{{ novost.opis }}</span>
                    </div>
                  </div>

                  <div class="widget-49-meeting-action">
                    <i @click="izbrisinovost(novost.id)" class="fa fa-trash" style="cursor: pointer;"
                      aria-hidden="true"></i>

                  </div>
                </div>
              </div>
            </div>

          </li>
        </template>

      </div>
    </ul>

    <div style="color:gray" class="cd-fail-message">Pretraživanje nije dalo rezultata</div>
  </section> <!-- cd-gallery -->

  <div class="cd-filter">
    <form>
      <div class="cd-filter-block">
        <h4>Pretraži</h4>

        <div class="cd-filter-content">
          <input type="search" placeholder="Bilo što">
        </div> <!-- cd-filter-content -->
      </div> <!-- cd-filter-block -->



      <div class="cd-filter-block">
        <h4>Grupe</h4>

        <div class="cd-filter-content">
          <div class="cd-select cd-filters">
            <select class="filter" name="selectThis" id="selectThis">
              <option value="">Izaberi Grupu</option>



              <option v-for="grupa in grupe" :value="'.' + grupa.ime">{{ grupa.ime }}</option>


            </select>
          </div> <!-- cd-select -->
        </div> <!-- cd-filter-content -->
      </div> <!-- cd-filter-block -->


    </form>

    <a href="#0" class="cd-close">Close</a>
  </div> <!-- cd-filter -->

  <a href="#0" class="cd-filter-trigger">Filteri</a>
</main> <!-- cd-main-content -->


</div>
<div id="preloader"></div>


      </template>
  


    </Navigacija>

    <div id="myModal" style="padding-top:90px; z-index:10001;" class="modal fade" tabindex="-1">
  <div class="modal-dialog inputmodal-dialog2">
    <div class="modal-content inputmodal-content">
      <div class="modal-header">
        <h5 class="modal-title modelheading">Kreiraj Obavijest</h5>
        <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('myModal')"  ></i>

      </div>
      <div class="modal-body">
        <form v-on:submit.prevent="submitPost">

          <div style="height:20px;"><br></div>
          <div class="form-group">
            <input type="text" class="form-control" id="post-opis" placeholder="Opis" v-model="createform.opis" required>
          </div>
          <div style="height:20px;"><br></div>
          
          <label for="message" class="col-form-label" style="color:gray;font-size: .9rem;font-weight: bold;">Grupe koje će dobiti obavijest *</label>
          <div id="grupe"> <select id="choices-multiple-remove-button" class="inputVrsta"
              placeholder="Označi djelatnike" multiple>
              <option value='Svi' selected>Svi</option>
            </select> </div>

          
          <br>
          <input type="date" class="form-control" name="date" id="zadatumpicker" v-model="createform.datum" required><br>
          <br>


          <div class="modal-footer justify-content-center">

         
  
            <button type="button" id="submit" class="btn btn-info f-left modalbutton"  @click="submitPost">Potvrdi</button>
            <div style="padding-top:10%;" class="prazanprostor"></div>
          </div>


        </form>


      </div>
    </div>
  </div>
</div>

    <div class="modal fade" id="deleteConfirmModal" style="padding-top:50px;" tabindex="-1" role="dialog" aria-labelledby="deleteLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title modelheading" id="deleteLabel">Izbriši Obavijest</h4>
        <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('deleteConfirmModal')"  ></i>
      </div>
      <div class="modal-body">
        <p>Ova obavijest više neće biti prikazana na listi</p>
 

      </div>
      <div class="modal-footer justify-content-center">
       
  
        <button type="button" id="submit" class="btn btn-info f-left modalbutton"  style="background-color: #dc3545 !important;" data-dismiss="modal" @click="izbrisinovostpost">Potvrdi</button>
            <div style="padding-top:10%;" class="prazanprostor"></div>

      </div>
    </div>
  </div>
</div>


</template>


<script>
import Navigacija from '../components/Navigacija.vue'
import axios from 'axios'

export default {
  components: {
    Navigacija
  },


  data() {
    return {
      pokazatelj: false,
      choice1:null,
      novosti: [],
      novi:[],
      grupe: [],
      form: {
        id: "",
        vrsta: "novost",
      },

      createform: {
        opis: "",
        oznaceni: [],
        datum: new Date().toISOString().substr(0, 10),
        svi: false,
      },


    }
  },

  mounted() {
    
    
    $('[data-toggle="datepicker"]').datepicker({
      autoHide: true,
      zIndex: 99999,
    });
  

    const preloader = document.querySelector('#preloader');

    this.getNovosti()


  },
  methods:
  {

    methodThatForcesUpdate(key){
              $("#preloader").show()
              this.choice1.destroy()
              this.getNovosti()

        
          },


    submitPost() {
      $('#grupe #choices-multiple-remove-button option').each(function (index, element) {
        if ($(element).attr('value') == "Svi") {
          this.createform.svi = true
        }
        this.createform.oznaceni.push($(element).attr('value'))
      }.bind(this));


      axios.post('/api/create_novost/', this.createform)
        .then((res) => {
          console.log(res)
          
         
          this.novi.unshift({id: res.data.id, opis: this.createform.opis, datum: new Date(), svi: this.createform.svi, djelatnici:res.data.grupe, novo:true})
    
          $("#myModal").modal('hide');
          this.$root.toastnoti("Uspjeh!", "Obavijest je napravljena", "uspjeh")

        })
        .catch((error) => {
          this.$root.toastnoti("Greška", error, "greska")
        }).finally(() => {
          //Perform action in always
        });

    },

    izbrisinovostpost() {

      axios.post('/api/izbrisi_zadatak/', this.form)
        .then((res) => {
          $("#deleteConfirmModal").modal('hide');
          $('#vijesti #' + this.form.id).hide();
          this.$root.toastnoti("Uspjeh!", "Obavijest je Izbrisana", "uspjeh")

        })
        .catch((error) => {
          console.log("bb")
        }).finally(() => {
          //Perform action in always
        });
    },
    izbrisinovost(id) {
      $("#deleteConfirmModal").modal('show');
      this.form.id = id

    },
    async getNovosti() {

      await axios
        .get('/api/novosti/')
        .then(response => {

          this.novosti = response.data
          console.log(this.novosti)
    

          this.choice1 = new Choices('#choices-multiple-remove-button', {
          choices: this.novosti.grupe.map(function(grupa) {return { id: grupa.name, value: grupa.name };}),
          removeItemButton: true,
          maxItemCount: 3,
          searchResultLimit: 5,
          renderChoiceLimit: 10
        });
   

      


          const recaptchaScript = document.createElement("script");
          recaptchaScript.setAttribute(
            "src",
            "/js/filterostalo.js"
          );
          document.head.appendChild(recaptchaScript);
          $("#podsjetnik").css("visibility", "visible");
          $("#preloader").hide()
        })
        .catch(error => {
            if (error.response.status === 401) this.$router.push('/korisnik/prijava')
          console.log(error)
        })

    }


  },
  beforeDestroy() {

this.choice1.destroy();


}

}


</script>


<style scoped>
  .staro{
    display: none;
  }
  

@import '../assets/css/filter/style.css';
@import '../assets/css/filter/reset.css';
@import '../assets/css/novosti/novost.css';
</style>

<style scoped>
.inputmodal-dialog2{
      padding:18px ;
      bottom:38% ;
    }
    </style>