<template>
    <Navigacija @reload-parent="methodThatForcesUpdate" yvisina=15 iocon="1">
    
    <template #atag><a></a></template>
      <template #content>

        
      <br><br><br><br><br><br>
      <div style="color:gray" class="text-center cd-fail-message"  v-if="!delivery.gost__broj__lokacija">Dostava ne postoji</div>
      <div class="card"  id="deliverydetail">
                <div class="title">Detalji Narudžbe {{id}}</div>
                <div class="title" v-show="delivery.preuzeo">Preuzeo/la - {{delivery.preuzeo}}</div>
       
      
                
                <div class="info">
                    <div class="row">
                        <div class="col-7">
                            
                            <span id="heading">Od</span><br>
                            <span id="details">Recepcija</span>
                        </div>
                        <div class="col-5 pull-right">
                            <span id="heading">Do</span><br>
                            <span id="details">MH {{delivery.gost__broj__lokacija}}</span>
                        </div>
                 
                    </div>      
                </div>      
                <div class="pricing">
                    <div class="row">
                        <template v-for="i in kosarica">
                       <div class="col-9">
                            <span id="name">{{i[0]}}</span>  
                        </div>
                       
                        <div class="col-3">
                            <span id="price">{{ i[1] }}x</span>
                        </div>
                    </template>
                 
                       
    
              
                    </div>
                </div><br>
         
       
                
                
    
                <div class="footer">
        
                    <button class="btn btn-info" v-show="!delivery.preuzeo__username" @click="PreuzmiIzvrsi('preuzmi')" id="preuzmi">Preuzmi</button>&nbsp;
                    <button class="btn btn-success" v-show="delivery.preuzeo__username==username && !delivery.dostavljeno" @click="PreuzmiIzvrsi('izvrsi')" id="izvrsi">Završi</button>&nbsp;
                    
                   
                </div>
            </div>
    
    
      <div id="preloader">
    
      </div>
      
      </template>
    </Navigacija>
    
    
    
      </template>
    
    
    
    <script>
    import Navigacija from '../components/Navigacija.vue'
    import axios from 'axios'
    export default {
    
      name: 'HomeView',
      components: {
        Navigacija
      },
      data(){
        return {
          form:{
            od:"",
            do:"",
            broj:""
    
          },
          username:'',
          restorani:['paleopark', 'portobus', 'sanpolo'],
          delivery:[],
          kosarica:[],
          id:"",
          predvideno_vrijeme:"",
          razlog_otkazivanja:"",
    
        }
      },
    
      methods:{
    
        otvoriModal(){
            $("#myModal").modal('show');     
        },
    

       async getDelivery() {
        const id = this.$route.params.id
          await axios
            .get('/api/suvenir/suveniridetalji/'+id+'/')
            .then(response => {
              this.delivery = response.data.delivery
              console.log(this.delivery)
              this.kosarica = response.data.kosarica
              this.id = id

    
          
              $("#preloader").hide();
    
            
            })
            .catch(error => {
                if (error.response.status === 401) this.$router.push('/korisnik/prijava')
                $("#preloader").hide();
            })
    
        },
        async PreuzmiIzvrsi(vrsta) {
            var poruka, link;
            if(vrsta=="preuzmi"){
                link = '/api/akcijesuveniri/'+this.$route.params.id+'/preuzmi/'
                poruka = "Preuzeli ste dostavu"
            }
            else if(vrsta=="izvrsi"){
                link = '/api/akcijesuveniri/'+this.$route.params.id+'/izvrsi/'
                poruka = "Izvršili ste dostavu"
            }

          await axios
            .get(link)
            .then(response => {
                this.$root.toastnoti("Uspjeh!", poruka, "uspjeh")
                $("#"+vrsta).hide();
                if(vrsta=="preuzmi") $("#izvrsi").show();

            
            })
            
    
        },
    },
    
      mounted(){
        this.username = localStorage.getItem('username')
    
        this.getDelivery();
    
        const preloader = document.querySelector('#preloader');
    
        
      },
    }
    </script>
    
    
    
    
    
      <style scoped>
     .card{
        margin: auto;
        width: 38%;
        max-width:600px;
        padding: 4vh 0;
        box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-top: 3px solid var(--color-primary);
        border-bottom: 3px solid var(--color-primary);
        border-left: none;
        border-right: none;
    }
    @media(max-width:768px){
        .card{
            width: 90%;
        }
    }
    .title{
        color: var(--color-primary);
        font-weight: 600;
        margin-bottom: 2vh;
        padding: 0 8%;
        font-size: initial;
    }
    #details{
        font-weight: 400;
    }
    .info{
        padding: 5% 8%;
    }
    .info2{
        padding: 0% 8%;
    }
    .info .col-5{
        padding: 0;
    }
    #heading{
        color: grey;
        line-height: 6vh;
    }
    .pricing{
        background-color: #ddd3;
        padding: 2vh 8%;
        font-weight: 400;
        line-height: 2.5;
    }
    .pricing .col-3{
        padding: 0;
    }
    .total{
        padding: 2vh 8%;
        color: var(--color-primary);
        font-weight: bold;
    }
    .total .col-3{
        padding: 0;
    }
    .footer{
        padding: 0 8%;
        font-size: x-small;
        color: black;
    }
    .footer img{
        height: 5vh;
        opacity: 0.2;
    }
    .footer a{
        color: rgb(252, 103, 49);
    }
    .footer .col-10, .col-2{
        display: flex;
        padding: 3vh 0 0;
        align-items: center;
    }
    .footer .row{
        margin: 0;
    }
    #progressbar {
        margin-bottom: 3vh;
        overflow: hidden;
        color: rgb(252, 103, 49);
        padding-left: 0px;
        margin-top: 3vh
    }
    
    #progressbar li {
        list-style-type: none;
        font-size: x-small;
        width: 20%;
        float: left;
        position: relative;
        font-weight: 400;
        color: rgb(160, 159, 159);
    }
    
    #progressbar #step1:before {
        content: "";
        color: rgb(252, 103, 49);
        width: 5px;
        height: 5px;
        margin-left: 0px !important;
        /* padding-left: 11px !important */
    }
    
    #progressbar #step2:before {
        content: "";
        color: #fff;
        width: 5px;
        height: 5px;
        margin-left: 32%;
    }
    
    #progressbar #step3:before {
        content: "";
        color: #fff;
        width: 5px;
        height: 5px;
        margin-right: 32% ; 
        /* padding-right: 11px !important */
    }
    
    #progressbar #step4:before {
        content: "";
        color: #fff;
        width: 5px;
        height: 5px;
        margin-right: 0px !important;
        /* padding-right: 11px !important */
    }
    
    #progressbar li:before {
        line-height: 29px;
        display: block;
        font-size: 12px;
        background: #ddd;
        border-radius: 50%;
        margin: auto;
        z-index: -1;
        margin-bottom: 1vh;
    }
    
    #progressbar li:after {
        content: '';
        height: 2px;
        background: #ddd;
        position: absolute;
        left: 0%;
        right: 0%;
        margin-bottom: 2vh;
        top: 1px;
        z-index: 1;
    }
    .progress-track{
        padding: 0 8%;
    }
    #progressbar li:nth-child(2):after {
        margin-right: auto;
    }
    
    #progressbar li:nth-child(1):after {
        margin: auto;
    }
    
    #progressbar li:nth-child(3):after {
        float: left;
        width: 68%;
    }
    #progressbar li:nth-child(4):after {
        margin-left: auto;
        width: 132%;
    }
    
    #progressbar  li.active{
        color: black;
    }
    
    #progressbar li.active:before,
    #progressbar li.active:after {
        background: #91d16f;
    }
    
      </style>
    
    