
<template>

 <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
<!-- End Header -->


<div style="z-index: 1000;" class="toast-container position-fixed bottom-0 end-0 p-3 d-none">
  <div id="liveToast" class="toast " role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header " style="color:white; font-weight:bold;background: linear-gradient(45deg,#2ed8b6,#59e0c5);">

      <i class="fa fa-pencil rounded  me-2"></i>
      <strong style="cursor: pointer;" id="naslov" class="me-auto">Novi Zadatak</strong>
      <small></small>
      <button type="button"  style="font-weight:bold;" class="btn-close btn-close-white" data-bs-dismiss="toast" aria-label="Close"></button>


    </div>
    <div class="toast-body" style="color:rgb(106, 105, 105);">

      <span id="tekst">Greška </span>
    </div>
  </div>

 
</div>


<router-view/>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import axios from 'axios'
export default{
  
   data() {
    return {
      device:"",
    }
  },
   
beforeCreate() {
    this.$store.commit('initializeStore')
    const token = this.$store.state.token
    if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token     
    } else {
        axios.defaults.headers.common['Authorization'] = ""

    }
  },

 mounted() {
  this.device = Capacitor.getPlatform();
  if(Capacitor.getPlatform() == 'ios'){$('head').append(`<style>.header{padding: 0px 0 !important;padding-top:45px !important;padding-bottom:10px !important;}.mobile-nav-toggle {padding-top:30px;} #navigacijaul {padding-top:50px !important;}.cd-main-content{padding-top: 40px;}.cd-filter-trigger {height:160px !important;}.cd-filter{margin-top: 55px;}.project{padding-top:60px;}</style>`);}
  if(this.device!="web"){
      
    PushNotifications.addListener('pushNotificationActionPerformed', this.handleNotificationClick);
  PushNotifications.removeAllDeliveredNotifications()
  PushNotifications.addListener('pushNotificationReceived',notification => {
      
    window.location.reload()

});

   
      }



 $("#idmenu-button").click(function(){
       $("#idmenu-bar").toggleClass( "open" );
       if($( "#kontrolna-bar" ).hasClass( "open" )){
        $("#kontrolna-bar").toggleClass( "open" );
       }
       
  });

   $("#kontrolna-button").click(function(){
       $("#kontrolna-bar").toggleClass( "open" );
       if($( "#idmenu-button" ).hasClass( "open" )){
        $("#idmenu-button").toggleClass( "open" );
       }
  });
  const selectHeader = document.querySelector('#header');
  if (selectHeader) {
    document.addEventListener('scroll', () => {
      window.scrollY > 20 ? selectHeader.classList.add('sticked') : selectHeader.classList.remove('sticked');
    });
  }
  let navbarlinks = document.querySelectorAll('#navigacija .scrollto');
  function navbarlinksActive() {
    navbarlinks.forEach(navbarlink => {
      if (!navbarlink.hash) return;
      let section = document.querySelector(navbarlink.hash);
      if (!section) return;
      let position = window.scrollY;
      if (navbarlink.hash != '#header') position += 200;
      if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
        navbarlink.classList.add('active');
      } else {
        navbarlink.classList.remove('active');
      }
    })
  }
  window.addEventListener('load', navbarlinksActive);
  document.addEventListener('scroll', navbarlinksActive);
  /**
   * Function to scroll to an element with top ofset
   */
  function scrollto(el) {
    const selectHeader = document.querySelector('#header');
    let offset = 0;
    if (selectHeader.classList.contains('sticked')) {
      offset = document.querySelector('#header.sticked').offsetHeight;
    } else if (selectHeader.hasAttribute('data-scrollto-offset')) {
      offset = selectHeader.offsetHeight - parseInt(selectHeader.getAttribute('data-scrollto-offset'));
    }
    window.scrollTo({
      top: document.querySelector(el).offsetTop - offset,
      behavior: 'smooth'
    });
  }
  /**
   * Fires the scrollto function on click to links .scrollto
   */
  let selectScrollto = document.querySelectorAll('.scrollto');
  selectScrollto.forEach(el => el.addEventListener('click', function(event) {

  }));
  /**
   * Scroll with ofset on page load with hash links in the url
   */
  window.addEventListener('load', () => {
    if (window.location.hash) {
      if (document.querySelector(window.location.hash)) {
        scrollto(window.location.hash);
      }
    }
  });
  /**
   * Mobile nav toggle
   */
  const mobileNavToogle = document.querySelector('.mobile-nav-toggle');
  if (mobileNavToogle) {
    mobileNavToogle.addEventListener('click', function(event) {
      event.preventDefault();
      document.querySelector('body').classList.toggle('mobile-nav-active');
      this.classList.toggle('bi-list');
      this.classList.toggle('bi-x');
    });
  }
  /**
   * Toggle mobile nav dropdowns
   */
  const navDropdowns = document.querySelectorAll('.navigacija .dropdown > a');
  navDropdowns.forEach(el => {
    el.addEventListener('click', function(event) {
      if (document.querySelector('.mobile-nav-active')) {
        event.preventDefault();
        this.classList.toggle('active');
        this.nextElementSibling.classList.toggle('dropdown-active');
        let dropDownIndicator = this.querySelector('.dropdown-indicator');
        dropDownIndicator.classList.toggle('bi-chevron-up');
        dropDownIndicator.classList.toggle('bi-chevron-down');
      }
    })
  });



  
 },
 methods: {
  handleNotificationClick(notification) {
      const page = notification.notification.data.page// get the page from the notification payload
      this.$router.push(page);
     
    },
    

    
  toastnoti(naslov, tijelo, vrsta){

    $('.toast-container').removeClass('d-none');
    const toastLiveExample = document.getElementById('liveToast')
    if(vrsta=="uspjeh"){
      $('#liveToast .toast-header').css('background', 'linear-gradient(45deg,#2ed8b6,#59e0c5)');
    }
    else{
      $('#liveToast .toast-header').css('background', 'linear-gradient(45deg,#FF5370,#ff869a)');
    }
  
    $('#liveToast #tekst').text(tijelo);
    $('#liveToast #naslov').text(naslov);

    const toast = new bootstrap.Toast(toastLiveExample)
    toast.show()

  },

  otvoriform(modalname){
    $('#' + modalname + ' ' +  '.modal-content').removeClass('animate-down').addClass('animate-bottom')
    $("#"+modalname).modal('show');   

  },
  zatvoriform(modalname){

    $('#' + modalname + ' ' +  '.modal-content').removeClass('animate-bottom').addClass('animate-down');
     $("#"+modalname).modal('hide');   


  
  },
 },
 
 }

</script>




<style>



.datepicker-container {
  background-color: #fff;
  direction: ltr;
  font-size: 12px;
  left: 0;
  line-height: 30px;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 210px;
  z-index: -1;
}

.datepicker-container::before,
.datepicker-container::after {
  border: 5px solid transparent;
  content: " ";
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}

.datepicker-dropdown {
  border: 1px solid #ccc;
  -webkit-box-shadow: 0 3px 6px #ccc;
  box-shadow: 0 3px 6px #ccc;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  z-index: 1;
}

.datepicker-inline {
  position: static;
}

.datepicker-top-left,
.datepicker-top-right {
  border-top-color: #39f;
}

.datepicker-top-left::before,
.datepicker-top-left::after,
.datepicker-top-right::before,
.datepicker-top-right::after {
  border-top: 0;
  left: 10px;
  top: -5px;
}

.datepicker-top-left::before,
.datepicker-top-right::before {
  border-bottom-color: #39f;
}

.datepicker-top-left::after,
.datepicker-top-right::after {
  border-bottom-color: #fff;
  top: -4px;
}

.datepicker-bottom-left,
.datepicker-bottom-right {
  border-bottom-color: #39f;
}

.datepicker-bottom-left::before,
.datepicker-bottom-left::after,
.datepicker-bottom-right::before,
.datepicker-bottom-right::after {
  border-bottom: 0;
  bottom: -5px;
  left: 10px;
}

.datepicker-bottom-left::before,
.datepicker-bottom-right::before {
  border-top-color: #39f;
}

.datepicker-bottom-left::after,
.datepicker-bottom-right::after {
  border-top-color: #fff;
  bottom: -4px;
}

.datepicker-top-right::before,
.datepicker-top-right::after,
.datepicker-bottom-right::before,
.datepicker-bottom-right::after {
  left: auto;
  right: 10px;
}

.datepicker-panel > ul {
  margin: 0;
  padding: 0;
  width: 102%;
}

.datepicker-panel > ul::before,
.datepicker-panel > ul::after {
  content: " ";
  display: table;
}

.datepicker-panel > ul::after {
  clear: both;
}

.datepicker-panel > ul > li {
  background-color: #fff;
  cursor: pointer;
  float: left;
  height: 30px;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 30px;
}

.datepicker-panel > ul > li:hover {
  background-color: rgb(229, 242, 255);
}

.datepicker-panel > ul > li.muted,
.datepicker-panel > ul > li.muted:hover {
  color: #999;
}

.datepicker-panel > ul > li.highlighted {
  background-color: rgb(229, 242, 255);
}

.datepicker-panel > ul > li.highlighted:hover {
  background-color: rgb(204, 229, 255);
}

.datepicker-panel > ul > li.picked,
.datepicker-panel > ul > li.picked:hover {
  color: #39f;
}

.datepicker-panel > ul > li.disabled,
.datepicker-panel > ul > li.disabled:hover {
  background-color: #fff;
  color: #ccc;
  cursor: default;
}

.datepicker-panel > ul > li.disabled.highlighted,
.datepicker-panel > ul > li.disabled:hover.highlighted {
  background-color: rgb(229, 242, 255);
}

.datepicker-panel > ul > li[data-view="years prev"],
.datepicker-panel > ul > li[data-view="year prev"],
.datepicker-panel > ul > li[data-view="month prev"],
.datepicker-panel > ul > li[data-view="years next"],
.datepicker-panel > ul > li[data-view="year next"],
.datepicker-panel > ul > li[data-view="month next"],
.datepicker-panel > ul > li[data-view="next"] {
  font-size: 18px;
}

.datepicker-panel > ul > li[data-view="years current"],
.datepicker-panel > ul > li[data-view="year current"],
.datepicker-panel > ul > li[data-view="month current"] {
  width: 150px;
}

.datepicker-panel > ul[data-view="years"] > li,
.datepicker-panel > ul[data-view="months"] > li {
  height: 52.5px;
  line-height: 52.5px;
  width: 52.5px;
}

.datepicker-panel > ul[data-view="week"] > li,
.datepicker-panel > ul[data-view="week"] > li:hover {
  background-color: #fff;
  cursor: default;
}

.datepicker-hide {
  display: none;
}






</style>