
<template>

  <link href="https://fonts.googleapis.com/css?family=Montserrat&display=swap" rel="stylesheet">
  
    <Navigacija @reload-parent="methodThatForcesUpdate" yvisina=15 iocon="1">
      <template #atag><a class="btn-getstarted scrollto" style="color:White;cursor: pointer;" id="changevrijeme" @click="changevrijeme()">Danas</a></template>
      <template #content>
        
  
  <br><br><br>
  
  <div class="container grey-bg container-fluid" id="kartice">
  
    <section id="minimal-statistics">
  
      
      <div class="row">
        <div class="col-xl-3 col-sm-6 col-12" v-for="i in dostave.dostave" :class="checkvrijeme(i.zavrijeme)"> 
        <a>
          <div class="card bg-c-blue order-card" >
              <a style="color:white;"  @click="$router.push('/korisnik/dostave/'+i.id+'/')">
                <div class="card-block">
                  <h6 class="m-b-20">Narudžba {{i.id}}  |   {{ i.gost__broj__lokacija }}</h6>
                  <h2 class="text-right">
                    <i class="fa fa-clock warning font-large-2 float-left" style="padding-right:10px;color:orange;" v-if="i.buduce" ></i>
                    <i :class="ikonaFunkcija(i.kategorija)" style="color:white;"></i>
                    
                    &nbsp;<span>{{new Date(i.zavrijeme).toLocaleString('en-UK', {month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit'}).replace(',', '')}}</span>
                    
                  </h2>
                </div>
                </a>
              </div>
          </a>
        </div>
  
        <div class="col-xl-3 col-sm-6 col-12" v-if="dostave.dostave==0"> 
        <a>
          <div class="card bg-c-gray order-card">
              <a style="color:white;" >
                <div class="card-block">
                  <h6 class="m-b-20">...</h6>
                  <h2 class="text-right">
                 
                    <i class="fa fa-clock warning font-large-2 float-left" style="padding-right:10px;color:white;font-size: 50px;"></i> &nbsp;
                    <span>Prazno</span>
  
                    
                  </h2>
                </div>
                </a>
              </div>
          </a>
        </div>
  
  
        
        
      </div>
    <hr>
      <div class="row" v-if="dostave.dostave">
      
        <div class="col-xl-3 col-sm-6 col-12"  v-if="dostave.dostave" style="padding: 0px 20px 0px 20px;"> 
        <a>
          <div class="card bg-c-yellow order-card">
              <a style="color:white;" >
                <div class="card-block">
                  <h6 class="m-b-20">{{dostave.dostave.length}} Narudžbe</h6>
                  <h2 class="text-right">
                 
                    <i class="fa-solid fa-bowl-food warning font-large-2 float-left" style="padding-right:10px;color:white;font-size: 50px;"></i> &nbsp;
                    <span style="font-size: 30px;">Sve narudžbe</span>
  
                    
                  </h2>
                </div>
                </a>
              </div>
          </a>
        </div>
  
      
        <div class="col-xl-3 col-sm-6 col-12"  v-if="dostave.dostave" style="padding: 0px 20px 0px 20px;"> 
        <a>
          <div class="card bg-c-yellow order-card">
              <a style="color:white;" >
                <div class="card-block">
                  <h6 class="m-b-20">{{buduceCount}}  Budućih</h6>
                  <h2 class="text-right">
                 
                    <i class="fa-solid fa-calendar warning font-large-2 float-left" style="padding-right:10px;color:white;font-size: 50px;"></i> &nbsp;
                    <span style="font-size: 30px;">Buduće narudžbe</span>
  
                    
                  </h2>
                </div>
                </a>
              </div>
          </a>
        </div>
  
  
  
       
      </div>
  
      <div class="row">
        
  
        
      </div>
    </section>
    
  <div id="preloader"></div>
  </div>
  
      </template>
      
  </Navigacija>
  
  
  
  
  </template>
  
  
  <script>
  import Navigacija from '../components/Navigacija.vue'
  import axios from 'axios'
  export default{
    components: {
      Navigacija
    },
  
      data() {
      return {
          dostave:[],
          intervalId1:null,
      }
    },
    beforeRouteLeave(to, from, next) {
    // Stop polling when leaving the route
    clearInterval(this.intervalId1);
    next();
  },
    computed: {
      sadaCount() {
        const today = new Date();  
        return this.dostave.dostave.filter(item => this.isSameDay(new Date(item.zavrijeme), today)).length;
      
      },
      buduceCount() {
        const today = new Date();  
        return this.dostave.dostave.filter(item => this.isFutureDateByDays(new Date(item.zavrijeme), today)).length;
      },
    },
  
    mounted(){
      
      this.DostaveSve()

      if (this.intervalId1) {
    clearInterval(this.intervalId1);
  }

      this.intervalId1 = setInterval(() => {
        this.DostaveSve()
      }, 10000);


  
  
    },
  
    methods:{
      async oznaci(id){
        const itemToUpdate = this.dostave.dostave.find(item => item.id === id);
        if (itemToUpdate) {
          itemToUpdate.oznaceno = !itemToUpdate.oznaceno ;
        }
        var status = 0
        if(itemToUpdate.oznaceno==true) status=1
        else if(itemToUpdate.oznaceno==true) status=0
        await axios
          .get('/api/oznacidostavu/restoran/'+id+'/'+status)
          .then(response => {
    
  
          })
          .catch(error => {
            console.log(error)
              if (error.response.status === 401) this.$router.push('/korisnik/prijava')
    
          })
      },
      isSameDay(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }, isFutureDateByDays(date1, date2) {
    const date1WithoutTime = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const date2WithoutTime = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
  
    console.log(date1WithoutTime)
  
    return date1WithoutTime > date2WithoutTime;
  },
  
      checkvrijeme(vrijeme){
        var givenDate = new Date(vrijeme);
      var today = new Date();
  
      if (givenDate.toDateString() === today.toDateString()) {
        return "danas"
      } else {
        return "buduce"
      }
  
  
      },
  
      changevrijeme(){
        var tekst = $("#changevrijeme").text()
  
      if (tekst == 'Danas') {
        $('#changevrijeme').text('Buduće');
        $(".danas").hide();
        $(".buduce").show();
  
      } else if (tekst == 'Buduće') {
        $('#changevrijeme').text('Danas');
        $(".buduce").hide();
        $(".danas").show();
  
      }
      },
  
      ikonaFunkcija(status) {
        console.log(status)
        switch (status) {  
          
        case 'molpicio': return 'fa-solid fa-utensils font-large-2 float-left';break;
  
        default: return 'icon-user primary font-large-2 float-left';
      }
  },
  
  
      async DostaveSve() {
        const username = localStorage.getItem('username')
  
        await axios
          .get('/api/restorandostave/'+username)
          .then(response => {
            this.dostave = response.data
     
            $('#preloader').hide()
  
              
      
  
          })
          .catch(error => {
            console.log(error)
              if (error.response.status === 401) this.$router.push('/korisnik/prijava')
    
          })
  
      },
  
  
  }
  }
  </script>
  
  <style>
  
  .buduce{
    display: none;
  }</style>
  
  
  <style>
  
  .order-card {
      color: #fff;
  }
  
  .bg-c-blue {
      background: linear-gradient(45deg,#4099ff,#73b4ff);
  }
  
  .bg-c-green {
      background: linear-gradient(45deg,#2ed8b6,#59e0c5);
  }
  
  .bg-c-yellow {
      background: linear-gradient(45deg,#FFB64D,#ffcb80);
  }
  
  .bg-c-pink {
      background: linear-gradient(45deg,#FF5370,#ff869a);
  }
  .bg-c-gray {
      background: linear-gradient(45deg,#d3d3d3,#d3d3d3);
  }
  
  
  
  .card {
      border-radius: 5px;
      -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
      box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
      border: none;
      margin-bottom: 15px;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
  }
  
  .card .card-block {
      padding: 25px;
  }
  
  .order-card i {
      font-size: 26px;
  }
  
  .f-left {
      float: left;
  }
  
  .f-right {
      float: right;
  }
  
  
  </style>
  
  <style scoped>
  
  .project {  
  
      max-width: 100%;  
  
  }  
  
  
  .submitbtn{
      font-weight: 400;
      display: inline-block;
      padding: 10px 28px;
      border-radius: 4px;
      transition: 0.5s;
      color: var(--color-white);
      background: var(--color-primary);
      font-family: var(--font-secondary);
  
  }
  
  
  </style>
  