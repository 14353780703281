<template>
    <Navigation @reload-parent="methodThatForcesUpdate" yvisina=0 iocon="1">
        
        <template #atag><a></a></template>
      <template #content>

        
    <section id="hero-animated" class="hero-animated d-flex align-items-center">
        <div class="container d-flex flex-column justify-content-center align-items-center text-center position-relative"
            data-aos="zoom-out">


            <h2><span>{{anketa.naslov}}</span></h2>
            <p>{{anketa.opis}}</p>

        </div>


    </section>
    <section id="featured-services" class="featured-services">
        <div class="container" style="padding:30px;">
            <template v-for="i in anketa.pitanja">
                    <label style="padding-bottom: 10px;color:#17a2b8;opacity: 0.9;font-size: 20px;font-weight: bold;">* {{i.pitanje}}</label>
                    <textarea type="text" class="form-control" placeholder="Odgovor" v-if="i.opis" v-model="form[i.id]"></textarea>
                    <template v-if="i.ocjena">
        <br>
                    <img src="../assets/1ocjena.svg" :id="'ocjena1'+i.id" :class="'ocjena'+i.id" style="width:55px;height:55px;opacity: 0.2;cursor: pointer;padding-left: 5px;color:purple" @click="ocjenaklik(i.id, 1)">
                    <img src="../assets/2ocjena.svg" :id="'ocjena2'+i.id" :class="'ocjena'+i.id" style="width:55px;height:55px;opacity: 0.2;cursor: pointer;padding-left: 5px;" @click="ocjenaklik(i.id, 2)">
                    <img src="../assets/3ocjena.svg" :id="'ocjena3'+i.id" :class="'ocjena'+i.id" style="width:55px;height:55px;opacity: 0.2;cursor: pointer;padding-left: 5px;" @click="ocjenaklik(i.id, 3)">
                    <img src="../assets/4ocjena.svg" :id="'ocjena4'+i.id" :class="'ocjena'+i.id" style="width:55px;height:55px;opacity: 0.2;cursor: pointer;padding-left: 5px;" @click="ocjenaklik(i.id, 4)">
                    <img src="../assets/5ocjena.svg" :id="'ocjena5'+i.id" :class="'ocjena'+i.id" style="width:55px;height:55px;opacity: 0.2;cursor: pointer;padding-left: 5px;" @click="ocjenaklik(i.id, 5)">
        
                    </template>
                    <br><br>
                    
                </template>

                <button class="btn btn-primary" style="border:none;" @click="odgovor()">Pošalji odgovore</button>
           





        </div>
    </section>

    <div id="preloader"></div>
    
      </template>
    </Navigation>

</template>
        
        
<script>

import axios from 'axios'
import Navigation from '../components/Navigacija.vue'
export default {

    name: 'Info',
    components: {
        Navigation
    },

    data() {
        return {
            id: this.$route.params.id,
            anketa: [],
            form:{
                id:this.$route.params.id,

                
            }
        }
    },

    mounted() {
       
        this.getAnketa()
        
    },


    methods: {
        ocjenaklik(id, ocjena){
            console.log(ocjena)
            this.form["ocjena_"+id] = ocjena
            $(".ocjena"+id).css('opacity', 0.2)
            $("#ocjena"+ocjena+id).css('opacity', 1)

        },
        methodThatForcesUpdate() {
            this.getAnkete()
        },
        odgovor(){
            axios.post('/anketa/odgovor/', this.form)
            .then((res) => {
    
              this.$root.toastnoti("Uspjeh", "Odgovor poslan" , "uspjeh")
              this.$router.push("/ankete/")
                    
              })
            .catch((error) => {
              console.log(error)
              this.$root.toastnoti("Greška", "Greška", "error")
             
            })
        },  
        async getAnketa() {

            await axios
                .get('/anketa/anketa/' + this.id)
                .then(response => {
                    this.anketa = response.data
                    console.log(this.anketa)
                    $('#preloader').remove();

                })
                .catch(error => {
                    this.$root.toastnoti(this.$t('error'), error, "greska")

                })

        },
    }
}
</script>
        
<style scoped>
.hero-animated {
    padding: 50px 0 0px;
    min-height: 30vh;
}

section {
    padding: 0px 0;

}
</style>
    
    
        
<style scoped>
.ag-format-container {
    width: 1142px;
    margin: 0 auto;
}


body {
    background-color: #000;
}

.ag-courses_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

}

.ag-courses_item {
    -ms-flex-preferred-size: calc(33.33333% - 30px);
    flex-basis: calc(33.33333% - 30px);

    margin: 0 15px 30px;

    overflow: hidden;

    border-radius: 28px;
}

.ag-courses-item_link {
    display: block;
    padding: 30px 20px;
    background-color: #17a2b8;
    opacity: 0.5;

    overflow: hidden;

    position: relative;
}

.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
    text-decoration: none;
    color: #FFF;
}

.ag-courses-item_link:hover .ag-courses-item_bg {
    -webkit-transform: scale(10);
    -ms-transform: scale(10);
    transform: scale(10);
}

.ag-courses-item_title {
    min-height: 87px;
    margin: 0 0 25px;

    overflow: hidden;

    font-weight: bold;
    font-size: 30px;
    color: #FFF;

    z-index: 2;
    position: relative;
}

.ag-courses-item_date-box {
    font-size: 18px;
    color: #FFF;

    z-index: 2;
    position: relative;
}

.ag-courses-item_date {
    font-weight: bold;
    color: #f9b234;

    -webkit-transition: color .5s ease;
    -o-transition: color .5s ease;
    transition: color .5s ease
}

.ag-courses-item_bg {
    height: 128px;
    width: 128px;
    background-color: #f9b234;

    z-index: 1;
    position: absolute;
    top: -75px;
    right: -75px;

    border-radius: 50%;

    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}

.ag-courses_item:nth-child(2n) .ag-courses-item_bg {
    background-color: #3ecd5e;
}

.ag-courses_item:nth-child(3n) .ag-courses-item_bg {
    background-color: #e44002;
}

.ag-courses_item:nth-child(4n) .ag-courses-item_bg {
    background-color: #952aff;
}

.ag-courses_item:nth-child(5n) .ag-courses-item_bg {
    background-color: #cd3e94;
}

.ag-courses_item:nth-child(6n) .ag-courses-item_bg {
    background-color: #4c49ea;
}



@media only screen and (max-width: 979px) {
    .ag-courses_item {
        -ms-flex-preferred-size: calc(50% - 30px);
        flex-basis: calc(50% - 30px);
    }

    .ag-courses-item_title {
        font-size: 24px;
    }
}

@media only screen and (max-width: 767px) {
    .ag-format-container {
        width: 96%;
    }

}

@media only screen and (max-width: 639px) {
    .ag-courses_item {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }

    .ag-courses-item_title {
        min-height: 72px;
        line-height: 1;

        font-size: 24px;
    }

    .ag-courses-item_link {
        padding: 22px 40px;
    }

    .ag-courses-item_date-box {
        font-size: 16px;
    }
}</style>