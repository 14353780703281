<template>


<Navigacija @reload-parent="methodThatForcesUpdate" yvisina=15 iocon="1">
  <template #atag><a class="btn-getstarted scrollto" style="border-radius: 20px;background-color: rgb(249, 243, 243);padding: 8px 15px;cursor: pointer;" @click="this.$root.otvoriform('PrijaviModal')"><i class="fa fa-plus-circle" style="color:rgb(14, 162, 189);font-size: 25px;"></i></a>
</template>
      <template #content>

        <div id="baseApp">
          
<br><div style="padding-top:17px;"></div>


<main class="cd-main-content">
  <div class="cd-tab-filter-wrapper">
    <div class="cd-tab-filter">
      <ul class="cd-filters">
        <li class="placeholder"> 
          <a data-type="all" href="#0">Sve</a> <!-- selected option on mobile -->
        </li> 
        <li class="filter"><a class="selected" href="#0" data-type="all">Sve</a></li> 
        <li class="filter" data-filter=".izgubljeno"><a href="#0" data-type="False">Izgubljeno</a></li>
        <li class="filter" data-filter=".pronadeno"><a href="#0" data-type="True">Pronađeno</a></li>
        
    
      </ul> 
    </div> 
  </div> 


  <section class="cd-gallery">
                <div class="row row--top-20">
                <div class="col-md-12">
                    <div class="table-container">
                      <table class="table">
                        <thead class="table__thead">
                          <tr v-if="data.length">
                           
                            <th class="table__th"></th>
                            <th class="table__th">Vrsta</th>
                            <th class="table__th">Opis</th>
                            <th class="table__th">Status</th>
                            <th class="table__th">Lokacija</th>
                            
                            <th class="table__th">Podsjetnik</th>
                            <th class="table__th">Uredi</th>
                     
                          </tr>
                        
                        </thead>
                        <tbody class="table__tbody">

                          <tr  :id="'pr'+predmet.id" class="table-row table-row--chris all" v-for="predmet in novi">
                     
                     <td class="table-row__td">
                     
                       <p style="color:gray">{{ predmet.zadatum.slice(8) + '.' + predmet.zadatum.slice(5, 7) }}</p>
                
                   </td>
                     <td class="table-row__td">
                    
                         <p style="font-weight: bold;" class="status--blue">{{predmet.vrsta_predmeta__ime}}</p>
                     </td>
                    
                     <td data-column="Opis" class="table-row__td">
                       <div class="">
                         <p class="table-row__policy">{{predmet.opis}}</p>
                         
                       </div>                
                     </td>
                     <td data-column="Status" class="table-row__td">
                      
                       <p class="table-row__p-status status--green status" v-if="predmet.status">Pronađeno</p>
                       <p class="table-row__p-status status--red status" v-else>Izgubljeno</p>
                  
                       
                       
                     </td>
                     <td data-column="Lokacija" class="table-row__td">
                       {{predmet.prostor__lokacija}}
                     </td>
                  
                     <td data-column="Podsjetnik" class="table-row__td">
                       

                       <p class="table-row__progress status--green status" v-if="predmet.podsjetnik">Da</p>
                       <p class="table-row__progress status--red status" v-else>Ne</p>
             
                    
                     </td>
                   
       <td class="table-row__td">
                      
                 
         
         <svg  @click="otvoriPodsjetnik(predmet.id, predmet.podsjetnik)" version="1.1" class="table-row__edit" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve" data-toggle="tooltip" data-placement="bottom" title="Edit"><g>	<g>		<path d="M496.063,62.299l-46.396-46.4c-21.2-21.199-55.69-21.198-76.888,0l-18.16,18.161l123.284,123.294l18.16-18.161    C517.311,117.944,517.314,83.55,496.063,62.299z" style="fill: rgb(1, 185, 209);"></path>	</g></g><g>	<g>
           <path d="M22.012,376.747L0.251,494.268c-0.899,4.857,0.649,9.846,4.142,13.339c3.497,3.497,8.487,5.042,13.338,4.143    l117.512-21.763L22.012,376.747z" style="fill: rgb(1, 185, 209);"></path>	</g></g><g>	<g>		<polygon points="333.407,55.274 38.198,350.506 161.482,473.799 456.691,178.568   " style="fill: rgb(1, 185, 209);"></polygon>	</g></g><g></g><g></g><g></g>
       <g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                       
                       <svg  @click="deleteModal(predmet.id)" data-toggle="tooltip" data-placement="bottom" title="Delete" version="1.1" class="table-row__bin" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g>	<g>		<path d="M436,60h-90V45c0-24.813-20.187-45-45-45h-90c-24.813,0-45,20.187-45,45v15H76c-24.813,0-45,20.187-45,45v30    c0,8.284,6.716,15,15,15h16.183L88.57,470.945c0.003,0.043,0.007,0.086,0.011,0.129C90.703,494.406,109.97,512,133.396,512    h245.207c23.427,0,42.693-17.594,44.815-40.926c0.004-0.043,0.008-0.086,0.011-0.129L449.817,150H466c8.284,0,15-6.716,15-15v-30    C481,80.187,460.813,60,436,60z M196,45c0-8.271,6.729-15,15-15h90c8.271,0,15,6.729,15,15v15H196V45z M393.537,468.408    c-0.729,7.753-7.142,13.592-14.934,13.592H133.396c-7.792,0-14.204-5.839-14.934-13.592L92.284,150h327.432L393.537,468.408z     M451,120h-15H76H61v-15c0-8.271,6.729-15,15-15h105h150h105c8.271,0,15,6.729,15,15V120z" style="fill: rgb(158, 171, 180);"></path>	</g></g><g>	<g>		<path d="M256,180c-8.284,0-15,6.716-15,15v212c0,8.284,6.716,15,15,15s15-6.716,15-15V195C271,186.716,264.284,180,256,180z" style="fill: rgb(158, 171, 180);"></path>	</g></g><g>	<g>		<path d="M346,180c-8.284,0-15,6.716-15,15v212c0,8.284,6.716,15,15,15s15-6.716,15-15V195C361,186.716,354.284,180,346,180z" style="fill: rgb(158, 171, 180);"></path>	</g></g><g>	<g>		<path d="M166,180c-8.284,0-15,6.716-15,15v212c0,8.284,6.716,15,15,15s15-6.716,15-15V195C181,186.716,174.284,180,166,180z" style="fill: rgb(158, 171, 180);"></path>	</g></g><g></g><g></g><g></g><g></g><g></g><g></g>
       <g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                       </svg>  
     
                      
                  

       </td>
    
</tr>       

                          
                      <tr  :id="'pr'+predmet.id" class="table-row table-row--chris mix all" :class="predmet.vrsta_predmeta__ime, predmet.prostor__zona, predmet.status === true ? 'pronadeno' : 'izgubljeno'"  v-for="predmet in data.predmeti">
                     
                        <td class="table-row__td">
                        
                          <p style="color:gray">{{ predmet.zadatum.slice(8) + '.' + predmet.zadatum.slice(5, 7) }}</p>
                   
                      </td>
                        <td class="table-row__td">
                       
                            <p style="font-weight: bold;" class="status--blue">{{predmet.vrsta_predmeta__ime}}</p>
                        </td>
                       
                        <td data-column="Opis" class="table-row__td">
                          <div class="">
                            <p class="table-row__policy">{{predmet.opis}}</p>
                            
                          </div>                
                        </td>
                        <td data-column="Status" class="table-row__td">
                         
                          <p class="table-row__p-status status--green status" v-if="predmet.status">Pronađeno</p>
                          <p class="table-row__p-status status--red status" v-else>Izgubljeno</p>
                     
                          
                          
                        </td>
                        <td data-column="Lokacija" class="table-row__td">
                          {{predmet.prostor__lokacija}}
                        </td>
                     
                        <td data-column="Podsjetnik" class="table-row__td">
                          
  
                          <p class="table-row__progress status--green status" v-if="predmet.podsjetnik">Da</p>
                          <p class="table-row__progress status--red status" v-else>Ne</p>
                
                       
                        </td>
                      
          <td class="table-row__td">
                         
                    
            
            <svg  @click="otvoriPodsjetnik(predmet.id, predmet.podsjetnik)" version="1.1" class="table-row__edit" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve" data-toggle="tooltip" data-placement="bottom" title="Edit"><g>	<g>		<path d="M496.063,62.299l-46.396-46.4c-21.2-21.199-55.69-21.198-76.888,0l-18.16,18.161l123.284,123.294l18.16-18.161    C517.311,117.944,517.314,83.55,496.063,62.299z" style="fill: rgb(1, 185, 209);"></path>	</g></g><g>	<g>
              <path d="M22.012,376.747L0.251,494.268c-0.899,4.857,0.649,9.846,4.142,13.339c3.497,3.497,8.487,5.042,13.338,4.143    l117.512-21.763L22.012,376.747z" style="fill: rgb(1, 185, 209);"></path>	</g></g><g>	<g>		<polygon points="333.407,55.274 38.198,350.506 161.482,473.799 456.691,178.568   " style="fill: rgb(1, 185, 209);"></polygon>	</g></g><g></g><g></g><g></g>
          <g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                          
                          <svg  @click="deleteModal(predmet.id)" data-toggle="tooltip" data-placement="bottom" title="Delete" version="1.1" class="table-row__bin" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g>	<g>		<path d="M436,60h-90V45c0-24.813-20.187-45-45-45h-90c-24.813,0-45,20.187-45,45v15H76c-24.813,0-45,20.187-45,45v30    c0,8.284,6.716,15,15,15h16.183L88.57,470.945c0.003,0.043,0.007,0.086,0.011,0.129C90.703,494.406,109.97,512,133.396,512    h245.207c23.427,0,42.693-17.594,44.815-40.926c0.004-0.043,0.008-0.086,0.011-0.129L449.817,150H466c8.284,0,15-6.716,15-15v-30    C481,80.187,460.813,60,436,60z M196,45c0-8.271,6.729-15,15-15h90c8.271,0,15,6.729,15,15v15H196V45z M393.537,468.408    c-0.729,7.753-7.142,13.592-14.934,13.592H133.396c-7.792,0-14.204-5.839-14.934-13.592L92.284,150h327.432L393.537,468.408z     M451,120h-15H76H61v-15c0-8.271,6.729-15,15-15h105h150h105c8.271,0,15,6.729,15,15V120z" style="fill: rgb(158, 171, 180);"></path>	</g></g><g>	<g>		<path d="M256,180c-8.284,0-15,6.716-15,15v212c0,8.284,6.716,15,15,15s15-6.716,15-15V195C271,186.716,264.284,180,256,180z" style="fill: rgb(158, 171, 180);"></path>	</g></g><g>	<g>		<path d="M346,180c-8.284,0-15,6.716-15,15v212c0,8.284,6.716,15,15,15s15-6.716,15-15V195C361,186.716,354.284,180,346,180z" style="fill: rgb(158, 171, 180);"></path>	</g></g><g>	<g>		<path d="M166,180c-8.284,0-15,6.716-15,15v212c0,8.284,6.716,15,15,15s15-6.716,15-15V195C181,186.716,174.284,180,166,180z" style="fill: rgb(158, 171, 180);"></path>	</g></g><g></g><g></g><g></g><g></g><g></g><g></g>
          <g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                          </svg>  
        
                         
                     

          </td>
       
</tr>        
                   

             
            </tbody>
        </table>
      </div>
    </div>
  </div>
 
<div style="color:gray" class="cd-fail-message">Pretraživanje nije dalo rezultata</div>
</section> <!-- cd-gallery -->

<div class="cd-filter">
  <form>
    <div class="cd-filter-block">
      <h4>Pretraži</h4>
      
      <div class="cd-filter-content">
        <input type="search" placeholder="Bilo što">
      </div> <!-- cd-filter-content -->
    </div> <!-- cd-filter-block -->

    <div class="cd-filter-block">
      <h4>Zona</h4>

      <ul class="cd-filter-content cd-filters list">
        <li>
          <input class="filter" data-filter=".sanpolo" type="checkbox" id="checkbox1">
            <label class="checkbox-label" for="checkbox1">San Polo</label>
        </li>

        <li>
          <input class="filter" data-filter=".colona" type="checkbox" id="checkbox2">
          <label class="checkbox-label" for="checkbox2">Colona</label>
        </li>

       

  
      </ul> <!-- cd-filter-content -->
    </div> <!-- cd-filter-block -->

    <div class="cd-filter-block">
      <h4>Vrsta Predmeta</h4>
      
      <div class="cd-filter-content">
        <div class="cd-select cd-filters">
          <select class="filter" name="selectThis" id="selectThis">
            <option value="">Izaberi Vrstu</option>
     
            <option  v-for="vrstap in data.vrste" :value="'.'+vrstap[0]">{{vrstap[0]}}</option>
 

          </select>
        </div> <!-- cd-select -->
      </div> <!-- cd-filter-content -->
    </div> <!-- cd-filter-block -->

   

   
  </form>

  <a href="#0" class="cd-close">Close</a>
</div> <!-- cd-filter -->

<a href="#0" class="cd-filter-trigger">Filteri</a>
</main> <!-- cd-main-content -->
 



</div>



      </template>



</Navigacija>
<div id="preloader"></div>

<div id="podsjetnikModal" class="modal fade" tabindex="-1" style="padding-top:50px;">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header" >
              <h5  class="modal-title modelheading"></h5><br>
              <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('podsjetnikModal')"  ></i>
          </div>
        <form v-on:submit.prevent="podsjetnik">
          <div class="modal-body">
   
        
            <input type="search" id="email" class="form-control" placeholder="Email gosta ..." v-model="formpos.email"/>
            
            
          
          </div>
          <div class="modal-footer d-flex justify-content-center">
            


              <button type="submit" id="submit" class="btn btn-info f-left modalbutton" data-dismiss="modal">Spremi</button>
            <div style="padding-top:10%;" class="prazanprostor"></div>

              
          </div>
        </form>
      </div>
  </div>
</div>


<div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h4 class="modal-title modelheading" id="deleteLabel">Izbriši predmet</h4>
              <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('deleteConfirmModal')"  ></i>
          </div>
      
          <div class="modal-footer d-flex justify-content-center">

  
              <button type="button" id="submit" class="btn btn-info f-left modalbutton" data-dismiss="modal" @click="deletePredmetPost">Potvrdi</button>
            <div style="padding-top:10%;" class="prazanprostor"></div>
          </div>
      </div>
  </div>
</div>


<div id="PrijaviModal" class="modal fade" tabindex="-1" style="padding-top: 90px;">
  <div class="modal-dialog inputmodal-dialog2">
      <div class="modal-content inputmodal-content">
          <div class="modal-header">
              <h5 class="modal-title modelheading">Prijavi izgubljeni/pronađeni predmet</h5>
              <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('PrijaviModal')"  ></i>
          </div>
        <form v-on:submit.prevent="createPredmet">

          <div class="modal-body">

            <label for="message" class="col-form-label" style="color:gray;font-size: .9rem;font-weight: bold;">Predmet *</label>


          <div class="multivrsta col-md-12 col-sm-12" id="vrsta" style="padding-bottom:20px;"> <select id="choices-multiple-remove-button1" class="inputVrsta" placeholder="Označi djelatnike" v-model="form.vrste">

         
              
                </select> </div>
                <label for="message" class="col-form-label" style="color:gray;font-size: .9rem;font-weight: bold;">Lokacija *</label>
                  <div class="col-md-12 col-sm-12" id="lokacija"> <select id="choices-multiple-remove-button" placeholder="Označi djelatnike" >
                 
                                </select> 
                    </div><br>


              <div class="form-group">
           
              <input type="text" class="form-control" id="post-opis" placeholder="Opis..." v-model="formpost.opis">
              </div> <br>
              <div class="form-check form-switch">
                <label class="form-check-label" for="flexSwitchCheckDefault" style="margin-top:0.25em;">Pronađeno?</label>
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="formpost.pronadeno">
    
  </div>


          </div>
          <div class="modal-footer d-flex justify-content-center">
                          
                        
                        
                              <button type="submit" id="submit" class="btn btn-info f-left modalbutton">Potvrdi</button>
            <div style="padding-top:10%;" class="prazanprostor"></div>
          </div>
         




      </form>
  </div>
</div>
</div>

</template>

<style scoped>

.inputmodal-dialog2{
      padding:18px ;
      bottom:35% ;
    }

  @import '../assets/css/predmeti/predmet.css';

  .cd-main-content {
    background: #fafbfc !important;
  }

  @media screen and (min-width: 800px) {
   .cd-gallery{
    padding: 50px;
   }
}

</style>

<script>
import Navigacija from '../components/Navigacija.vue'
import axios from 'axios'

export default{
  components: {
    Navigacija
  },

  data() {
    return {
      choice1:null,
      choice2:null,
      data: [],
      novi:[],
      form:{
        id:"",
        vrsta:"predmet",
      },
      formpos:{
        email:"",
        vrsta:"predmet",
      },

      formpost:{
        vrsta:"",
        lokacija:"",
        opis:"",
        pronadeno: true,

      },

      vrste:[],
      lokacije:[],
      pokazatelj:false,
      sdcreen_siz:"",
      boja:"",
      st:"",

    }
  },

  mounted(){
    $('[data-toggle="datepicker"]').datepicker({
     autoHide: true,
     zIndex: 99999,
  });

 const preloader = document.querySelector('#preloader');



	

  

      this.getPredmeti()

      	

    
      

   },
methods:
{

  methodThatForcesUpdate(key){
   
              this.choice1.destroy()
              this.choice2.destroy()
          
              this.getPredmeti()

        
          },




  createPredmet(){

            this.formpost.vrsta = $('#vrsta .choices__item ').attr('data-custom-properties')
            this.formpost.lokacija = $('#lokacija .choices__item ').attr('data-custom-properties')
        
            
            
            axios.post('/api/create_predmet/', this.formpost)
                 .then((res) => {
                  
           
                      this.novi.unshift({vrsta_predmeta__ime: this.data.vrste.find(item => item[1] === parseInt(this.formpost.vrsta))[0],
                      prostor__lokacija: this.data.lokacije.find(item => item[1] === parseInt(this.formpost.lokacija))[0],
                      prostor__zona: 'sanpolo', zadatum: "sada", opis: this.formpost.opis, podjetnik:"", status:this.formpost.pronadeno, id:res.data.id}); // Use unshift to add the new item to the beginning

              
                  
         
                 ;
             
  
                

            $("#myModal").modal('hide');
              $("#PrijaviModal").modal('hide');  

                     this.$root.toastnoti("Uspjeh!", "Zadatak je napravljen", "uspjeh")

                     
                 })
                 .catch((error) => {
                    console.log(error)
                 }).finally(() => {
                     //Perform action in always
                 });
                 
              
                 
      },



   podsjetnik(){
    axios.post('/api/podsjetnik/', this.formpos)
                 .then((res) => {

                      $("#podsjetnikModal").modal('hide');
                      this.$root.toastnoti("Uspjeh!", "Podsejtnik je dodan", "uspjeh")
                      this.formpos.email = ""
                      $('#email').attr("placeholder", ""); 
                 })
                 .catch((error) => {
                    console.log("bb")
                 }).finally(() => {
                     //Perform action in always
                 });
  },

   otvoriPodsjetnik(id, email){

      var modal = $("#podsjetnikModal")

      this.$root.otvoriform('podsjetnikModal')
      modal.find('.modal-title').text('Podsjetnik za Izgubljeni predmet')
      $('.modal-title').attr("id", id); 
      this.formpos.id=id
      if(email){
        $('.modal-body input').attr("placeholder", email); 
      }
        
      
      
    },

   deletePredmetPost(){

            
            axios.post('/api/izbrisi_zadatak/', this.form)
                 .then((res) => {
                  console.log(res)
                      $('#pr'+this.form.id).hide();
                      $("#deleteConfirmModal").modal('hide');  
                      this.$root.toastnoti("Uspjeh!", "Predmet je izbrisan", "uspjeh")
                 })
                 .catch((error) => {
                    console.log("bb")
                 }).finally(() => {
                     //Perform action in always
                 });
                 
      },

  
    deleteModal(id){

    this.$root.otvoriform('deleteConfirmModal')
    this.form.id=id
  },

    async getPredmeti() {

      await axios
        .get('/api/predmeti/')
        .then(response => {
          this.data = response.data
          
          
          console.log(this.data)
          
           const recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute(
          "src",
          "/js/filtertr.js"
        );
        document.head.appendChild(recaptchaScript);
        
        $("#podsjetnik").css("visibility", "visible");

        this.choice1 = new Choices('#choices-multiple-remove-button', {
          //choices: [{id: '1', value: 'One'}, {id: '2', value: 'Two'}],
          choices: this.data.lokacije.map(function(i) {return { id: i[1], value: i[0], customProperties: i[1] };}),
        removeItemButton: true,
        maxItemCount:3,
        searchResultLimit:5,
        renderChoiceLimit:5
      }); 
      this.choice2 = new Choices('#choices-multiple-remove-button1', {
          //choices: [{id: '1', value: 'One'}, {id: '2', value: 'Two'}],
          choices: this.data.vrste.map(function(i) {return { id: i[1], value: i[0], customProperties: i[1] };}),
        removeItemButton: true,
        maxItemCount:3,
        searchResultLimit:5,
        renderChoiceLimit:5
      }); 

   



      $("#preloader").hide()

        

       
        
        })
        .catch(error => {
           
          console.log(error)
        })

    },

    mixitFilter(){

    },
    

    


    
  },
  beforeDestroy() {

this.choice1.destroy();
this.choice2.destroy();
this.choice3.destroy();

}
}



</script>


