<template>
    <Navigation @reload-parent="methodThatForcesUpdate" yvisina=0 iocon="1">
    
      <template #atag>  <a class="btn-getstarted scrollto" style="border-radius: 20px;background-color: rgb(249, 243, 243);padding: 8px 15px;cursor: pointer;" id="podsjetnik" @click="this.$router.go(-1)"><i class="fa fa-arrow-left" style="color:rgb(14, 162, 189);font-size: 20px;"></i></a></template>
      <template #content>
        
<br><br><br><br>
<div class="wrapper">
    <div class="container">
        <div class="left">
            <div class="top">
                <input type="text" placeholder="Search"  v-model="searchquery" @input="search" />
                <a href="javascript:;" class="search" ></a>
            </div>
            <ul class="people">
                
                <li class="person" data-chat="person1" :id="user" v-for="(messages, user, index) in poruke" @click="otvori(user, messages[messages.length-1][3] )" :class="{ active: index === 0 }" >
                  
                  <img src="../assets/img/user.png"  alt="" />
                    <span class="name">{{ messages[messages.length-1][3] }} {{user}} </span>
                    <span class="time">{{ calculateTimeDifference(messages[messages.length-1][1]) }}</span>
                    <span class="preview"><span v-if="messages[messages.length-1][0]">{{messages[messages.length-1][0]}}</span> <span v-else>Nema poruka</span></span>
                </li>
                
             
            </ul>
        </div>
        <div class="right">
            <div class="top"><span>To: <span class="name">{{to}} | Lokacija: {{ tolokacija }}</span></span></div>
           
            <div class="chat overflow-auto" :id="'chat'+user" v-for="(messages, user, index) in poruke" :class="{ 'active-chat': index === 0 }">
                <div class="conversation-start">
					<br>
                    <span>{{prvaporuka(messages[0][1])}} </span>
                </div>
                <template v-for="i in messages">
                    <div class="bubble you" v-if="i[2]=='guest'">
                      <span style="color:lightgray;float:left;padding-right: 5px;font-size: 14px;">{{new Date(i[1]).toLocaleString('en-UK', {month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit'}).replace(',', '')}}</span>
                    
                    {{i[0]}}
           
                    
                </div>
                
                <div class="bubble me" v-if="i[2]=='staff'">
                    {{ i[0] }}
                    <span style="color:gray;float:right;padding-left: 5px;font-size: 14px;">{{new Date(i[1]).toLocaleString('en-UK', {month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit'}).replace(',', '')}}</span>
                </div>
                </template>
               
            
            </div>
         <br>
            <div class="write">
           
                <input type="text" id="porukatest" />

                <a  style="float:right;padding-right: 20px;cursor: pointer;" class="write-link send" @click="posaljiporuku()" ></a>
            </div>
        </div>
    </div>
</div>
<div id="preloader"></div>

      </template>
</Navigation>


</template>


<script>


import axios from 'axios'
import Navigation from '../components/Navigacija.vue'
export default {

    name: 'Info',
    components: {
        Navigation
    },

    data() {
        return {
          poruka:"",
          dodatangost:"",
          disabledporuka:false,
            friends:[],
            chat:[],
            poruke:[],
            to:"...",
            tolokacija:"",
            searchquery:"",
			form:{
				poruka:"",
				to:"",
			}
                
            }
        
    },

    mounted() {
      this.dodatangost = this.$route.params.parameter;
      console.log(this.dodatangost)


        this.getPoruke()
       

this.friends = {
    list: document.querySelector('ul.people'),
    all: document.querySelectorAll('.left .person'),
    name: ''
  },

  this.chat = {
    container: document.querySelector('.container .right'),
    current: null,
    person: null,
    name: document.querySelector('.container .right .top .name')
  }

this.friends.all.forEach(f => {
  f.addEventListener('mousedown', () => {
    f.classList.contains('active') || this.setAciveChat(f)
  })
});


    },


    methods: {

      methodThatForcesUpdate(key){
              $("#preloader").show()
          
              this.getPoruke()

        
          },


      prvaporuka(vrijeme){
        if (!vrijeme){
          return "..."
        }
        return new Date(vrijeme).toLocaleString('en-UK', {day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false})

      },

      dodajchat(username){
        this.poruke[username] = [[]]
      },
  

        search(){
            var text = this.searchquery
            $(".people li").each(function() {
				console.log(this.searchquery)


          var listItem = $(this);
          var listItemText = listItem.attr('id').toLowerCase()
		  console.log(listItemText)
          if (listItemText.includes(text.toLowerCase())) {
            
        
            listItem.css("display", "block");
          } else {
       
            listItem.css("display", "none");
          }
        });
        },
		posaljiporuku() {
      var poruka = $("#porukatest").val()
      console.log(poruka)
    if (poruka==""){
      this.$root.toastnoti("Error", "Your message is empty", "greska")
      return;
    }
    this.form.poruka = poruka

    if(this.disabledporuka){ return false}
    else{
      this.disabledporuka=true
    }
	this.form.to = this.to
  
     
      axios.post('/chat/kreirajporukustaff/', this.form)
        .then((res) => {
        
			console.log(this.poruke[this.form.to].push([this.form.poruka, 'now', "staff"]))
          //this.poruke.poruke.push([this.form.poruka, "guest", "now"])
          this.form.poruka = ""
          $("#porukatest").val("")
         
          

        })
        .catch((error) => {
          console.log(error)

          this.$root.toastnoti("Error", "Error, please try again later", "greska")
        }).finally(() => {
          this.disabledporuka=false
        });

    },

        otvori(user, lokacija){
            $('.active-chat').removeClass('active-chat');
            $('.active').removeClass('active');
            $("#chat"+user).addClass("active-chat")
            $("#"+user).addClass("active")
            this.to = user
            this.tolokacija = lokacija
        },
        calculateTimeDifference(timestamp) {
          if(!timestamp){
            return ""
          }
    if(timestamp=="now"){
      return "now"
    }
      const currentTime = new Date();
      const messageTime = new Date(timestamp);
      const timeDiff = currentTime.getTime() - messageTime.getTime();
      const minutes = Math.floor(timeDiff / 1000 / 60);

      if (minutes < 60) {
        return `${minutes} min ago`;
      } else {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours} hours and ${remainingMinutes} min ago`;
      }
    },

        async getPoruke() {
      await axios
        .get('/chat/staffporuke/')
        .then(response => {
          this.poruke = response.data
          if(this.dodatangost != 0){
            this.dodajchat(this.dodatangost)

          }
         
          console.log(this.poruke)
      
          
		  $("#preloader").remove()	
		  setTimeout(() => {
			
			let dataArray = Object.entries(this.poruke);

// Sort the array based on the last list's second element (date)
		dataArray.sort((a, b) => {
		const dateA = new Date(a[1][a[1].length - 1][1]);
		const dateB = new Date(b[1][b[1].length - 1][1]);
		return dateA.getTime() - dateB.getTime();
		});
		dataArray.reverse();

		// Convert the sorted array back to a dictionary
		let sortedData = Object.fromEntries(dataArray);
		this.poruke = sortedData
    const users = Object.keys(this.poruke);
			if (users.length > 0) {
				this.to = users[0];
        this.tolokacija = this.poruke[users[0]][0][3]
			}
			else{
				this.to = "..."
			}



			
        }, 200); // Delay of 0.2 seconds (200 milliseconds)
		  


        })
        .catch(error => {
            console.log(error)

        })

    },

     setAciveChat(f) {
  this.friends.list.querySelector('.active').classList.remove('active')
  f.classList.add('active')
  this.chat.current = this.chat.container.querySelector('.active-chat')
  this.chat.person = f.getAttribute('data-chat')
  this.chat.current.classList.remove('active-chat')
 this.chat.container.querySelector('[data-chat="' + this.chat.person + '"]').classList.add('active-chat')
  this.friends.name = f.querySelector('.name').innerText
  this.chat.name.innerHTML = friends.name
},

      
    }
}
</script>

<style scoped>


img {
    background-color: #eceff1;
    border-radius: 50%;
    padding: 2px;
  }

  body {
  background-color: var(--bg);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/382994/image.jpg");
  background-size: cover;
  background-repeat: none;
}

.wrapper {
  padding: 5em 0;
  height: 800px;
}

.container {
  height: 100%;
}
.container .left {
  float: left;
  width: 37.6%;
  height: 100%;
  border: 1px solid var(--light);
  background-color: var(--white);
}
.container .left .top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  padding: 29px;
}
.container .left .top:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  width: 80%;
  height: 1px;
  content: "";
  background-color: var(--light);
  transform: translate(-50%, 0);
}
.container .left input {
  float: left;
  width: 75%;
  height: 42px;
  padding: 0 15px;
  border: 1px solid var(--light);
  background-color: #eceff1;
  border-radius: 21px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}
.container .left input:focus {
  outline: none;
}
.container .left a.search {
  display: block;
  float: left;
  width: 42px;
  height: 42px;
  margin-left: 10px;
  border: 1px solid var(--light);
  background-color: var(--blue);
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/382994/name-type.png");
  background-repeat: no-repeat;
  background-position: top 12px left 14px;
  border-radius: 50%;
}
.container .left .people {
  margin-left: -1px;
  border-right: 1px solid var(--light);
  border-left: 1px solid var(--light);
  width: calc(100% + 2px);
}
.container .left .people .person {
  position: relative;
  width: 100%;
  padding: 12px 10% 16px;
  cursor: pointer;
  background-color: var(--white);
}
.container .left .people .person:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  width: 80%;
  height: 1px;
  content: "";
  background-color: var(--light);
  transform: translate(-50%, 0);
}
.container .left .people .person img {
  float: left;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}
.container .left .people .person .name {
  font-size: 14px;
  line-height: 22px;
  color: var(--dark);
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
}
.container .left .people .person .time {
  font-size: 14px;
  position: absolute;
  top: 16px;
  right: 10%;
  padding: 0 0 5px 5px;
  color: var(--grey);
  background-color: var(--white);
}
.container .left .people .person .preview {
  font-size: 14px;
  display: inline-block;
  overflow: hidden !important;
  width: 70%;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--grey);
  display: block;
}
.container .left .people .person.active,
.container .left .people .person:hover {
  margin-top: -1px;
  margin-left: -1px;
  padding-top: 13px;
  border: 0;
  background-color: var(--blue);
  width: calc(100% + 2px);
  padding-left: calc(10% + 1px);
}
.container .left .people .person.active span,
.container .left .people .person:hover span {
  color: var(--white);
  background: transparent;
}
.container .left .people .person.active:after,
.container .left .people .person:hover:after {
  display: none;
}
.container .right {
  position: relative;
  float: left;
  width: 62.4%;
  height: 100%;
}
.container .right .top {
  width: 100%;
  height: 47px;
  padding: 15px 29px;
  background-color: #eceff1;
}
.container .right .top span {
  font-size: 15px;
  color: var(--grey);
}
.container .right .top span .name {
  color: var(--dark);
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
}
.container .right .chat {
  position: relative;
  display: none;
  overflow: hidden;
  padding: 0 35px 92px;
  border-width: 1px 1px 1px 0;
  border-style: solid;
  border-color: var(--light);
  height: calc(100% - 48px);
  /*justify-content: flex-end;*/
  flex-direction: column;
  background-color: white;
}
.container .right .chat.active-chat {
  display: block;
  display: flex;
  border: none;
}
.container .right .chat.active-chat .bubble {
  transition-timing-function: cubic-bezier(0.4, -0.04, 1, 1);
}
.container .right .chat.active-chat .bubble:nth-of-type(1) {
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
}
.container .right .chat.active-chat .bubble:nth-of-type(2) {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}
.container .right .chat.active-chat .bubble:nth-of-type(3) {
  -webkit-animation-duration: 0.45s;
  animation-duration: 0.45s;
}
.container .right .chat.active-chat .bubble:nth-of-type(4) {
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
}
.container .right .chat.active-chat .bubble:nth-of-type(5) {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}
.container .right .chat.active-chat .bubble:nth-of-type(6) {
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
}
.container .right .chat.active-chat .bubble:nth-of-type(7) {
  -webkit-animation-duration: 1.05s;
  animation-duration: 1.05s;
}
.container .right .chat.active-chat .bubble:nth-of-type(8) {
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
}
.container .right .chat.active-chat .bubble:nth-of-type(9) {
  -webkit-animation-duration: 1.35s;
  animation-duration: 1.35s;
}
.container .right .chat.active-chat .bubble:nth-of-type(10) {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}
.container .right .write {

  bottom: 29px;
  left: 30px;
  height: 42px;
  padding-left: 8px;
  border: 1px solid var(--light);
  background-color: #eceff1;
  
  border-radius: 5px;
}
.container .right .write input {
  font-size: 16px;
  float: left;
  width: 85%;
  height: 40px;
  padding: 0 10px;
  color: var(--dark);
  border: 0;
  outline: none;
  background-color: #eceff1;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}
.container .right .write .write-link.attach:before {
  display: inline-block;
  float: left;
  width: 20px;
  height: 42px;
  content: "";
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/382994/attachment.png");
  background-repeat: no-repeat;
  background-position: center;
}
.container .right .write .write-link.smiley:before {
  display: inline-block;
  float: left;
  width: 20px;
  height: 42px;
  content: "";
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/382994/smiley.png");
  background-repeat: no-repeat;
  background-position: center;
}
.container .right .write .write-link.send:before {
  display: inline-block;
  float: left;
  width: 20px;
  height: 42px;
  margin-left: 11px;
  content: "";
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/382994/send.png");
  background-repeat: no-repeat;
  background-position: center;
}
.container .right .bubble {
  font-size: 16px;
  position: relative;
  display: inline-block;
  clear: both;
  margin-bottom: 8px;
  padding: 13px 14px;
  vertical-align: top;
  border-radius: 5px;
}
.container .right .bubble:before {
  position: absolute;
  top: 19px;
  display: block;
  width: 8px;
  height: 6px;
  content: " ";
  transform: rotate(29deg) skew(-35deg);
}
.container .right .bubble.you {
  float: left;
  color: var(--white);
  background-color: var(--blue);
  align-self: flex-start;
  -webkit-animation-name: slideFromLeft;
  animation-name: slideFromLeft;
}
.container .right .bubble.you:before {
  left: -3px;
  background-color: var(--blue);
}
.container .right .bubble.me {
  float: right;
  color: var(--dark);
  background-color: #eceff1;
  align-self: flex-end;
  -webkit-animation-name: slideFromRight;
  animation-name: slideFromRight;
}
.container .right .bubble.me:before {
  right: -3px;
  background-color: #eceff1;
}
.container .right .conversation-start {
  position: relative;
  width: 100%;
  margin-bottom: 27px;
  text-align: center;
}
.container .right .conversation-start span {
  font-size: 14px;
  display: inline-block;
  color: var(--grey);
}
.container .right .conversation-start span:before,
.container .right .conversation-start span:after {
  position: absolute;
  top: 10px;
  display: inline-block;
  width: 30%;
  height: 1px;
  content: "";
  background-color: var(--light);
}
.container .right .conversation-start span:before {
  left: 0;
}
.container .right .conversation-start span:after {
  right: 0;
}

@keyframes slideFromLeft {
  0% {
    margin-left: -200px;
    opacity: 0;
  }
  100% {
    margin-left: 0;
    opacity: 1;
  }
}
@-webkit-keyframes slideFromLeft {
  0% {
    margin-left: -200px;
    opacity: 0;
  }
  100% {
    margin-left: 0;
    opacity: 1;
  }
}
@keyframes slideFromRight {
  0% {
    margin-right: -200px;
    opacity: 0;
  }
  100% {
    margin-right: 0;
    opacity: 1;
  }
}
@-webkit-keyframes slideFromRight {
  0% {
    margin-right: -200px;
    opacity: 0;
  }
  100% {
    margin-right: 0;
    opacity: 1;
  }
}

/* Media Query */
@media (max-width: 992px) {
  .container .left {
    display: block;
    width: 100%;
    float: unset;
    overflow-y: scroll;
    height: 300px;
    margin-bottom: 1em;
    overflow-x: hidden;
  }
  .container .right {
    width: 100%;
    float: unset;
  }
  .container .right .write input {
    width: 70%;
  }
  .wrapper {
    height: unset;
    min-height: 100vh;
    padding: 2em 0;
  }
}

</style>